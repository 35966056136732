import http from "../services/http.service";
import { GenerateTokeApiResponse } from "./apiTypes";
import { AxiosError } from "axios";

export const notifyRider = (
  uuid: string,
  onSucceed: (data: GenerateTokeApiResponse) => void,
  onError: (err: AxiosError) => void
) => {
  http
    .post(`/v1/ops/riders/${uuid}/notify/`, {
      notification_type: "CONTAINER_ASSIGNMENT",
    })
    .then((res) => {
      console.log("notifyRider", res);
      onSucceed(res.data);
    })
    .catch(onError);
};
