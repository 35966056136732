import React, { useCallback, useEffect, useState } from "react";
import "./boxDetail.css";
import { useTranslation } from "react-i18next";
import InfiniteList from "../../molecules/list/InfiniteList";
import {
  GetContainerDetailAPIParam,
  ShippingItemType,
} from "../../../api/apiTypes";
import LeftTitledTemplate from "../../templates/leftTitled/LeftTitledTemplate";
import {
  checkoutBox,
  getContainerDetail,
  uncheckShippingItem,
} from "../../../api/shippingApi";
import {
  showApiError,
  showMessage,
  showMessageWithTitle,
} from "../../../fns/message";
import { Button } from "antd";
import { isDesktopByWidth } from "../../../fns/commonFns";
import EmptyContent from "../../molecules/emptyContent/EmptyContent";
import ShippingItem from "../../organisms/ShippingItem";

interface Params {
  params: {
    uuid: string;
    cntrUuid: string;
  };
}
const BoxDetailScreen = ({ match }: { match: Params }) => {
  const { uuid, cntrUuid } = match.params;
  const { t } = useTranslation(["box"]);
  const [container, setContainer] = useState<GetContainerDetailAPIParam>();
  const [selected, setSelected] = useState<string[]>([]);
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    getContainerDetail(cntrUuid, (res) => {
      setContainer(res);
    });
  }, [uuid]);

  const handleCloseBox = () => {
    showMessageWithTitle(
      t("popup.check.ask.title", { box: container?.box?.alias }),
      t("popup.check.ask.content", { count: 30 }),
      "warning",
      t("popup.check.ask.btn.ok"),
      () => {
        checkoutBox(uuid, onCheckoutSuccess);
      },
      t("popup.check.ask.btn.cancel")
    );
  };

  const onCheckoutSuccess = () => {
    showMessage(t("popup.check.done", { box: container?.box?.alias }));
  };

  const handleSelect = useCallback(
    (tracking_number: string, select?: boolean) => {
      if (select) {
        console.log("selected", select, tracking_number, selected);
        setSelected([...selected, tracking_number]);
      } else {
        const filtered = selected.filter((s) => s !== tracking_number);
        setSelected(filtered);
      }
    },
    [selected]
  );

  const handleUncheck = () => {
    let successCnt = 0;
    const req = selected.map(
      (tracking): Promise<number> => {
        return new Promise<number>((resolve) => {
          uncheckShippingItem(
            tracking,
            () => {
              successCnt++;
              resolve(0);
            },
            (err) => {
              showApiError(err);
              resolve(0);
            }
          );
        });
      }
    );
    Promise.all<number>(req).then((error) => {
      console.log("handleAfterPrint", error);
      if (successCnt >= selected.length) {
        showMessage(t("success.popup.checkout.item"));
      }
      setSelected([]);
      setRefresh((prev) => !refresh);
    });
  };

  const handleRegisterDone = () => {
    setRefresh((prev) => !prev);
  };

  return (
    <LeftTitledTemplate
      header={t("detail.header", { box: container?.box?.alias })}
      headerProps={{
        right: container?.status === "CHECKIN" && t("register.btn.done"),
        onRight: handleCloseBox,
      }}
      classname={"box-detail-container"}
    >
      <InfiniteList
        url={`/v1/hub/boxes/${uuid}/shippingitems`}
        refresh={refresh}
        params={{ ordering: "timestamp_boxassigned" }}
        loadingClass={"box-loading-class"}
        height={
          isDesktopByWidth()
            ? window.innerHeight - 200
            : window.innerHeight - 100
        }
        onRefresh={handleRegisterDone}
      >
        {(count?: number, results?: ShippingItemType[], loading?) => {
          return (
            <div>
              {results && results.length > 0
                ? results.map((order, index) => (
                    <ShippingItem
                      key={"order" + index}
                      // selected={selected.includes(tracking_number)}
                      // tracking_number={tracking_number}
                      {...order}
                      onClick={handleSelect}
                    />
                  ))
                : !loading && <EmptyContent title={t("empty.item.title")} />}
            </div>
          );
        }}
      </InfiniteList>
      {selected.length > 0 && (
        <Button
          type={"primary"}
          className={"btn-uncheck"}
          onClick={handleUncheck}
        >
          {t("btn.remove")}
        </Button>
      )}
    </LeftTitledTemplate>
  );
};

export default BoxDetailScreen;
