import http from "../services/http.service";
import Bugsnag from "@bugsnag/js";
import { GetUserApiResponse, UserAccountType } from "./apiTypes";
import { showApiError } from "../fns/message";
import { AxiosError } from "axios";

export const getUser = (onSucceed: (data: GetUserApiResponse) => void) => {
  http
    .get("/v1/users/self/")
    .then((res) => {
      console.log("getUser", res);
      Bugsnag.setUser(res.data.uuid, res.data.phonenumber, res.data.username);
      if (res.data.user) {
        onSucceed({
          ...res.data.user,
          permission_group: res.data.permission_group,
        });
      } else {
        onSucceed(res?.data);
      }
    })
    .catch(showApiError);
};
