import { fetcher } from "../services/http.service";
import {
  GetListResponse,
  GetShippingAPIParams2,
  ShippingItemListType,
} from "./apiTypes";
import { objToQueryString } from "../fns/commonFns";
import { useEffect, useMemo, useRef, useState } from "react";
import useSWRInfinite from "swr/infinite";
import { getRandomColorSector } from "../fns/objectFns";
import shippingMarkerIcon from "../assets/images/shippingmarker.png";
import { Options as IconOptions } from "ol/style/Icon";
import { PointProps } from "@delivus/react-open-layers/dist/cjs/types/point/point.type";
import { ObjectWith } from "../components/screens/plan/PlanMapScreen";

export const getMarkerIcon = (color?: string): IconOptions => ({
  color,
  src: shippingMarkerIcon,
  scale: 0.3,
  anchorOrigin: "bottom-left",
});

export type TShippingPointProperty = {
  shipping: ShippingItemListType;
  color: string;
  highlighted?: boolean;
};

export function useShippings(params: GetShippingAPIParams2) {
  const coloredSectorsRef = useRef<ObjectWith>({});
  const [shippingMarkers, setShippingMarkers] = useState<PointProps[]>();
  const paramString = useMemo(() => objToQueryString(params, true), [params]);
  const getKey = (
    pageIndex: number,
    previousPageData: GetListResponse<ShippingItemListType>
  ) => {
    if (pageIndex === 0) {
      if (
        (!(params.designated_sector__name__in?.length || 0 > 0) &&
          !(params.designated_sector__code__in?.length || 0 > 0)) ||
        (!(params.shipping_shop__uuid__in?.length || 0 > 0) &&
          !(params.pickup_batch__uuid__in?.length || 0 > 0) &&
          !(params.return_order__shop__uuid__in?.length || 0 > 0))
      )
        return null;
      else return `/v2/hub/shippingitems/?page_size=1000&${paramString}`;
    }
    if (previousPageData && previousPageData.next) return previousPageData.next; // reached the end
    return null;
  };

  const { data, error, isValidating, setSize, mutate } = useSWRInfinite<
    GetListResponse<ShippingItemListType>
  >(getKey, fetcher, { initialSize: 1 });

  useEffect(() => {
    console.log("useShippings", data, params);
    let listData: PointProps[] = [];
    if (data && data?.length > 0) {
      const lastRes = data[data?.length - 1];
      if (
        lastRes &&
        lastRes.current_page * lastRes.items_per_page < lastRes.count
      )
        setSize((size) => size + 1);
      else {
        data.forEach((item) => {
          console.log("coordinates 2", item);
          if (item?.results) {
            item.results.forEach((shipping, index) => {
              const marker = createPointFromShipping(shipping, index);
              if (marker) listData.push(marker);
            });
          }
        });
      }
      setShippingMarkers(listData);
    } else {
      setShippingMarkers(listData);
    }
  }, [data, params, setSize]);

  const createPointFromShipping = (
    shipping: ShippingItemListType,
    index: number
  ) => {
    let marker: PointProps | null = null;
    if (!!shipping.address?.lng && !!shipping.address?.lat) {
      const code = shipping?.designated_sector?.code || "";
      const color = getRandomColorSector(code, coloredSectorsRef.current[code]);
      coloredSectorsRef.current[code] = color;
      marker = {
        index,
        properties: { shipping, color },
        iconOptions: getMarkerIcon(color),
        coordinate: [
          Number(shipping.address?.lng),
          Number(shipping.address?.lat),
        ],
      };
    }
    return marker;
  };

  return {
    shippingMarkers,
    isLoading: isValidating,
    mutate,
    isError: error,
  };
}
