import React from "react";
import { NavLink } from "react-router-dom";
import styles from "./tabbar.module.css";
import { useTranslation } from "react-i18next";

type Props = {
  route: string;
  icon: any;
  label: string;
  active?: boolean;
  count?: number;
  badge?: boolean;
};
const TabItem = ({ route, icon, label, active, count, badge }: Props) => {
  const { t } = useTranslation(["common"]);
  const Icon = icon;
  return (
      //@ts-ignore
    <NavLink to={route} className={styles.tabLink} activeClassName="active">
      {!!count && (
        <span className={"small medium " + styles.tabCount}>{count}</span>
      )}
      {badge && <span className={styles.tabBadge} />}
      <div className={styles.tabInner}>
        <Icon color={active ? "var(--primary)" : "black"} />
        <p
          className={
            active ? "small1 " + styles.tabActive : styles.tabText + " small1"
          }
        >
          {t(label)}
        </p>
      </div>
    </NavLink>
  );
};

export default TabItem;
