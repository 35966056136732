import React from "react";
import styles from "./riderItem.module.css";

import { Button, Radio } from "antd";
import { ContainerApiResponse } from "../../../api/apiTypes";

interface Props extends ContainerApiResponse {
  selected: boolean;
  onSelect: (uuid: string) => void;
}

const ContainerItem = ({
  selected,
  onSelect,
  box,
  sector,
  uuid,
  unit_location,
}: Props) => {
  const handleSelect = (e: any) => {
    if (!selected) onSelect(uuid);
  };

  return (
    <Button
      type={"text"}
      className={styles.container + " row align-center"}
      onClick={handleSelect}
    >
      <Radio checked={selected} onChange={handleSelect} />
      <div className={"flex column " + styles.name}>
        <div className={"small bold white85"}>{box?.alias}</div>
        <div className={"small bold white45 " + styles.phone}>
          {sector?.code}
        </div>
      </div>
      <span className={"small black white " + styles.type}>
        {unit_location?.name}
      </span>
    </Button>
  );
};

export default ContainerItem;
