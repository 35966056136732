export default function DeliveryIcon({ color = "black" }: { color: string }) {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.9091 2H3.09091C2.4875 2 2 2.38304 2 2.85714V25.1429C2 25.617 2.4875 26 3.09091 26H24.9091C25.5125 26 26 25.617 26 25.1429V2.85714C26 2.38304 25.5125 2 24.9091 2ZM19.3182 9.55089L17.733 8.64286L16.0455 9.59107V3.60714H19.3182V9.55089Z"
        fill={color}
        fill-opacity="0.85"
      />
    </svg>
  );
}
