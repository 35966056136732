import React from "react";
import { Modal } from "antd";
import "./pushPopupStyle.css";
import { useTranslation } from "react-i18next";

const PushConfirm = (props: any) => {
  const { t } = useTranslation(["pickup"]);

  return (
    <Modal
      open={props.status}
      className="modal-style-push-confirm"
      maskClosable={true}
      bodyStyle={{ borderRadius: "1.4rem" }}
      centered
      onCancel={props.changeStatus}
      closable={false}
      footer={null}
    >
      <div className={"push-confirm-root-div"}>
        <div className={"mobile-text"}>{props.value}</div>
        <div className={"confirm-text"}>{t("request.text.push_confirm")}</div>
        <div className={"push-confirm-button-row"}>
          <div onClick={props.onCancel} className={"push-confirm-btn-cancel"}>
            {t("request.confirm.cancel")}
          </div>
          <div onClick={props.onPush} className={"push-confirm-btn-save"}>
            {t("request.confirm.ok")}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default PushConfirm;
