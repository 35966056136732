import React from "react";
import styles from "./print.module.css";
import { Skeleton, Space } from "antd";

const PrintLoading = () => {
  return (
    <div className={styles.wrapLoading}>
      <Space direction={"vertical"} size={1}>
        <Space size={1}>
          <Skeleton.Input style={{ width: 80, height: 80 }} active />
          <Skeleton.Input active style={{ width: 279, height: 80 }} />
        </Space>
        <Skeleton.Input style={{ width: 360, height: 80 }} active />
        <Skeleton.Input style={{ width: 360, height: 150 }} active />
        <Skeleton.Input style={{ width: 360, height: 120 }} active />
        <Skeleton.Input style={{ width: 360, height: 95 }} active />
      </Space>
    </div>
  );
};

export default PrintLoading;
