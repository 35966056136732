import React, { forwardRef } from "react";
import styles from "./filter.module.css";

import { ShippingItemListType } from "../../../api/apiTypes";
import { Button } from "antd";
import { useTranslation } from "react-i18next";

interface Props {
  data: ShippingItemListType[];
  onReset?: () => void;
}

const SelectedShippings = forwardRef(({ data, onReset }: Props, ref) => {
  const { t } = useTranslation("shipping");
  return (
    <div className={styles.container + " column"}>
      <div className={"flex " + styles.list}>
        {data &&
          data.map((s, idx) => (
            <div key={"chek" + idx} className={styles.check + " body"}>
              {s.tracking_number}
            </div>
          ))}
      </div>
      <div className={"row space-between align-center "}>
        <Button
          type={"text"}
          className={"lime body " + styles.reset}
          onClick={onReset}
        >
          {t("reset", { cnt: data.length })}
        </Button>
      </div>
    </div>
  );
});

export default SelectedShippings;
