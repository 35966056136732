import React from "react";
import { Modal } from "antd";
import "./pushPopupStyle.css";
import { useTranslation } from "react-i18next";

const PushPopup = (props: any) => {
  const { t } = useTranslation(["pickup"]);

  const handleChange = (e: any) => {
    const value = e.target.value.replace(/[^\d-]+/g, "");
    props.changeMobile(value);
  };

  return (
    <Modal
      open={props.status}
      className="modalStyle"
      maskClosable={true}
      bodyStyle={{ backgroundColor: "#262626", borderRadius: "1.4rem" }}
      centered
      onCancel={props.changeStatus}
      closable={false}
      footer={null}
    >
      <div className={"modal-root-div"}>
        <input
          value={props.value}
          onChange={handleChange}
          autoFocus={true}
          placeholder={t("input.request_placeholder")}
          className={"input-div"}
        />

        <div onClick={props.confirm} className={"submit-button"}>
          {t("input.request.send")}
        </div>
      </div>
    </Modal>
  );
};

export default PushPopup;
