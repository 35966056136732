import React, { ReactNode, useEffect, useRef, useState } from "react";
import styles from "./search.module.css";
import magnify from "../../../assets/svgs/magnify.svg";

import {
  Button,
  Checkbox,
  Dropdown,
  Input,
  Select,
  Space,
  DatePicker,
  MenuProps,
} from "antd";
import { useTranslation } from "react-i18next";
import { LogicalBoxType } from "../../../api/apiTypes";
import { showMessage } from "../../../fns/message";
import { DownOutlined } from "@ant-design/icons";

const { RangePicker } = DatePicker;

const { Option } = Select;

export type SearchResultType = {
  selectedOption?: string;
  inputValue?: string;
  dateRange?: any;
  checkedValue?: string | number;
};

export type ButtonType = {
  title: string;
  loading?: boolean;
  objectType?: any;
  menu?: MenuProps;
  onClick: (arg?: any) => void;
};
export type CheckType = {
  title: string | ReactNode;
  value: string;
  className?: string;
};

export type MultipleOptionType = {
  value?: string | string[];
  label?: string;
  placeholder?: string;
  onSelect: (val: any) => void;
  options: CheckType[];
};

export interface SearchViewProps extends LogicalBoxType {
  selectDiff?: boolean;
  searchLabel?: string;
  className?: string;
  id?: string;
  inputValue?: string;
  container?: any;
  containerSelect?: any;
  defaultOptionIndex?: number;
  options?: CheckType[];
  multipleOptions?: MultipleOptionType[];
  buttons?: ButtonType[];
  checks?: CheckType[];
  onSearch?: (params: SearchResultType) => void;
  onChangeRange?: (range: any) => void;
}

const SearchView = ({
  id,
  className,
  selectDiff,
  searchLabel,
  options,
  inputValue: inputProp,
  multipleOptions,
  buttons,
  checks,
  onSearch,
  container,
  containerSelect,
  onChangeRange,
  defaultOptionIndex = 0,
}: SearchViewProps) => {
  const { t } = useTranslation(["box"]);
  const dateRef = useRef<any>();
  const [selectedOption, setSelectedOption] = useState(
    options && options.length > 0 ? options[0].value : ""
  );
  const [checkedValue, setCheckedValue] = useState<string | number>();
  const [inputValue, setInputValue] = useState(inputProp);

  useEffect(() => {
    if (!inputProp) setInputValue("");
  }, [inputProp]);

  const onHandleChange = (e: any) => {
    setInputValue(e.target.value);
  };

  const handleSearch = (event: any) => {
    event.preventDefault();
    if (!inputValue) showMessage(t("box.search.input"));
    else {
      !!onSearch &&
        onSearch({
          inputValue,
          dateRange: dateRef.current,
          checkedValue,
          selectedOption,
        });
    }
  };

  const handleChangeChecked = (value: string) => {
    setCheckedValue(value);
    !!onSearch &&
      onSearch({
        inputValue,
        dateRange: dateRef.current,
        checkedValue: value,
        selectedOption,
      });
  };

  const handleChangeRange = (value: any) => {
    dateRef.current = value;
    !!onSearch &&
      onSearch({
        dateRange: dateRef.current,
        inputValue,
        checkedValue,
        selectedOption,
      });
  };

  const handleSelectType = (selectedOption: string) => {
    setSelectedOption(selectedOption);
    !!onSearch &&
      onSearch({
        dateRange: dateRef.current,
        inputValue,
        checkedValue,
        selectedOption,
      });
  };

  const checksNode = (
    <div className={styles.checkCntr}>
      {checks &&
        checks.map(({ value, title }, idx) => (
          <Checkbox
            key={"chek" + idx}
            className={styles.searchCheck}
            data-cy="homeManageCheckbox"
            onChange={(e) => e.target.checked && handleChangeChecked(value)}
            checked={checkedValue === value}
          >
            {title}
          </Checkbox>
        ))}
    </div>
  );

  const dateNode = (
    <div className={styles.checkCntr}>
      {onChangeRange && (
        <RangePicker
          onChange={handleChangeRange}
          style={{ marginRight: "10px" }}
        />
      )}
    </div>
  );

  const inputNode = onSearch && (
    <form
      className={"row flex " + styles.searchInputCntr}
      onSubmit={handleSearch}
    >
      <Input
        defaultValue={""}
        autoComplete={"off"}
        size={"small"}
        className={styles.searchInput}
        onChange={onHandleChange}
        maxLength={30}
        value={inputValue}
        placeholder={t("box.search.input")}
      />
      <Button
        data-cy="searchHomeInput"
        className={styles.searchBtn}
        onClick={handleSearch}
        icon={<img src={magnify} className={styles.searchIcon} />}
      ></Button>
    </form>
  );

  const buttonNode = buttons && (
    <Space size={1} className={styles.controlBtn}>
      {buttons.map((item, idx) => {
        if (item?.objectType) {
          return (
            <Dropdown menu={item.menu} trigger={["click"]}>
              <Button
                key={"searchbtn" + idx}
                type={"primary"}
                loading={item.loading}
                className={styles.btnPrint}
              >
                <Space>
                  {item.title}
                  <DownOutlined />
                </Space>
              </Button>
            </Dropdown>
          );
        } else {
          return (
            <Button
              key={"searchbtn" + idx}
              type={"primary"}
              loading={item.loading}
              className={styles.btnPrint}
              onClick={item.onClick}
            >
              {item.title}
            </Button>
          );
        }
      })}
    </Space>
  );

  const optionsNode = (
    <>
      {searchLabel && (
        <div className={"small bold black45 " + styles.searchLabel}>
          {searchLabel}
        </div>
      )}
      {options && options.length > defaultOptionIndex && (
        <Select
          // size={"large"}
          className={styles.picker}
          placeholder={options[defaultOptionIndex].title}
          defaultValue={options[defaultOptionIndex].value}
          onChange={handleSelectType}
          data-cy="homeManageSearch"
        >
          {options.map(
            ({ value, title, className }, index) =>
              (selectDiff && value === selectedOption) || (
                <Option
                  key={"option_" + index}
                  className={className}
                  value={value}
                >
                  {title}
                </Option>
              )
          )}
        </Select>
      )}
    </>
  );

  const containerOptionsNode = (
    <>
      {container && container.length > defaultOptionIndex && (
        <Select
          // size={"large"}
          className={styles.picker}
          placeholder={t("container_type")}
          onChange={containerSelect}
          data-cy="homeManageSearchC"
        >
          {container.map((v: any) => {
            return (
              <Option
                key={"option_c" + v.key}
                className={className}
                value={v.label}
              >
                {v.label}
              </Option>
            );
          })}
        </Select>
      )}
    </>
  );

  const multipleOptionsNode = (
    <div className={"row"}>
      {multipleOptions &&
        multipleOptions.map(
          (options, index) =>
            options.options.length > 0 && (
              <div key={"multiple-opts" + index} className={"row"}>
                {options.label && (
                  <span
                    className={"small1 medium black45 " + styles.optionLabel}
                  >
                    {options.label}
                  </span>
                )}
                <Select
                  // size={"large"}
                  className={styles.picker}
                  placeholder={
                    options.placeholder
                      ? options.placeholder
                      : options.options[defaultOptionIndex].title
                  }
                  defaultValue={
                    options.placeholder
                      ? undefined
                      : options.options[defaultOptionIndex].value
                  }
                  value={options.value}
                  onChange={options.onSelect}
                  data-cy="homeManageSearch"
                >
                  {options.options.map(
                    ({ value, title, className }, index) =>
                      (selectDiff && value === selectedOption) || (
                        <Option
                          key={"option_" + index}
                          className={className}
                          value={value}
                        >
                          {title}
                        </Option>
                      )
                  )}
                </Select>
              </div>
            )
        )}
    </div>
  );

  return (
    <div id={id} className={styles.container + " row " + className}>
      <div className={styles.searchCntr + " row flex"}>
        {multipleOptionsNode}
        <div className={"row"}>
          {containerOptionsNode}
          {optionsNode}
          {inputNode}
        </div>
        {checksNode}
        {dateNode}
      </div>
      {buttonNode}
    </div>
  );
};

export default SearchView;
