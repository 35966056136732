export default function DeliveryIcon({ color = "black" }: { color: string }) {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25.04 2H2.96C2.429 2 2 2.429 2 2.96V25.04C2 25.571 2.429 26 2.96 26H25.04C25.571 26 26 25.571 26 25.04V2.96C26 2.429 25.571 2 25.04 2ZM10.04 19.52C9.377 19.52 8.84 18.983 8.84 18.32C8.84 17.657 9.377 17.12 10.04 17.12C10.703 17.12 11.24 17.657 11.24 18.32C11.24 18.983 10.703 19.52 10.04 19.52ZM10.04 15.2C9.377 15.2 8.84 14.663 8.84 14C8.84 13.337 9.377 12.8 10.04 12.8C10.703 12.8 11.24 13.337 11.24 14C11.24 14.663 10.703 15.2 10.04 15.2ZM10.04 10.88C9.377 10.88 8.84 10.343 8.84 9.68C8.84 9.017 9.377 8.48 10.04 8.48C10.703 8.48 11.24 9.017 11.24 9.68C11.24 10.343 10.703 10.88 10.04 10.88ZM19.16 19.04C19.16 19.172 19.052 19.28 18.92 19.28H13.4C13.268 19.28 13.16 19.172 13.16 19.04V17.6C13.16 17.468 13.268 17.36 13.4 17.36H18.92C19.052 17.36 19.16 17.468 19.16 17.6V19.04ZM19.16 14.72C19.16 14.852 19.052 14.96 18.92 14.96H13.4C13.268 14.96 13.16 14.852 13.16 14.72V13.28C13.16 13.148 13.268 13.04 13.4 13.04H18.92C19.052 13.04 19.16 13.148 19.16 13.28V14.72ZM19.16 10.4C19.16 10.532 19.052 10.64 18.92 10.64H13.4C13.268 10.64 13.16 10.532 13.16 10.4V8.96C13.16 8.828 13.268 8.72 13.4 8.72H18.92C19.052 8.72 19.16 8.828 19.16 8.96V10.4Z"
        fill={color}
        fill-opacity="0.85"
      />
    </svg>
  );
}
