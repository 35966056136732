import { message } from "antd";
import React, { ReactNode } from "react";
import { AxiosError } from "axios";
import { Modal } from "antd";
import { isEmptyObject } from "./objectFns";

export type ErrorType = {
  message: string | { message: string };
  code: string;
  field: string;
};

export const showApiError = (error: any, t?: (string: any) => string) => {
  if (!t) t = (text) => text;
  console.log("apiError", error.response);
  const errors: ErrorType[] = error.response?.data?.errors;
  let popup: any = {};
  let fieldError: any = {};
  if (errors && errors.length > 0) {
    errors.forEach((e) => {
      switch (e.field) {
        case "toast":
          showMessage(e.message + "");
          return;
        case "ui.title":
          popup.title = e.message;
          break;
        case "ui.text":
          popup.content = e.message;
          break;
        case "ui.button.ok":
          popup.btnOk = e.message;
          break;
        case "ui.button.cancel":
          popup.btnCancel = e.message;
          break;
        default:
          fieldError.field = e.field;
          fieldError.message =
            typeof e.message === "string" ? e.message : e.message?.message;
      }
    });
    if (!isEmptyObject(popup)) {
      showMessageWithTitle(
        popup.title,
        popup.content,
        "warning",
        popup.btnOk,
        undefined,
        popup.btnCancel,
        undefined
      );
      return;
    } else if (!isEmptyObject(fieldError)) {
      showMessage(fieldError.field + fieldError.message);
    }
  } else if (
    error?.response?.data &&
    Array.isArray(error?.response?.data) &&
    error?.response?.data.length > 0
  ) {
    let message = "";
    error?.response?.data.forEach((e: any) => {
      message = JSON.stringify(e);
    });
    showMessage(message);
  } else {
    showMessage(
      t(error?.response?.data?.detail || error?.response?.data || error.message)
    );
  }
};

export const showMessage = (
  content: string | ReactNode,
  type: "info" | "success" | "error" | "warning" | "loading" = "info",
  duration = 2
) => {
  message.open({
    type,
    icon: <div />,
    content: content,
    duration,
  });
};

export const showMessageWithTitle = (
  title: string,
  content: string | ReactNode,
  type: "info" | "success" | "error" | "warning" = "info",
  okText = "확인",
  onOk?: () => void,
  cancelText?: string,
  onCancel?: () => void
) => {
  console.log("cancel text", cancelText);
  if (!!cancelText) {
    switch (type) {
      case "success":
        Modal.success({
          centered: true,
          title,
          content,
          okText,
          onOk,
          cancelText,
          onCancel,
        });
        return;
      default:
        console.log("cancel text", cancelText, okText);
        Modal.confirm({
          centered: true,
          title,
          content,
          okText,
          onOk,
          cancelText,
          onCancel,
        });
    }
  } else {
    switch (type) {
      case "info":
        Modal.info({
          centered: true,
          title,
          content,
          okText,
          onOk,
          onCancel,
        });
        break;

      case "success":
        Modal.success({
          centered: true,
          title,
          content,
          okText,
          onOk,
          onCancel,
        });
        break;

      case "error":
        Modal.error({
          centered: true,
          title,
          content,
          okText,
          onOk,
          onCancel,
        });
        break;

      case "warning":
        Modal.warning({
          centered: true,
          title,
          content,
          okText,
          onOk,
          onCancel,
        });
        break;
    }
  }
};
