import http from "../services/http.service";
import { showApiError } from "../fns/message";
import { GetPickupRoutesAPIParam } from "./apiTypes";
import { AxiosError } from "axios";

export const getPickupRoutes = (
  params: object,
  onSucceed: (res: GetPickupRoutesAPIParam) => void
) => {
  http
    .get(`/v1/route/pickuproutes`, {
      params,
    })
    .then((res) => {
      console.log("getPickupRoutes", res);
      onSucceed(res.data);
    })
    .catch(showApiError);
};

export const getPickupBatchList = (
  alias: string,
  onSucceed: (res: GetPickupRoutesAPIParam) => void
) => {
  http
    .get(`/v1/route/pickuproutes/` + alias + "/batches/", {})
    .then((res) => {
      console.log("getBatchRoutes", res);
      onSucceed(res.data);
    })
    .catch(showApiError);
};

export const getHubList = (
  onSucceed: (res: GetPickupRoutesAPIParam) => void
) => {
  http
    .get("/v1/location/mainhubs")
    .then((res) => {
      console.log("getHubList", res);
      onSucceed(res.data);
    })
    .catch(showApiError);
};

export const createRoutePickup = (
  params: object,
  onSucceed: (res: GetPickupRoutesAPIParam) => void,
  onError: (err: AxiosError) => void
) => {
  http
    .post("/v1/route/pickuproutes/", params)
    .then((res) => {
      onSucceed(res.data);
    })
    .catch(onError);
};

export const getRoutePickupBatches = (
  params: object,
  onSucceed: (res: GetPickupRoutesAPIParam) => void
) => {
  http
    .get("/v1/route/pickupbatches/", {
      params,
    })
    .then((res) => {
      onSucceed(res.data);
    })
    .catch(showApiError);
};

export const completeRoutePickup = (
  alias: string,
  onSucceed: (res: GetPickupRoutesAPIParam) => void,
  onError: (err: AxiosError) => void
) => {
  http
    .post(`/v1/route/pickuproutes/` + alias + "/complete/", {})
    .then((res) => {
      console.log("complete", res);
      onSucceed(res.data);
    })
    .catch(onError);
};

export const completePickupBatches = (
  uuid: string,
  onSucceed: (res: GetPickupRoutesAPIParam) => void,
  onError: (err: AxiosError) => void
) => {
  http
    .post(`/v1/route/pickupbatches/` + uuid + "/complete/", {})
    .then((res) => {
      console.log("complete batches", res);
      onSucceed(res.data);
    })
    .catch(onError);
};

export const completeRouteRequestMiddlemile = (
  alias: string,
  phone: string,
  onSucceed: (res: GetPickupRoutesAPIParam) => void,
  onError: (err: AxiosError) => void
) => {
  http
    .post(`/v1/route/pickuproutes/${alias}/request/`, {
      phonenumber: phone,
    })
    .then((res) => {
      console.log("complete route for middlemile", res);
      onSucceed(res.data);
    })
    .catch(onError);
};

export const addPickupAlias = (
  alias: string,
  params: object,
  onSucceed: (res: GetPickupRoutesAPIParam) => void,
  onError: (err: AxiosError) => void
) => {
  http
    .post(`/v2/route/pickuproutes/` + alias + "/add/", params)
    .then((res) => {
      console.log("add alias", res);
      onSucceed(res.data);
    })
    .catch(onError);
};

export const getDropoffRoutes = (
  params: object,
  onSucceed: (res: any) => void
) => {
  http
    .get(`/v1/route/dropoffroutes`, {
      params,
    })
    .then((res) => {
      console.log("getCDropoffRoutes", res);
      onSucceed(res.data);
    })
    .catch(showApiError);
};

export const getDropoffBatchList = (
  alias: string,
  onSucceed: (res: GetPickupRoutesAPIParam) => void
) => {
  http
    .get(`/v1/route/dropoffroutes/` + alias + "/batches/")
    .then((res) => {
      console.log("getDropoffBatchRoutes", res);
      onSucceed(res.data);
    })
    .catch(showApiError);
};

export const getRouteDropOffBatches = (
  params: object,
  onSucceed: (res: GetPickupRoutesAPIParam) => void
) => {
  http
    .get("/v1/route/dropoffbatches/", { params })
    .then((res) => {
      onSucceed(res.data);
    })
    .catch(showApiError);
};

export const createRouteDropOff = (
  params: object,
  onSucceed: (res: GetPickupRoutesAPIParam) => void,
  onError: (err: AxiosError) => void
) => {
  http
    .post("/v1/route/dropoffroutes/", params)
    .then((res) => {
      onSucceed(res.data);
    })
    .catch(onError);
};

export const addDropOffAlias = (
  alias: string,
  params: object,
  onSucceed: (res: GetPickupRoutesAPIParam) => void,
  onError: (err: AxiosError) => void
) => {
  http
    .post(`/v2/route/dropoffroutes/` + alias + "/add/", params)
    .then((res) => {
      console.log("add dropoff alias", res);
      onSucceed(res.data);
    })
    .catch(onError);
};

export const completeDropOffBatches = (
  uuid: string,
  onSucceed: (res: GetPickupRoutesAPIParam) => void,
  onError: (err: AxiosError) => void
) => {
  http
    .post(`/v1/route/dropoffbatches/` + uuid + "/complete/", {})
    .then((res) => {
      console.log("complete dropoff batches", res);
      onSucceed(res.data);
    })
    .catch(onError);
};

export const completeRouteDropOff = (
  alias: string,
  onSucceed: (res: GetPickupRoutesAPIParam) => void,
  onError: (err: AxiosError) => void
) => {
  http
    .post(`/v1/route/dropoffroutes/` + alias + "/complete/", {})
    .then((res) => {
      console.log("dropoff route complete", res);
      onSucceed(res.data);
    })
    .catch(onError);
};

export const getAllPickUpBatches = (
  params: object,
  onSucceed: (res: GetPickupRoutesAPIParam) => void
) => {
  http
    .get(`/v1/route/pickupbatches/`, {
      params,
    })
    .then((res) => {
      console.log("getAllPickupBatches", res);
      onSucceed(res.data);
    })
    .catch(showApiError);
};

export const getAllDropOffBatches = (
  params: object,
  onSucceed: (res: GetPickupRoutesAPIParam) => void
) => {
  http
    .get(`/v1/route/dropoffbatches/`, {
      params,
    })
    .then((res) => {
      console.log("getAllDropOffBatches", res);
      onSucceed(res.data);
    })
    .catch(showApiError);
};

export const pickupDelay = (
  uuid: string,
  onSucceed: (res: GetPickupRoutesAPIParam) => void,
  onError: (err: AxiosError) => void
) => {
  http
    .post("/v1/ops/pickupbatches/" + uuid + "/notify/", {
      notification_type: "DELAY",
    })
    .then((res) => {
      console.log("delay notification pickup", res);
      onSucceed(res.data);
    })
    .catch(onError);
};

export const completeDropoffRouteRequestMiddlemile = (
  alias: string,
  phone: string,
  onSucceed: (res: GetPickupRoutesAPIParam) => void,
  onError: (err: AxiosError) => void
) => {
  http
    .post(`/v1/route/dropoffroutes/${alias}/request/`, {
      phonenumber: phone,
    })
    .then((res) => {
      console.log("complete route for middlemile", res);
      onSucceed(res.data);
    })
    .catch(onError);
};
