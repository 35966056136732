import React from "react";
import ReactDOM from "react-dom/client";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact, {
  BugsnagPluginReactResult,
} from "@bugsnag/plugin-react";
import "./index.css";
import "antd/dist/antd.css";
import "@delivus/daas-print-lib/dist/esm/index.css";
import "@delivus/daas-leftmenu-template/dist/esm/index.css";
import "./locales/i18n";
import App from "./App";
import store from "./reducers/store";
// import {
//   initFirebaseAnalytics,
//   initializeFirebase,
// } from "./services/push.service";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import { onSuccess, onUpdate } from "./registrationStatus";
import { Provider } from "react-redux";

Bugsnag.start({
  apiKey: process.env.REACT_APP_BUGSNAG_KEY || "",
  plugins: [new BugsnagPluginReact()],
});

const plugin = Bugsnag.getPlugin("react") as BugsnagPluginReactResult;
const ErrorBoundary: any = plugin.createErrorBoundary(React);
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <ErrorBoundary>
    <React.StrictMode>
      <Provider store={store}>
        <App />
      </Provider>
    </React.StrictMode>
  </ErrorBoundary>
);

// @ts-ignore
if (window.Cypress) {
  // @ts-ignore
  window.store = store;
}

serviceWorkerRegistration.register({
  onUpdate,
  onSuccess,
});
// initializeFirebase();
// initFirebaseAnalytics();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
