import React, { forwardRef } from "react";
import styles from "./filter.module.css";

import { Checkbox } from "antd";
import { SellerBatchType } from "../../../api/apiTypes";
import InfiniteList from "../../molecules/list/InfiniteList";
import { CheckboxGroupProps } from "antd/es/checkbox";

interface Props extends CheckboxGroupProps {
  onSearch?: (value: string[]) => void;
}

const SellerBatchFilter = forwardRef(({ onSearch, ...rest }: Props, ref) => {
  const handleChangeChecked = (checkedValues: any[]) => {
    console.log("checked = ", checkedValues);
    !!onSearch && onSearch(checkedValues);
  };

  return (
    <InfiniteList
      className={styles.container}
      url={"/v1/hub/pickupbatches/"}
      params={{ordering: '-round'}}
      style={{ overflowX: "hidden" }}
    >
      {(count?: number, sectors?: SellerBatchType[], isLoading?) => {
        return (
          <Checkbox.Group
            {...rest}
            className={styles.list + " column"}
            onChange={handleChangeChecked}
          >
            {sectors && sectors.length > 0
              ? sectors.map((s, idx) => (
                  <Checkbox
                    key={"chek" + idx}
                    className={styles.check}
                    data-cy="homeManageCheckbox"
                    value={s.uuid}
                  >
                    {`${s.seller_name} ${s.round}(${
                      s.return_count + s.shipping_count
                    })`}
                  </Checkbox>
                ))
              : isLoading || "데이터가 없습니다"}
          </Checkbox.Group>
        );
      }}
    </InfiniteList>
  );
});

export default SellerBatchFilter;
