import React, { forwardRef, useImperativeHandle, useState } from "react";

import { Button, Space } from "antd";
import { TClusterPlanItem } from "../../../api/apiTypes";
import { useTranslation } from "react-i18next";
import { showMessage } from "../../../fns/message";
import { MergePlanPopup, SearchParams } from "./MergePlanPopup";
import { ShiftPlanPopup } from "./ShiftPlanPopup";
import { IShippingPlanMarker } from "./PlanMap";
import { CreatetPlanPopup } from "./CreatetPlanPopup";

interface Props {
  onShifted: () => void;
  onMerged: () => void;
  params: SearchParams;
  planNo?: number | string;
  planDate: string;
}

export const PlanControlButtons = forwardRef(
  ({ onShifted, onMerged, planNo, planDate, params: {sector_code__in, ...params} }: Props, ref) => {
    const { t } = useTranslation("shipping");
    const [selectedPlans, setSelectedPlans] = useState<TClusterPlanItem[]>();
    const [selectedTrackings, setSelectedTrackings] = useState<string[]>();
    const [isVisibleMerging, setMergingVisibility] = useState(false);
    const [isVisibleShifting, setShiftingVisibility] = useState(false);
    const [isVisibleCreation, setCreationVisibility] = useState(false);

    useImperativeHandle(
      ref,
      () => ({
        setPlans: setSelectedPlans,
        setShippings: createTrackingList,
      }),
      []
    );

    function createTrackingList(selectedShippings: IShippingPlanMarker[]) {
      const trackingList: string[] = [];
      selectedShippings &&
        selectedShippings.forEach(({ shipping_item__tracking_number }) => {
          trackingList.push(shipping_item__tracking_number);
        });
      setSelectedTrackings(trackingList);
    }

    const handleCreatePlan = () => {
      if (!planNo) {
        showMessage(t("Please, select plan no first"), "error");
        return;
      }
      if (!!selectedTrackings && selectedTrackings.length > 0) {
        setCreationVisibility(true);
      } else {
        showMessage(t("popup.error.empty"), "error");
      }
    };

    const handleMergePlan = () => {
      if (!!selectedPlans && selectedPlans.length > 0) {
        if (selectedPlans.length === 1) {
          setMergingVisibility(true);
        } else {
          showMessage(t("Please, select only one plan to merge"), "warning");
        }
      } else {
        showMessage(t("Please, select plan"), "warning");
      }
    };

    const handleShiftShipping = () => {
      if (!!selectedTrackings && selectedTrackings.length > 0) {
        setShiftingVisibility(true);
      } else {
        showMessage(t("popup.error.empty"), "warning");
      }
    };

    return (
      <Space size={1}>
        <Button
          type={"primary"}
          style={{height: 32, borderRadius: 2}}
          onClick={handleCreatePlan}
        >
          {t("create plan")}
        </Button>
        <Button
          type={"primary"}
          size={"small"}
          style={{height: 32, borderRadius: 2}}
          onClick={handleMergePlan}
        >
          {t("merge plan")}
        </Button>
        <Button
          type={"primary"}
          size={"small"}
          style={{height: 32, borderRadius: 2}}
          onClick={handleShiftShipping}
        >
          {t("shift shipping")}
        </Button>
        {isVisibleMerging && selectedPlans && selectedPlans?.length > 0 && (
          <MergePlanPopup
            plan={selectedPlans[0]}
            params={params}
            onMerged={onMerged}
            onHide={() => setMergingVisibility(false)}
          />
        )}
        {isVisibleShifting &&
          selectedTrackings &&
          selectedTrackings?.length > 0 && (
            <ShiftPlanPopup
              trackingNumbers={selectedTrackings}
              params={params}
              onShifted={onShifted}
              onHide={() => setShiftingVisibility(false)}
            />
          )}
        {isVisibleCreation &&
          selectedTrackings &&
          selectedTrackings?.length > 0 &&
          planNo && (
            <CreatetPlanPopup
              trackingNumbers={selectedTrackings}
              planNo={planNo}
              planDate={planDate}
              onCreated={onMerged}
              onHide={() => setCreationVisibility(false)}
            />
          )}
      </Space>
    );
  }
);
