import React, { forwardRef, useImperativeHandle, useState } from "react";

import { Button, Input, Modal } from "antd";
import { useTranslation } from "react-i18next";
import { showMessage } from "../../fns/message";

interface Props {
  onOK: (id: string, inpuValue: string) => void;
  title: string;
}

const InputPopup = forwardRef(({ onOK, title }: Props, ref) => {
  const { t } = useTranslation("dispatch");
  const [id, setID] = useState("");
  const [inputValue, setInputValue] = useState("");

  useImperativeHandle(
    ref,
    () => ({
      show: (uuid: string, value: string) => {
        setID(uuid);
        setInputValue(value);
      },
      hide: hide,
    }),
    []
  );

  const handleOk = () => {
    if (!!inputValue) {
      onOK(id, inputValue);
      hide();
    } else {
      showMessage(t("premium.popup.empty"));
    }
  };

  const hide = () => {
    setID("");
    setInputValue("");
  };

  const buttonNode = (
    <div className={"row "}>
      <Button
        type={"text"}
        className={"white bold body popup-cancel"}
        onClick={hide}
      >
        {t("assign.popup.btn.cancel")}
      </Button>
      <div className={"modal-divider"} />
      <Button type={"text"} className={"bold body popup-ok"} onClick={handleOk}>
        {t("premium.popup.btn.ok")}
      </Button>
    </div>
  );

  return (
    <Modal
      closable={false}
      cancelText={""}
      open={!!id}
      maskClosable
      centered
      className={"sm-modal"}
      footer={buttonNode}
    >
      <div className={"white subtitle2 bold"}>{title}</div>
      <Input
        inputMode="numeric"
        value={inputValue}
        onChange={(e: any) => {
          const formatted = e.target.value.replaceAll(",", "");
          setInputValue(formatted);
        }}
        className={"popup-input"}
        placeholder={t("premium.popup.input")}
        autoComplete={"off"}
      />
    </Modal>
  );
});

export default InputPopup;
