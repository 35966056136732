import axios from "axios";
import { TokenStorage } from "./token.service";
import { updateAuthenticated } from "../reducers/authReducer";
import { Dispatch } from "redux";

const http = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    Accept: "*/*",
    "Content-Type": "application/json",
  },
  timeout: 30000,
});

http.interceptors.request.use(
  async (config) => {
    let accessToken = TokenStorage.getToken();
    console.log("token", accessToken);
    if (accessToken) {
      config.headers["authorization"] = `Bearer ${accessToken}`;
      return config;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export const setResponse = (dispatch: Dispatch<any>) => {
  http.interceptors.response.use(
    (response) => {
      // Return a successful response back to the calling service
      return response;
    },
    (error) => {
      if (error.response?.status === 403) {
        return new Promise((resolve, reject) => {
          TokenStorage.clear();
          dispatch(updateAuthenticated(false));
          reject(error);
        });
      }
      // Return any error which is not due to authentication back to the calling service
      if (error.response?.status !== 401) {
        return new Promise((resolve, reject) => {
          reject(error);
        });
      }

      if (error.config.url.endsWith("/auth/token/refresh/")) {
        TokenStorage.clear();
        dispatch(updateAuthenticated(false));
        return new Promise((resolve, reject) => {
          reject(error);
        });
      }

      // Try request again with new token
      return TokenStorage.cachedGetNewToken()
        .then((response) => {
          if (error.config.url.includes("/auth/")) {
            return response;
          }
          const config = error.config;
          return new Promise((resolve, reject) => {
            http.request(config).then(resolve).catch(reject);
          });
        })
        .catch((error: any) => {
          TokenStorage.clear();
          dispatch(updateAuthenticated(false));
          Promise.reject(error);
        });
    }
  );
};

export const fetcher = (url: string) => http.get(url).then((res) => res?.data);

export default http;
