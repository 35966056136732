import React, { useCallback, useEffect, useRef, useState } from "react";
import "./box.style.css";
import { useTranslation } from "react-i18next";
import InfiniteList from "../../molecules/list/InfiniteList";
import {
  CheckinBoxApiParams,
  CheckoutBoxApiParams,
  LogicalBoxType,
} from "../../../api/apiTypes";
import LeftTitledTemplate from "../../templates/leftTitled/LeftTitledTemplate";
import BoxRegisterItem from "../../organisms/BoxRegisterItem";
import {
  checkinBatchBox,
  checkoutBatchBox,
  getContainerSts,
  updateContainer,
  checkinPlanAll,
} from "../../../api/shippingApi";
import { updateBoxSts } from "../../../reducers/profileReducer";
import { useDispatch } from "react-redux";
import { isDesktopByWidth } from "../../../fns/commonFns";
import EmptyContent from "../../molecules/emptyContent/EmptyContent";
import SearchView, {
  CheckType,
  SearchResultType,
} from "../../organisms/searchView/SearchView";
import {
  showApiError,
  showMessage,
  showMessageWithTitle,
} from "../../../fns/message";
import { BoxLabelFormat } from "@delivus/daas-print-lib";
import PrintView from "../../molecules/print/PrintView";
import { getLocations } from "../../../api/locationApi";
import TabbarView from "../../molecules/tabbar/TabbarView";
import { MenuProps } from "antd";
import { ItemType } from "antd/lib/menu/hooks/useItems";

const BoxRegister = () => {
  const { t } = useTranslation(["box"]);
  const dispatch = useDispatch();
  const printRef = useRef<any>();
  const dataRef = useRef<LogicalBoxType[]>();
  const selectedRef = useRef<LogicalBoxType[]>([]);
  const [refresh, setRefresh] = useState(false);
  const [params, setParams] = useState<any>();
  const [sortBy, setSortBy] = useState("all");
  const [options, setOptions] = useState<CheckType[]>([]);

  useEffect(() => {
    getLocations((res) => {
      const options: CheckType[] = [{ title: t("all"), value: "all" }];
      res.results.forEach((r) => {
        const opt: CheckType = { title: r.code, value: r.code };
        options.push(opt);
      });
      setOptions(options);
    });
  }, []);

  const handleGetCount = (count: number, data: any[]) => {
    dataRef.current = data;
  };

  const handleRegisterDone = () => {
    setRefresh((prev) => !prev);
    selectedRef.current = [];
    getContainerSts((cnt) => {
      dispatch(updateBoxSts(cnt));
    });
  };

  const handleSearch = ({ inputValue, selectedOption }: SearchResultType) => {
    setParams({ search: inputValue });
    setSortBy(selectedOption || "");
  };

  const handlePrint = (data?: LogicalBoxType[], damaged?: boolean) => {
    const array = !!data && data.length > 0 ? data : selectedRef.current;
    selectedRef.current = array;
    if (!!array && array.length > 0) {
      if (!!printRef.current) {
        printRef.current.show();
      }
      const invoivesNode = array.map(({ box, container }, index: number) => (
        <BoxLabelFormat
          key={"print" + index}
          alias={box?.alias || ""}
          unit_storage_name={container?.unit_storage || ""}
        />
      ));
      if (!!printRef.current) {
        printRef.current.setContent(invoivesNode, damaged);
      }
    } else {
      showMessageWithTitle(
        t("box.item.popup.print.success.title"),
        t("error.popup.box.print.notselected"),
        "warning"
      );
    }
  };

  const onPrinted = (damaged?: boolean) => {
    window.onfocus = null;
    let successOrderCnt = 0;
    if (damaged) {
      showMessageWithTitle(
        t("box.item.popup.print.success.title"),
        t("box.item.popup.print.success.content")
      );
    } else {
      const array =
        !!selectedRef.current && selectedRef.current.length > 0
          ? selectedRef.current
          : dataRef.current;
      if (array) {
        const req = array.map(
          ({ container }: LogicalBoxType): Promise<number> => {
            return new Promise<number>((resolve) => {
              console.log("handlePrinted", container);
              if (container && container.uuid) {
                updateContainer(
                  container.uuid,
                  { count_box: (container.count_box || 0) + 1 },
                  () => setRefresh((prev) => !prev)
                );
              } else {
                resolve(successOrderCnt);
              }
            });
          }
        );
        Promise.all<number>(req).then((cnt) => {
          showMessageWithTitle(
            t("box.item.popup.print.success.title"),
            t("box.item.popup.print.success.content")
          );
        });
      }
    }
  };

  const handlePrintAll = () => {
    handlePrint(dataRef.current);
  };

  const handleCheckinAll = (value: string) => {
    handleCheckin(value, dataRef.current);
  };

  const handleCheckin = (key: string, data?: LogicalBoxType[]) => {
    const array = !!data && data.length > 0 ? data : selectedRef.current;
    if (!!array && array.length > 0) {
      const uuidList: CheckinBoxApiParams = [];
      array.forEach((s: LogicalBoxType) => {
        if (!s.box && s.sector?.id) uuidList.push({ sector_id: s.sector?.id });
      });
      if (uuidList.length > 0) {
        if (key === t("box.item.btn.checkin.gen")) {
          checkinBatchBox(
            uuidList,
            () => {
              handleRegisterDone();
              if (array.length === 1) showMessage(t("popup.checkin.done"));
              else showMessage(t("popup.checkin.done"));
            },
            (err) => {
              showApiError(err);
            }
          );
        } else if (key === t("box.item.btn.checkin.plan")) {
          const uuidListNew: CheckinBoxApiParams = [];
          array.forEach((s: LogicalBoxType) => {
            if (!s.box && s.sector?.id)
              uuidListNew.push({ sector_id: s.sector?.id, is_plan: true });
          });
          checkinPlanAll(
            uuidListNew,
            () => {
              handleRegisterDone();
              if (array.length === 1) showMessage(t("popup.checkin.done"));
              else showMessage(t("popup.checkin.done"));
            },
            (err) => {
              showApiError(err);
            }
          );
        }
      } else {
        showMessage(t("error.popup.checkin.empty.available"));
      }
    } else {
      showMessageWithTitle(
        t("box.item.popup.print.success.title"),
        t("error.popup.sector.empty"),
        "warning"
      );
    }
  };

  const handleCloseAll = () => {
    handleClose(dataRef.current);
  };

  const handleClose = (data?: LogicalBoxType[]) => {
    const array = !!data && data.length > 0 ? data : selectedRef.current;
    if (!!array && array.length > 0) {
      const uuidList: CheckoutBoxApiParams = [];
      array.forEach((s: LogicalBoxType) => {
        if (s.box?.uuid) uuidList.push({ box_uuid: s.box?.uuid });
      });
      checkoutBatchBox(
        uuidList,
        () => {
          handleRegisterDone();
          if (array.length === 1) showMessage(t("popup.checkout.done"));
          else showMessage(t("popup.checkout.all.done"));
        },
        (err) => {
          showApiError(err);
        }
      );
    } else {
      showMessageWithTitle(
        t("box.item.popup.print.success.title"),
        t("error.popup.box.print.notselected"),
        "warning"
      );
    }
  };

  const handleSelect = useCallback((box: LogicalBoxType, select?: boolean) => {
    selectedRef.current = selectedRef.current.filter(
      (s) => s.box?.uuid !== box.box?.uuid
    );
    if (select) {
      selectedRef.current.push(box);
    }
  }, []);

  const onClickBtn: MenuProps["onClick"] = ({ key }) => {
    handleCheckinAll(key as string);
  };

  const items: MenuProps["items"] = [
    {
      label: t("box.item.btn.checkin.gen"),
      key: t("box.item.btn.checkin.gen"),
    },
    {
      label: t("box.item.btn.checkin.plan"),
      key: t("box.item.btn.checkin.plan"),
    },
  ];

  const buttons = [
    {
      title: t("box.reg.checkin.all"),
      onClick: () => console.log("open dropdown"),
      objectType: "dropdown",
      menu: { items, onClick: onClickBtn, selectable: true },
    },
    { title: t("box.reg.checkin"), onClick: handleCheckin },
    { title: t("box.reg.close.all"), onClick: handleCloseAll },
    { title: t("box.reg.close"), onClick: handleClose },
    { title: t("box.reg.print.all"), onClick: handlePrintAll },
    { title: t("box.reg.print"), onClick: handlePrint },
  ];

  console.log("render", selectedRef.current, dataRef.current);
  return (
    <>
      <LeftTitledTemplate classname={" box-register-container "} showBreadTitle>
        <SearchView
          searchLabel={t("box.search.label")}
          options={options}
          buttons={buttons}
          onSearch={handleSearch}
        />
        <InfiniteList
          url={"/v1/hub/containers/sectors/"}
          refresh={refresh}
          loadingClass={"box-register-loading"}
          params={params}
          onGetCount={handleGetCount}
          height={
            isDesktopByWidth()
              ? window.innerHeight - 260
              : window.innerHeight - 150
          }
        >
          {(
            count?: number,
            results?: LogicalBoxType[],
            isLoading?: boolean
          ) => {
            const filtered =
              sortBy === "all"
                ? results
                : results &&
                  results.filter(({ sector }, index) =>
                    sector?.code ? sector.code.includes(sortBy) : false
                  );
            return (
              <div>
                {filtered && filtered.length > 0
                  ? filtered.map((order, index) => (
                      <BoxRegisterItem
                        key={"order" + index}
                        {...order}
                        refresh={refresh}
                        data-cy="boxDetailRemove"
                        onClick={handleSelect}
                        onPrint={handlePrint}
                        onCloseDone={handleRegisterDone}
                      />
                    ))
                  : isLoading || (
                      <EmptyContent
                        title={t("empty.title")}
                        label={t("empty.content")}
                      />
                    )}
              </div>
            );
          }}
        </InfiniteList>
        <PrintView
          ref={printRef}
          onPrinted={onPrinted}
          btnOk={t("box.item.btn.label")}
        />
      </LeftTitledTemplate>
      <TabbarView />
    </>
  );
};

export default BoxRegister;
