import React from "react";

import { Button } from "antd";
import styles from "./plan.module.css";

interface Props {
  title?: string;
  img: any;
  isActive?: boolean;
  isQuadrat?: boolean;
  onClick: () => void;
}

export const PlanMapButton = ({
  onClick,
  img: Img,
  title,
  isActive,
  isQuadrat,
}: Props) => (
  <Button
    type={"primary"}
    size={"small"}
    className={
      styles.planBtn +
      " " +
      (isActive ? "" : styles.planBtnWhite) +
      " " +
      (isQuadrat ? styles.btnQuad : "")
    }
    onClick={onClick}
  >
    <div className={"row align-center " + styles.planBtnInner}>
      <Img color={isActive ? "white" : "black"} />
      {title}
    </div>
  </Button>
);
