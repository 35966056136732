import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import DropoffMapScreen from "../components/screens/routes/DropoffMapScreen";
import PickupMapScreen from "../components/screens/routes/PickupMapScreen";

function Routes() {
  const renderRouteRedirect = () => <Redirect to={"/route/pickup/"} />;

  return (
    <Switch>
      <Route exact path="/route/pickup/" component={PickupMapScreen} />
      <Route exact path="/route/dropoff/" component={DropoffMapScreen} />
      <Route path="*" component={renderRouteRedirect} />
    </Switch>
  );
}

export default Routes;
