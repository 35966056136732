import React, { ReactNode } from "react";
import styles from "./lefttemplate.module.css";
import Navbar, { NavbarProps } from "../../molecules/navbar/NavbarView";
import { CustomBreadcrumb } from "@delivus/daas-leftmenu-template";
import { isDesktopByWidth } from "../../../fns/commonFns";
import { useTranslation } from "react-i18next";

interface Props {
  classname?: string;
  showBreadTitle?: boolean;
  header?: string;
  headerProps?: NavbarProps;
  breadButton?: { title: string; onClick: () => void };
  title?: string;
  subtitle?: string;
  children: ReactNode;
  onClickLink?: (path: string) => void;
  paths?: string[];
}
const LeftTitledTemplate = ({
  classname,
  header,
  showBreadTitle,
  headerProps,
  title,
  subtitle,
  children,
  breadButton,
  onClickLink,
  paths,
}: Props) => {
  const { t } = useTranslation("common");
  return (
    <div
      className={
        (!isDesktopByWidth() ? " container " : "") + styles.leftContainer
      }
    >
      {showBreadTitle && (
        <CustomBreadcrumb
          t={t}
          rightButton={breadButton}
          onClickLink={onClickLink}
          paths={paths}
        />
      )}
      {header && <Navbar title={header} {...headerProps} />}
      <div
        className={styles.leftInnerContainer + " inner-container " + classname}
      >
        {title && (
          <div className={"title bold " + styles.titleInner}>{title}</div>
        )}
        {subtitle && (
          <div className={"body " + styles.subtitleInner}>{subtitle}</div>
        )}
        {children}
      </div>
    </div>
  );
};

export default LeftTitledTemplate;
