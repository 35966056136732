import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import LoginPhoneContainer from "../components/screens/login/LoginPhoneContainer";
function AuthRoutes() {
  const renderAuthRedirect = () => <Redirect to={"/auth/login"} />;

  return (
    <Switch>
      <Route exact path="/auth/login" component={LoginPhoneContainer} />
      <Route path="*" component={renderAuthRedirect} />
    </Switch>
  );
}

export default AuthRoutes;
