import React, { useMemo } from "react";
import { useLocation } from "react-router-dom";
import Box from "../../../assets/svgs/tab.box";
import List from "../../../assets/svgs/tab.list";
import Scan from "../../../assets/svgs/tab.scan";
import styles from "./tabbar.module.css";
import TabItem from "./TabItem";
import { isDesktopByWidth } from "../../../fns/commonFns";

const tabs = [
  {
    route: "/home/box",
    icon: Box,
    label: "home.box",
  },
  {
    route: "/home/scan",
    icon: Scan,
    label: "scan",
  },
  {
    route: "/home/list",
    icon: List,
    label: "home.list",
  },
  {
    route: "/home/scan/return",
    icon: Scan,
    label: "return.scan",
  },
];
const TabbarView = () => {
  const location = useLocation();
  const path = useMemo(() => location.pathname, [location.key]);
  if (!path.includes("home")) {
    return null;
  }
  return (
    <nav
      className={
        (isDesktopByWidth() ? "absolute " : "fixed ") + styles.tabContainer
      }
      role="navigation"
    >
      {tabs.map((tab, index) => (
        <TabItem
          key={`tab-${index}`}
          data-cy="tabBarView"
          {...tab}
          active={path.endsWith(tab.route)}
        />
      ))}
    </nav>
  );
};

export default TabbarView;
