import { fetcher } from "../services/http.service";
import { GetListResponse, TCoord, TCoordinatesItem } from "./apiTypes";
import { useEffect, useRef, useState } from "react";
import useSWRInfinite from "swr/infinite";
import { queries } from "./queries";
import { useSelector } from "react-redux";
import { RootState } from "../reducers/reducer";
import { getRandomColorSector } from "../fns/objectFns";
import { fromLonLat } from "ol/proj";
import { ObjectWith } from "../components/screens/plan/PlanMapScreen";
import { PolygonProps } from "@delivus/react-open-layers/dist/esm/types/polygon/polygon.type";

const BOUND_GAP = 0.1;
export const useCoordinates = (use: boolean, bound?: Array<number>) => {
  const coloredSectorsRef = useRef<ObjectWith>({});
  const { authenticated } = useSelector((state: RootState) => state.auth);
  const [coordinates, setCoordinates] = useState<PolygonProps[]>();
  const getKey = (
    pageIndex: number,
    previousPageData: GetListResponse<TCoordinatesItem>
  ) => {
    console.log("coordinates*** getKey ", pageIndex, previousPageData);
    if (authenticated && pageIndex === 0 && use && bound && bound.length > 3)
      return (
        queries.location.coordinate +
        `?page_size=100&min_x=${bound[0] - BOUND_GAP}&min_y=${
          bound[1] - BOUND_GAP
        }&max_x=${bound[2] + BOUND_GAP}&max_y=${bound[3] + BOUND_GAP}`
      );
    if (previousPageData && previousPageData.next) return previousPageData.next;
    return null; // reached the end
  };

  const { data, error, setSize } = useSWRInfinite<
    GetListResponse<TCoordinatesItem>
  >(getKey, fetcher, {
    initialSize: 1,
    revalidateOnFocus: false,
    revalidateIfStale: false,
    revalidateFirstPage: false,
  });

  console.log("render**** useCoordinates", data, error, coordinates);
  useEffect(() => {
    if (data && data?.length > 0) {
      const lastRes = data[data?.length - 1];
      console.log("coordinates 1", data, lastRes);
      if (
        lastRes &&
        lastRes.current_page * lastRes.items_per_page < lastRes.count
      )
        setSize((size) => size + 1);
      else {
        let listData: PolygonProps[] = [];
        data.forEach((item) => {
          console.log("coordinates 2", item);
          if (item?.results) {
            item.results.forEach(({ coords, code }, index) => {
              const polygon = createPolygonFromCoordinate(coords, code);
              if (polygon) listData.push(polygon);
            });
          }
        });
        setCoordinates(listData);
      }
    }
  }, [data]);

  const createPolygonFromCoordinate = (coords: TCoord[], code: string) => {
    let polygon: PolygonProps | null = null;
    if (!!coords && !!coords.map) {
      const color = getRandomColorSector(
        code,
        coloredSectorsRef.current[code],
        true
      );
      coloredSectorsRef.current[code] = color;
      const coordinates = coords.map(({ lng, lat }) => fromLonLat([lng, lat]));
      polygon = {
        strokeWidth: 2.5,
        strokeColor: color,
        fillColor: color + "25",
        code,
        coordinates,
      };
    }
    return polygon;
  };

  return {
    coordinates,
    error,
  };
};
