import React, {
  forwardRef,
  ReactNode,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import "./timer.css";
import { differenceInSeconds } from "date-fns";

type Props = {
  ref: any;
  children?: (sec: number, min: number) => ReactNode;
  hoursMinSecs?: {
    minutes?: number;
    seconds?: number;
  };
  onEnd: () => void;
};
const Timer = forwardRef(({ hoursMinSecs, onEnd, children }: Props, ref) => {
  const [[mins, secs], setTime] = useState([0, 0]);

  useImperativeHandle(
    ref,
    () => ({
      reset: (endDate?: Date) => {
        reset(endDate);
      },
    }),
    []
  );

  const tick = () => {
    if (mins === 0 && secs === 0) {
      onEnd();
    } else if (secs === 0) {
      setTime([mins - 1, 59]);
    } else {
      setTime([mins, secs - 1]);
    }
  };

  const reset = (endDate?: Date) => {
    let minutes = 0;
    let seconds = 0;
    if (hoursMinSecs) {
      minutes = hoursMinSecs.minutes || 0;
      seconds = hoursMinSecs.seconds || 0;
    } else if (!!endDate) {
      const now = new Date();
      const totalSec = differenceInSeconds(endDate, now);
      minutes = Math.floor(totalSec / 60);
      seconds = totalSec % 60;
    }
    console.log("timer reset", minutes, seconds, endDate);
    setTime([minutes, seconds]);
  };

  useEffect(() => {
    const timerId = setInterval(() => tick(), 1000);
    return () => clearInterval(timerId);
  }, [mins, secs]);

  return (
    <>
      {children ? (
        children(secs, mins)
      ) : (
        <div className={"timer-text body"}>
          <p>{`${mins.toString().padStart(2, "0")}:${secs
            .toString()
            .padStart(2, "0")}`}</p>
        </div>
      )}
    </>
  );
});

export default Timer;
