import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  getHubList,
  getDropoffRoutes,
  getDropoffBatchList,
  getRouteDropOffBatches,
  createRouteDropOff,
  addDropOffAlias,
  completeDropOffBatches,
  completeRouteDropOff,
  getAllDropOffBatches,
  completeDropoffRouteRequestMiddlemile,
} from "../../../api/routeApi";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { StyledEngineProvider } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import map_marker from "../../../assets/images/dropoff_marker.png";
import "./index.css";
import toggleUp from "../../../assets/images/toggle_up.png";
import toggleDown from "../../../assets/images/toggle_down.png";
import { Empty } from "antd";
import { AutoComplete, Input, Checkbox as Check, DatePicker } from "antd";
import Button from "@mui/material/Button";
import { Select } from "antd";
import { Tree } from "antd";
import drag_icon from "../../../assets/images/drag_icon.png";
import { showApiError, showMessage } from "../../../fns/message";
import {
  DataNew,
  createDropOffDataNew,
  pickupDataType,
  EnhancedTableProps,
  BatchTableProps,
  EnhancedTableToolbarProps,
  defaultDropoffParams,
  headDropOffCellsNew,
  DropOffDataNew,
  headDropOffCellsList,
  createDropOffDataList,
  DropOffDataList,
  defaultDropoffBatchParams,
  createDropOffBatchData,
  BatchHeadDropOffCell,
  DropOffBatchData,
} from "./routes-types";
import map_marker_inactive from "../../../assets/images/dropoff_marker_inactive.png";
import { useTranslation } from "react-i18next";
import PushConfirm from "../../molecules/popup/Push/pushConfirm";
import PushPopup from "../../molecules/popup/Push/pushPopup";

const { RangePicker } = DatePicker;

const defaultData: any[] | (() => any[]) = [];
type Order = "asc" | "desc";

const DropOffMapScreen = () => {
  const { t } = useTranslation(["dropoff"]);
  const [value, setValue] = useState<string>(t("tab.dropoff_route_new"));
  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState<keyof DataNew>("hub");
  const [selected, setSelected] = useState<readonly string[]>([]);
  const [selectedList, setSelectedList] = useState<readonly string[]>([]);
  const [selectedBatch, setSelectedBatch] = useState<readonly string[]>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [dropOffData, setDropOffData] = useState<pickupDataType[]>([]);
  const [showMap, setShowMap] = useState(false);
  const [mapCoordinates, setMapCoordinates] = useState<any[]>([]);
  const [completeToggle, setCompleteToggle] = useState<string>(
    t("table.filter.dropdown.placeholder")
  );
  const [dateSort, setDateSort] = useState("today");
  const [rangeDate, setRangeDate] = useState<any>(null);
  const [batchTableData, setBatchTableData] = useState([]);
  const [rowsNew, setRowsNew] = useState<any>([]);
  const [rowsBatch, setRowsBatch] = useState<any>([]);
  const [createDropOffRoute, setCreateDropOffRoute] = useState(false);
  const [batchEditPopup, setBatchEditPopup] = useState(false);
  const [hubListSelect, setHubListSelect] = useState([]);
  const [selectedHub, setSelectedHub] = useState<string>(
    t("table.filter.dropdown.placeholder")
  );
  const [routePrice, setRoutePrice] = useState("");
  const [batchEditData, setBatchEditData] = useState([]);
  const [selectedTreeData, setSelectedTreeData] = useState<any>([]);
  const [selectAllTree, setSelectAllTree] = useState(false);
  const [reload, setReload] = useState(false);
  const [inputAlias, setInputAlias] = useState("");
  const [reloadBatch, setReloadBatch] = useState(false);
  const [pageCount, setPageCount] = useState(1);
  const [selectedAlias, setSelectedAlias] = useState("");
  const [mapBatches, setMapBatches] = useState<any[]>([]);
  const [clickedMarker, setClickedMarker] = useState<any>([]);
  const [hideFirstTable, setHideFirstTable] = useState(false);
  const [hideSecondTable, setHideSecondTable] = useState(false);
  const [emptyRows, setEmptyRows] = useState(0);
  const [showPushPopup, setShowPushPopup] = useState(false);
  const [pushMobile, setPushMobile] = useState("");
  const [showPushConfirm, setShowPushConfirm] = useState(false);
  const [pushAlias, setPushAlias] = useState("");

  const loadRef = useRef<any>();
  const plotRef = useRef<any>();

  const dataSource: any = [];

  useEffect(() => {
    if (
      selectedList.length > 0 &&
      rowsBatch.length === 0 &&
      batchTableData.length === 0
    ) {
      getDropoffBatchList(selectedList[0], (res) => {
        setMapBatches(res.results);
        setBatchTableData(res.results);
      });
    } else {
      getAllDropOffBatches({ completed: false, page_size: 50 }, (res) => {
        setMapBatches(res.results);
      });
    }
  }, [showMap, value, selectedList, reloadBatch, batchTableData, rowsBatch]);

  const iconLoad = (completed: boolean) => (
    <div className={"icon-marker"}>
      <img
        alt={map_marker}
        src={completed ? map_marker_inactive : map_marker}
        className={"map-markers"}
      />
      <div className={"map-counts"}>{""}</div>
    </div>
  );

  const checkDigit = (d: any) => {
    if (d.toString().length === 1) {
      return "0" + d.toString();
    }
    return d;
  };

  const getTimStamp = (d: string) => {
    const dx = new Date(d);
    const months = dx.getMonth() + 1;
    return (
      dx.getFullYear() +
      "-" +
      checkDigit(months) +
      "-" +
      checkDigit(dx.getDate()) +
      " " +
      checkDigit(dx.getHours()) +
      ":" +
      checkDigit(dx.getMinutes()) +
      ":" +
      checkDigit(dx.getSeconds())
    );
  };

  useEffect(() => {
    if (dropOffData.length > 0) {
      let rowsVal: any = [];

      for (let i = 0; i < dropOffData.length; i++) {
        const dropOff: any = dropOffData[i];
        const d_start = getTimStamp(dropOff.timestamp_created);
        let d_stop = "";
        if (dropOff.timestamp_completed) {
          d_stop = getTimStamp(dropOff.timestamp_completed);
        }
        if (value === t("tab.dropoff_route_new")) {
          rowsVal.push(
            createDropOffDataNew(
              dropOff?.main_hub?.name ?? " ",
              dropOff?.main_hub?.address?.address1 ??
                " " + dropOff?.main_hub?.address?.address2 ??
                " ",
              dropOff?.alias ?? " ",
              dropOff?.price ?? " ",
              dropOff?.main_hub?.address?.mobile_tel ?? " ",
              d_start,
              <Button
                onClick={(e) => {
                  setSelectedAlias(dropOff.alias);
                  setBatchEditPopup(true);
                }}
                className={"pickupBatch"}
                variant="contained"
              >
                {t("button.edit_route")}
              </Button>
            )
          );
        } else {
          rowsVal.push(
            createDropOffDataList(
              dropOff?.main_hub?.name ?? " ",
              dropOff?.main_hub?.address?.address1 ??
                " " + dropOff?.main_hub?.address?.address2 ??
                " ",
              dropOff?.alias ?? " ",
              dropOff?.count_unit ?? " ",
              dropOff?.price ?? " ",
              dropOff?.main_hub?.address?.mobile_tel ?? " ",
              dropOff.completed ? "complete" : "incomplete",
              d_start,
              d_stop,
              <Button
                onClick={() => {
                  setSelectedAlias(dropOff.alias);
                  setBatchEditPopup(true);
                }}
                disabled={dropOff.completed}
                className={
                  dropOff.completed ? "pickupBatch-disabled" : "pickupBatch"
                }
                variant="contained"
              >
                {t("button.edit_route")}
              </Button>,
              <Button
                disabled={dropOff.completed}
                onClick={() => completeDropOffRoute(dropOff.alias)}
                className={
                  dropOff.completed ? "pickupBatch-disabled" : "pickupBatch"
                }
                variant="contained"
              >
                {t("button.complete_route")}
              </Button>,
              <Button
                onClick={() => {
                  if (!dropOff.completed) {
                    setPushAlias(dropOff.alias);
                    setShowPushPopup(true);
                  }
                }}
                disabled={dropOff.completed}
                className={
                  dropOff.completed
                    ? "pickupBatch-disabled"
                    : dropOff.timestamp_push_sent !== null
                    ? "pickupBatch-disabled-clickable"
                    : "pickupBatch"
                }
                variant="contained"
              >
                {t("button.assignment_push")}
              </Button>
            )
          );
        }
      }
      setRowsNew(rowsVal);
    }
  }, [dropOffData, value]);

  useEffect(() => {
    if (batchTableData.length > 0) {
      let rowsVal: any = [];
      for (let i = 0; i < batchTableData.length; i++) {
        const batch: any = batchTableData[i];
        const d_start = getTimStamp(batch.timestamp_created);
        let d_stop = "";
        if (batch.timestamp_completed) {
          d_stop = getTimStamp(batch.timestamp_completed);
        }
        rowsVal.push(
          createDropOffBatchData(
            batch.uuid,
            batch.unit_storage.name,
            batch.unit_storage.address.address1 +
              batch.unit_storage.address.address2,
            batch.dropoff_route_alias,
            batch.container_count,
            batch.return_container_count,
            batch.return_item_count,
            batch.completed ? "complete" : "incomplete",
            d_stop,
            d_start,
            <Button
              className={
                batch.completed ? "pickupBatch-disabled" : "pickupBatch"
              }
              onClick={() => completeBatchesDropOff(batch.uuid)}
              disabled={batch.completed}
              variant="contained"
            >
              드롭 완료
            </Button>
          )
        );
      }
      setRowsBatch(rowsVal);
    }
  }, [batchTableData]);

  function TableHeadBatch(props: BatchTableProps) {
    const { numSelected, rowCount } = props;

    return (
      <TableHead className={"batch-head"}>
        <TableRow>
          <TableCell
            sx={{
              backgroundColor: "#f5f5f5",
              boxShadow: "inset 0px -1px 0px rgba(0, 0, 0, 0.12)",
              minWidth: dropOffData.length > 0 ? "auto" : "10vw",
            }}
            padding="checkbox"
          >
            <Checkbox
              color="primary"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              inputProps={{
                "aria-label": "select all desserts",
              }}
            />
          </TableCell>
          {BatchHeadDropOffCell.map((headCell) => (
            <TableCell
              sx={{
                backgroundColor: "#f5f5f5",
                boxShadow: "inset 0px -1px 0px rgba(0, 0, 0, 0.12)",
              }}
              key={headCell.id}
              align={"left"}
              style={{
                fontWeight: 700,
                color: "rgba(0, 0, 0, 0.87)",
                whiteSpace: "pre",
                width: "auto",
                minWidth: dropOffData.length > 0 ? "auto" : "9vw",
              }}
              padding={"normal"}
            >
              {t(headCell.label)}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  function EnhancedTableHead(props: EnhancedTableProps) {
    const { onSelectAllClick, numSelected, rowCount } = props;

    return (
      <TableHead id={"enhanced-table-head"} className={"batch-head"}>
        <TableRow>
          <TableCell
            sx={{
              backgroundColor: "#f5f5f5",
              boxShadow: "inset 0px -1px 0px rgba(0, 0, 0, 0.12)",
              width: dropOffData.length > 0 ? "auto" : "10.4vw",
            }}
            padding="checkbox"
          >
            <Checkbox
              color="primary"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={
                value === t("tab.dropoff_route_new")
                  ? onSelectAllClick
                  : (e) => e
              }
              inputProps={{
                "aria-label": "select all desserts",
              }}
            />
          </TableCell>
          {value === t("tab.dropoff_route_new")
            ? headDropOffCellsNew.map((headCell) => (
                <TableCell
                  sx={{
                    backgroundColor: "#f5f5f5",
                    boxShadow: "inset 0px -1px 0px rgba(0, 0, 0, 0.12)",
                  }}
                  key={headCell.id}
                  align={"left"}
                  style={{
                    fontWeight: 700,
                    minWidth: dropOffData.length > 0 ? "auto" : "12.8vw",
                    color: "rgba(0, 0, 0, 0.87)",
                    whiteSpace: "pre",
                    width: "auto",
                  }}
                  padding={"normal"}
                >
                  {t(headCell.label)}
                </TableCell>
              ))
            : headDropOffCellsList.map((headCell) => (
                <TableCell
                  sx={{
                    backgroundColor: "#f5f5f5",
                    boxShadow: "inset 0px -1px 0px rgba(0, 0, 0, 0.12)",
                  }}
                  key={headCell.id}
                  align={"left"}
                  style={{
                    fontWeight: 700,
                    color: "rgba(0, 0, 0, 0.87)",
                    whiteSpace: "pre",
                    minWidth: dropOffData.length > 0 ? "auto" : "8vw",
                    width: "auto",
                  }}
                  padding={"normal"}
                >
                  {t(headCell.label)}
                </TableCell>
              ))}
        </TableRow>
      </TableHead>
    );
  }

  useEffect(() => {
    if (createDropOffRoute) {
      getHubList((res) => {
        setHubListSelect(res.results);
      });
    }
  }, [createDropOffRoute]);

  const setToggle = useCallback(
    (value: any) => {
      setSelectedList([]);
      setBatchTableData([]);
      setPage(0);
      setRowsPerPage(5);
      setCompleteToggle(value);
    },
    [completeToggle]
  );

  const EnhancedTableToolbar = React.memo(
    (props: EnhancedTableToolbarProps) => {
      return (
        <Toolbar
          sx={{
            backgroundColor: "white",
            pl: { sm: 2 },
            pr: { xs: 1, sm: 1 },
          }}
        >
          <>
            <Typography
              sx={{ flex: "1 1 100%", whiteSpace: "pre" }}
              style={{ fontWeight: 700, fontSize: "14px", lineHeight: "22px" }}
              variant="h6"
              id="tableTitle"
              component="div"
            >
              {value === t("tab.dropoff_route_new")
                ? t("title.dropoff_route_new")
                : t("title.dropoff_route_list")}
            </Typography>
            <Select
              optionFilterProp="children"
              disabled={value === t("tab.dropoff_route_new")}
              value={completeToggle}
              style={{
                width: "100px",
                fontSize: "14px",
                lineHeight: "22px",
                borderRadius: 0,
                marginRight: "10px",
                opacity:
                  completeToggle === t("table.filter.dropdown.placeholder")
                    ? 0.6
                    : 1,
              }}
              onChange={setToggle}
            >
              <Option value="all">{t("table.filter.toggle.all")}</Option>
              <Option value="incomplete">
                {t("table.filter.dropdown.incomplete")}
              </Option>
              <Option value="complete">
                {t("table.filter.dropdown.complete")}
              </Option>
            </Select>
            <AutoComplete
              dataSource={dataSource}
              disabled={value === t("tab.dropoff_route_new")}
              style={{
                marginRight: "10px",
                fontSize: "14px",
                lineHeight: "22px",
                opacity: value === t("tab.dropoff_route_new") ? 0.4 : 1,
              }}
              defaultValue={inputAlias}
              placeholder={t("table.search.input.placeholder")}
            >
              <Input.Search
                enterButton
                onSearch={(v) => {
                  setSelectedList([]);
                  setBatchTableData([]);
                  setPage(0);
                  setRowsPerPage(5);
                  setInputAlias(v);
                }}
                size="small"
              />
            </AutoComplete>
            <Check
              checked={dateSort === "today"}
              onChange={() => {
                setSelectedList([]);
                setBatchTableData([]);
                setPage(0);
                setRowsPerPage(5);
                setDateSort("today");
              }}
            >
              {t("table.filter.toggle.today")}
            </Check>
            <Check
              checked={dateSort === "any"}
              onChange={() => {
                setSelectedList([]);
                setBatchTableData([]);
                setPage(0);
                setRowsPerPage(5);
                setDateSort("any");
              }}
            >
              {t("label.checkbox.all")}
            </Check>
            <RangePicker
              onChange={(range: any) => {
                setSelectedList([]);
                setBatchTableData([]);
                setPage(0);
                setRowsPerPage(5);
                setRangeDate(range);
              }}
              value={rangeDate ? rangeDate : ""}
              style={{
                marginRight: "10px",
                fontSize: "14px",
                lineHeight: "22px",
              }}
            />
            {value === t("tab.dropoff_route_new") && (
              <div style={{ marginRight: "10px!important" }}>
                <Button
                  onClick={() => setCreateDropOffRoute(true)}
                  variant="contained"
                  className={"toolbar-btn"}
                >
                  {t("button.create_route")}
                </Button>
              </div>
            )}
            {value !== t("tab.dropoff_route_new") && (
              <img
                alt={"toggle"}
                onClick={() => setHideFirstTable(!hideFirstTable)}
                src={hideFirstTable ? toggleDown : toggleUp}
                style={{ width: "24px", height: "24px", cursor: "pointer" }}
              />
            )}
          </>
        </Toolbar>
      );
    }
  );

  const EnhancedBatchToolbar = React.memo(
    (props: EnhancedTableToolbarProps) => {
      return (
        <Toolbar
          sx={{
            backgroundColor: "white",
            pl: { sm: 2 },
            pr: { xs: 1, sm: 1 },
          }}
        >
          <>
            <Typography
              sx={{ flex: "1 1 100%", whiteSpace: "pre" }}
              style={{ fontWeight: 700, fontSize: "14px", lineHeight: "22px" }}
              variant="h6"
              id="tableTitle"
              component="div"
            >
              {t("table.header.hub")}
            </Typography>
            {value !== t("tab.dropoff_route_new") && (
              <img
                alt={"toggle"}
                onClick={() => setHideSecondTable(!hideSecondTable)}
                src={hideSecondTable ? toggleDown : toggleUp}
                style={{ width: "24px", height: "24px", cursor: "pointer" }}
              />
            )}
          </>
        </Toolbar>
      );
    }
  );

  useEffect(() => {
    const d = new Date();
    const year = d.getFullYear();
    const month = d.getMonth() + 1;
    const days = d.getDate();
    const date_selected = year + "-" + month + "-" + days;
    let pageVal: number = 1;
    pageVal = page + 1;

    let defaultParams: any = {
      ...defaultDropoffParams,
      page_size: rowsPerPage,
      page: pageVal,
    };

    if (value !== t("tab.dropoff_route_new")) {
      defaultParams = {
        ...defaultDropoffBatchParams,
        page_size: rowsPerPage,
        page: pageVal,
      };
    }
    if (rangeDate !== null) {
      const valueOfInput1 = rangeDate[0].format();
      const valueOfInput2 = rangeDate[1].format();
      const d = new Date(valueOfInput1);
      const dx = new Date(valueOfInput2);
      const year = d.getFullYear();
      const month = d.getMonth() + 1;
      const days = d.getDate();
      const date_selected = year + "-" + month + "-" + days;
      const year_end = dx.getFullYear();
      const month_end = dx.getMonth() + 1;
      const days_end = dx.getDate();
      const date_selected_end = year_end + "-" + month_end + "-" + days_end;
      defaultParams = {
        ...defaultParams,
        date_start: date_selected,
        date_end: date_selected_end,
      };
    } else {
      if (dateSort === "today") {
        defaultParams = {
          ...defaultParams,
          date_start: date_selected,
          date_end: date_selected,
        };
      }
    }
    if (inputAlias.length > 0) {
      defaultParams = { ...defaultParams, alias: inputAlias };
    }
    if (completeToggle === "incomplete") {
      defaultParams = { ...defaultParams, completed: false };
    }
    if (completeToggle === "complete") {
      defaultParams = { ...defaultParams, completed: true };
    }
    getDropoffRoutes(defaultParams, (res) => {
      setPageCount(res.count);
      setDropOffData(res.results);
    });
  }, [
    completeToggle,
    dateSort,
    value,
    rangeDate,
    reload,
    inputAlias,
    rowsPerPage,
    page,
  ]);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setPage(0);
    setRowsPerPage(5);
    setValue(newValue);
    setSelected([]);
    setSelectedList([]);
    setSelectedBatch([]);
  };

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof DataNew
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = rowsNew.map((n: any) => n.dropOff_route_alias);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };
  const prevCountRef = useRef<any>();

  const getMapCoordinates = (b: boolean) => {
    let dt: any[] = [];
    for (let i = 0; i < selected.length; i++) {
      for (let j = 0; j < dropOffData.length; j++) {
        if (dropOffData[j].alias === selected[i]) {
          dt.push(dropOffData[j]);
        }
      }
    }
    setMapCoordinates(dt);
  };

  useEffect(() => {
    if (selected.length > 0 && prevCountRef.current < selected.length) {
      if (selected.length !== rowsNew.length) {
        getMapCoordinates(true);
      }
    } else {
      getMapCoordinates(false);
    }
    prevCountRef.current = selected.length;
  }, [rowsNew.length, selected]);

  const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: readonly string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const showBatchTable = (event: React.MouseEvent<unknown>, name: string) => {
    const selectedIndex = selectedList.indexOf(name);
    let newSelected: readonly string[] = [];

    if (selectedIndex === -1) {
      if (selectedList.length === 0) {
        newSelected = newSelected.concat(selectedList, name);
      } else {
        newSelected = newSelected.concat(name);
      }
    } else {
      newSelected = newSelected.concat(selectedList.slice(1));
    }
    setBatchTableData([]);
    setRowsBatch([]);
    setSelectedList(newSelected);
  };

  useEffect(() => {
    if (batchEditPopup) {
      getRouteDropOffBatches({ completed: false, page_size: 50 }, (res) => {
        setBatchEditData(res.results);
      });
    }
  }, [selectedList, batchEditPopup]);

  const getVal = (ld: any) => {
    return (
      <div className={"flex-row-center"}>
        <img alt={"drag_icon"} src={drag_icon} className={"drag-icon-img"} />
        {ld.is_return && (
          <div className={"tree-row-data-status"}>
            {t("seller_address_return.text")}
          </div>
        )}
        <div style={{ flexDirection: "column" }} className={"tree-row-data"}>
          <div className={"tree-row-data-text"}>
            {t("label.unitname")}:{" "}
            <span style={{ fontWeight: "bold" }}>{ld.unit_storage.name}</span>
          </div>
          <div className={"tree-row-data-text-small"}>
            {t("label.address")}:{" "}
            {ld.unit_storage.address.address1 +
              " " +
              ld.unit_storage.address.address2}
          </div>
          <div className={"tree-row-data-text-small"}>
            {t("label.box_drop")}: {ld.container_count}
          </div>
          <div className={"tree-row-data-text-small"}>
            {t("label.box_collect")}: {ld.return_container_count}
          </div>
          <div className={"tree-row-data-text-small"}>
            {t("label.items_collect")}: {ld.return_item_count}
          </div>
          <div className={"tree-row-data-text-small"}>
            {t("label.route")}: {ld.dropoff_route_alias}
          </div>
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (batchEditData.length > 0 && batchEditPopup) {
      let tns:any[] = [];
      for (let i = 0; i < batchEditData.length; i++) {
        const ld: any = batchEditData[i];
        const key: any = ld.uuid;
        if (ld.unit_storage) {
          tns.push({
            title: getVal(ld),
            key,
          });
        }
      }
      setGData(tns);
    }
  }, [batchEditData, batchEditPopup]);

  const clickBatchTable = (event: React.MouseEvent<unknown>, name: string) => {
    const selectedIndex = selectedBatch.indexOf(name);
    let newSelected: readonly string[] = [];

    if (selectedIndex === -1) {
      if (selectedBatch.length === 0) {
        newSelected = newSelected.concat(selectedBatch, name);
      } else {
        newSelected = newSelected.concat(name);
      }
    } else {
      newSelected = newSelected.concat(selectedBatch.slice(1));
    }
    setSelectedBatch(newSelected);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name: string) => selected.indexOf(name) !== -1;
  const isSelectedList = (name: string) => selectedList.indexOf(name) !== -1;
  const isSelectedBatch = (name: string) => selectedBatch.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.

  const truncateString = (string: string, maxLength: number) =>
    string.length > maxLength ? `${string.substring(0, maxLength)}…` : string;

  // The current position of mouse
  let y = 0;

  // The dimension of the element
  let h = 0;
  let h_me = 0;

  // Handle the mousedown event
  // that's triggered when user drags the resizer
  const mouseDownHandler = function (e: any) {
    if (document.getElementById("resizeMe")) {
      if (
        document.getElementById("resizeMe") &&
        document.getElementById("resizeable-box")
      ) {
        const ele: any = document.getElementById("resizeable-box");
        const ele_me: any = document.getElementById("resizeMe");

        // Get the current mouse position
        y = e.clientY;
        // Calculate the dimension of element
        const styles = window.getComputedStyle(ele);
        h = parseInt(styles.height, 10);

        const styles_me = window.getComputedStyle(ele_me);
        h_me = parseInt(styles_me.height, 10);
        // Attach the listeners to `document`
        document.addEventListener("mousemove", mouseMoveHandler);
        document.addEventListener("mouseup", mouseUpHandler);
      }
    }
  };

  const mouseMoveHandler = function (e: any) {
    const ele: any = document.getElementById("resizeable-box");
    const ele_me: any = document.getElementById("resizeMe");
    const enh: any = document.getElementById("enhanced-table-head");
    const cHeight = enh.getBoundingClientRect().height;

    if (document.getElementById("resizeable-box")) {
      // How far the mouse has been moved
      const dy = e.clientY - y;

      // Adjust the dimension of element
      ele.style.height = `${h + dy}px`;
      if (h_me + dy > cHeight) {
        ele_me.style.height = `${h_me + dy}px`;
      }
    }
  };

  const mouseUpHandler = function () {
    // Remove the handlers of `mousemove` and `mouseup`
    document.removeEventListener("mousemove", mouseMoveHandler);
    document.removeEventListener("mouseup", mouseUpHandler);
  };

  const { Option } = Select;

  const createNewDropOffRoute = () => {
    const params = {
      main_hub_id: selectedHub,
      price: Number(routePrice.replace(",", "")),
    };
    createRouteDropOff(
      params,
      (res: any) => {
        showMessage(t("toast.dropoff.create.route"));
        setReload(!reload);
      },
      showApiError
    );
    setCreateDropOffRoute(false);
  };

  const completeDropOffRoute = (alias: string) => {
    completeRouteDropOff(
      alias,
      (res: any) => {
        showMessage(t("toast.dropoff.complete.route"));
      },
      showApiError
    );
    setReload(!reload);
  };

  const completeBatchesDropOff = (uuid: string) => {
    completeDropOffBatches(
      uuid,
      (res: any) => {
        showMessage(t("toast.dropoff.complete.batch"));
      },
      showApiError
    );
    setReloadBatch(!reloadBatch);
  };

  const dropOffAddAlias = (alias: string) => {
    let orderVal: any[] = [];
    if (selectedTreeData.length > 0) {
      for (let i = 0; i < gData.length; i++) {
        if (selectedTreeData.includes(gData[i].key)) {
          orderVal.push(gData[i].key);
        }
      }
      let params:any[] = [];
      for (let j = 0; j < orderVal.length; j++) {
        params.push({
          uuid: orderVal[j],
          order: j + 1,
        });
      }
      addDropOffAlias(
        alias,
        params,
        (res: any) => {
          showMessage(t("toast.dropoff.add.alias"));
        },
        showApiError
      );
    }
    setBatchEditPopup(false);
    setBatchEditData([]);
    setReload(!reload);
  };

  const [gData, setGData] = useState(defaultData);
  const [expandedKeys] = useState(["0-0", "0-0-0", "0-0-0-0"]);

  const onDragEnter = (info: any) => {
    console.log(info); // expandedKeys 需要受控时设置
    // setExpandedKeys(info.expandedKeys)
  };

  const onDrop = (info: any) => {
    const dropKey = info.node.key;
    const dragKey = info.dragNode.key;
    const dropPos = info.node.pos.split("-");
    const dropPosition =
      info.dropPosition - Number(dropPos[dropPos.length - 1]);

    const loop = (data: any, key: any, callback: any) => {
      for (let i = 0; i < data.length; i++) {
        if (data[i].key === key) {
          return callback(data[i], i, data);
        }
        if (data[i].children) {
          loop(data[i].children, key, callback);
        }
      }
    };

    const data = [...gData]; // Find dragObject

    let dragObj: any;
    loop(data, dragKey, (item: any, index: any, arr: any) => {
      arr.splice(index, 1);
      dragObj = item;
    });

    if (!info.dropToGap) {
      // Drop on the content
      loop(data, dropKey, (item: any) => {
        item.children = item.children || []; // where to insert

        item.children.unshift(dragObj as any);
      });
    } else if (
      (info.node.props.children || []).length > 0 && // Has children
      info.node.props.expanded && // Is expanded
      dropPosition === 1 // On the bottom gap
    ) {
      loop(data, dropKey, (item: any) => {
        item.children = item.children || []; // where to insert

        item.children.unshift(dragObj as any); // in previous version, we use item.children.push(dragObj) to insert the
        // item to the tail of the children
      });
    } else {
      let ar: never[] = [];
      let i;
      loop(data, dropKey, (_item: any, index: any, arr: never[]) => {
        ar = arr;
        i = index;
      });

      if (dropPosition === -1) {
        // @ts-ignore
        ar.splice(i, 0, dragObj);
      } else {
        // @ts-ignore
        ar.splice(i + 1, 0, dragObj);
      }
    }

    setGData(data);
  };

  useEffect(() => {
    if (batchEditData.length > 0 && batchEditPopup) {
      if (selectAllTree) {
        let tns: any[] = [];
        for (let i = 0; i < batchEditData.length; i++) {
          const ld: any = batchEditData[i];
          const key: any = ld.uuid;
          if (ld.unit_storage) {
            tns.push(key);
          }
        }
        setSelectedTreeData(tns);
      }
    }
  }, [batchEditData, batchEditPopup, selectAllTree]);

  const prevCheckedTreeDataRef = useRef(0);

  useEffect(() => {
    if (prevCheckedTreeDataRef.current) {
      if (
        prevCheckedTreeDataRef.current > selectedTreeData.length &&
        selectAllTree
      ) {
        setSelectAllTree(false);
      }
    }
    prevCheckedTreeDataRef.current = selectedTreeData.length;
  }, [selectedTreeData, selectAllTree]);

  useEffect(() => {
    const em = document.getElementById("resizeMe");
    const gy = document.getElementById("resizeable-box");
    if (em && gy && dropOffData.length > 0 && batchTableData.length === 0) {
      setTimeout(() => {
        const cHeight = em.getBoundingClientRect().height;
        const cHeight1 = gy.getBoundingClientRect().height;
        if (cHeight1 === 0 && cHeight < 280 && batchTableData.length === 0) {
          const resizeHeight = Math.round(280 - cHeight);
          gy.style.height = `${resizeHeight}px`;
        }
        if (cHeight1 > 0 && cHeight > 280 && batchTableData.length === 0) {
          gy.style.height = `${0}px`;
        }
      }, 1000);
    }
    if (em && gy && dropOffData.length > 0 && batchTableData.length > 0) {
      setTimeout(() => {
        const cHeight = em.getBoundingClientRect().height;
        const cHeight1 = gy.getBoundingClientRect().height;
        if (
          dropOffData.length > 0 &&
          batchTableData.length > 0 &&
          cHeight > 280 &&
          cHeight1 > 0
        ) {
          em.style.height = `${cHeight - cHeight1}px`;
          gy.style.height = `${0}px`;
        }
      }, 1000);
    }
  }, [batchTableData, dropOffData]);

  const sendPush = () => {
    completeDropoffRouteRequestMiddlemile(
      pushAlias,
      pushMobile,
      (res) => {
        console.log("res success for middlemile");
        setReload(true);
      },
      (err) => {
        showApiError(err);
      }
    );
    setPushMobile("");
    setPushAlias("");
    setShowPushConfirm(false);
  };

  return (
    <>
      <StyledEngineProvider injectFirst>
        <PushConfirm
          value={pushMobile}
          status={showPushConfirm}
          onPush={sendPush}
          onCancel={() => {
            setPushMobile("");
            setShowPushConfirm(false);
          }}
          changeStatus={() => setShowPushConfirm(false)}
        />

        <PushPopup
          changeMobile={(v: string) => setPushMobile(v)}
          value={pushMobile}
          status={showPushPopup}
          changeStatus={() => {
            setPushMobile("");
            setShowPushPopup(false);
          }}
          confirm={() => {
            if (pushMobile.length > 0) {
              setShowPushConfirm(true);
              setShowPushPopup(false);
            }
          }}
        />

        {createDropOffRoute && (
          <div className={"pickup-route-create"}>
            <div className={"pickup-route-create-header"}>
              <div className={"popup-header-text"}>
                {t("title.create_dropoff_route")}
              </div>
              <Button
                disabled={selectedHub.length === 0 || Number(routePrice) < 0}
                onClick={createNewDropOffRoute}
                className={
                  selectedHub.length === 0 || Number(routePrice) < 0
                    ? "create-route-disabled"
                    : "create-route"
                }
                variant="contained"
              >
                {t("button.create_route")}
              </Button>
              <Button
                variant="outlined"
                className={"create-route-cancel"}
                onClick={() => setCreateDropOffRoute(false)}
              >
                {t("button.cancel")}
              </Button>
            </div>
            <div className={"popup-body-div"}>
              <div>
                <span className={"span-star"}>*</span>
                {t("table.header.price")}
              </div>
              <div className={"route-input-div"}>
                <input
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  onChange={(e) => {
                    let cc = parseFloat(
                      e.currentTarget.value.replace(/,/g, "")
                    );
                    setRoutePrice(parseInt(String(cc)).toLocaleString());
                  }}
                  value={routePrice}
                  style={{ border: "none", width: "65px", outline: "none" }}
                />
                <div style={{ marginLeft: "5px" }}>{t("label.won")}</div>
              </div>
              <div style={{ marginTop: "20px", marginBottom: "5px" }}>
                <span style={{ color: "red", marginRight: "4px" }}>*</span>
                {t("table.header.hub")}
              </div>
              <div
                style={{
                  width: "100px",
                  borderRadius: 0,
                  marginRight: "10px",
                  zIndex: 999999,
                }}
              >
                <Select
                  placeholder={t("table.filter.dropdown.placeholder")}
                  optionFilterProp="children"
                  value={selectedHub}
                  style={{
                    width: "100px",
                    opacity:
                      selectedHub === t("table.filter.dropdown.placeholder")
                        ? 0.6
                        : 1,
                  }}
                  onChange={(v: string) => setSelectedHub(v)}
                >
                  {hubListSelect.map((item: any, index) => {
                    return <Option value={item.id}>{item.name}</Option>;
                  })}
                </Select>
              </div>
            </div>
          </div>
        )}

        {batchEditPopup && (
          <div className={"pickup-route-create"}>
            <div className={"pickup-route-create-header"}>
              <div className={"batch-edit-popup-text"}>
                {t("title.edit_dropoff_batch_list")}
              </div>
              <Button
                onClick={() => dropOffAddAlias(selectedAlias)}
                className={"create-route"}
                variant="contained"
              >
                {t("title.edit_dropoff_batch_list")}
              </Button>
              <Button
                className={"create-route-cancel"}
                variant="outlined"
                onClick={() => setBatchEditPopup(false)}
              >
                {t("button.cancel")}
              </Button>
            </div>
            <div className={"tree-div"}>
              <div className={"seller-text"}>
                {t("title.drop_location_unit")}
              </div>
              <Check
                checked={selectAllTree}
                className={"checkbox-label"}
                onChange={(e) => {
                  if (!e.target.checked) {
                    setSelectedTreeData([]);
                  }
                  setSelectAllTree(e.target.checked);
                }}
              >
                {t("label.checkbox.all")}
              </Check>

              <div className={"tree"}>
                <div style={{ width: "462px" }}>
                  <Tree
                    checkable
                    className="draggable-tree"
                    defaultExpandedKeys={expandedKeys}
                    draggable
                    showIcon={true}
                    checkedKeys={selectedTreeData}
                    onCheck={(dt) => setSelectedTreeData(dt)}
                    blockNode
                    onDragEnter={onDragEnter}
                    onDrop={onDrop}
                    treeData={gData}
                  />
                </div>
              </div>
            </div>
          </div>
        )}

        <Box
          className={"tab-box-div"}
          sx={{
            height: showMap ? "68px" : "48px",
            backgroundColor: "red",
            position: "relative",
            paddingBottom: showMap ? "10px" : 0,
          }}
        >
          <Box sx={{ backgroundColor: "white" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="wrapped label tabs example"
            >
              <Tab
                value={t("tab.dropoff_route_new")}
                label={
                  dropOffData
                    ? value === t("tab.dropoff_route_new")
                      ? t("tab.dropoff_route_new") +
                        " (" +
                        dropOffData.length.toString() +
                        ")"
                      : t("tab.dropoff_route_new")
                    : t("tab.dropoff_route_new")
                }
              />
              <Tab
                value={t("tab.dropoff_route_list")}
                label={
                  dropOffData
                    ? value === t("tab.dropoff_route_list")
                      ? t("tab.dropoff_route_list") +
                        " (" +
                        dropOffData.length.toString() +
                        ")"
                      : t("tab.dropoff_route_list")
                    : t("tab.dropoff_route_list")
                }
              />
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginRight: "10px",
                  width: "100%",
                  justifyContent: "end",
                  height: "48px",
                }}
              >
                <img
                  alt={"toggle"}
                  onClick={() =>
                    showMap ? setShowMap(false) : setShowMap(true)
                  }
                  src={showMap ? toggleDown : toggleUp}
                  style={{ width: "24px", height: "24px", cursor: "pointer" }}
                />
              </div>
            </Tabs>
          </Box>
        </Box>
      </StyledEngineProvider>
      <>
        {showMap ? (
          <div className={"map-div"} />
        ) : (
          <div style={{ display: "flex" }}>
            <div className={"main-map"} />
            <div className={"map-disable-text"}>{t("map.disable.text")}</div>
            <div
              className="resizable"
              style={{ width: "-webkit-fill-available" }}
            >
              <Box id={"mainBox"} className={"main-box"}>
                <Paper
                  sx={{ width: "100%", overflow: "hidden", resize: "vertical" }}
                >
                  <Box className={"box-div"}>
                    <EnhancedTableToolbar
                      numSelected={selected ? selected.length : 0}
                    />
                  </Box>
                  {!hideFirstTable && (
                    <>
                      {dropOffData ? (
                        dropOffData.length > 0 ? (
                          <Box className={"box-div"}>
                            <TableContainer
                              sx={{
                                height:
                                  batchTableData.length > 0 &&
                                  value !== t("tab.dropoff_route_new")
                                    ? "29vh"
                                    : "auto",
                                maxHeight:
                                  batchTableData.length > 0 &&
                                  value !== t("tab.dropoff_route_new")
                                    ? "29vh"
                                    : "70vh",
                                overflowY:
                                  batchTableData.length > 0 &&
                                  value !== t("tab.dropoff_route_new")
                                    ? "scroll"
                                    : "auto",
                                backgroundColor: "white",
                                "&::-webkit-scrollbar": {
                                  width: 18,
                                },
                                "&::-webkit-scrollbar-track": {
                                  backgroundColor: "#f9f8f9",
                                },
                                "&::-webkit-scrollbar-thumb": {
                                  backgroundColor: "#646463",
                                },
                              }}
                              className={"table-container"}
                            >
                              <div id="resizeMe">
                                <Table
                                  stickyHeader
                                  aria-label="sticky table"
                                  sx={{
                                    minWidth: 450,
                                    zIndex: 333,
                                    position: "relative",
                                  }}
                                  aria-labelledby="tableTitle"
                                  size={"small"}
                                >
                                  <EnhancedTableHead
                                    numSelected={
                                      value === t("tab.dropoff_route_new")
                                        ? selected.length
                                        : selectedList.length
                                    }
                                    order={order}
                                    orderBy={orderBy}
                                    onSelectAllClick={handleSelectAllClick}
                                    onRequestSort={handleRequestSort}
                                    rowCount={rowsNew.length}
                                  />
                                  <TableBody>
                                    {/* if you don't need to support IE11, you can replace the `stableSort` call with:
              rows.slice().sort(getComparator(order, orderBy)) */}
                                    {value === t("tab.dropoff_route_new")
                                      ? rowsNew.map(
                                          (
                                            row: DropOffDataNew,
                                            index: number
                                          ) => {
                                            const isItemSelected = isSelected(
                                              row.dropOff_route_alias as string
                                            );
                                            const labelId = `enhanced-table-checkbox-${index}`;
                                            return (
                                              <TableRow
                                                hover
                                                role="checkbox"
                                                aria-checked={isItemSelected}
                                                tabIndex={-1}
                                                key={row.dropOff_route_alias}
                                                selected={isItemSelected}
                                              >
                                                <TableCell padding="checkbox">
                                                  <Checkbox
                                                    color="primary"
                                                    onClick={(event) => {
                                                      handleClick(
                                                        event,
                                                        row.dropOff_route_alias as string
                                                      );
                                                    }}
                                                    checked={isItemSelected}
                                                    inputProps={{
                                                      "aria-labelledby": labelId,
                                                    }}
                                                  />
                                                </TableCell>
                                                <TableCell
                                                  onClick={(event) => {
                                                    handleClick(
                                                      event,
                                                      row.dropOff_route_alias as string
                                                    );
                                                  }}
                                                  sx={{ whiteSpace: "pre" }}
                                                  component="th"
                                                  align={"left"}
                                                  id={labelId}
                                                  scope="row"
                                                  padding="normal"
                                                >
                                                  {row.hub}
                                                </TableCell>
                                                <TableCell
                                                  onClick={(event) => {
                                                    handleClick(
                                                      event,
                                                      row.dropOff_route_alias as string
                                                    );
                                                  }}
                                                  sx={{ whiteSpace: "nowrap" }}
                                                  align="left"
                                                >
                                                  {truncateString(
                                                    row.address as string,
                                                    20
                                                  )}
                                                </TableCell>
                                                <TableCell
                                                  onClick={(event) => {
                                                    handleClick(
                                                      event,
                                                      row.dropOff_route_alias as string
                                                    );
                                                  }}
                                                  align="left"
                                                >
                                                  <div
                                                    className={
                                                      "route-alias-text-div"
                                                    }
                                                  >
                                                    <div
                                                      className={
                                                        "route-alias-text"
                                                      }
                                                    >
                                                      {row.dropOff_route_alias}
                                                    </div>
                                                  </div>
                                                </TableCell>
                                                <TableCell
                                                  onClick={(event) => {
                                                    handleClick(
                                                      event,
                                                      row.dropOff_route_alias as string
                                                    );
                                                  }}
                                                  sx={{ whiteSpace: "nowrap" }}
                                                  align="left"
                                                >
                                                  {parseInt(
                                                    String(row.price)
                                                  ).toLocaleString()}
                                                </TableCell>
                                                <TableCell
                                                  onClick={(event) => {
                                                    handleClick(
                                                      event,
                                                      row.dropOff_route_alias as string
                                                    );
                                                  }}
                                                  align="left"
                                                >
                                                  {row.hub_personnel_mobile_tel}
                                                </TableCell>

                                                <TableCell
                                                  onClick={(event) => {
                                                    handleClick(
                                                      event,
                                                      row.dropOff_route_alias as string
                                                    );
                                                  }}
                                                  sx={{ whiteSpace: "pre" }}
                                                  align="left"
                                                >
                                                  {row.timestamp_created}
                                                </TableCell>
                                                <TableCell
                                                  sx={{
                                                    width: "20px",
                                                    height: "20px",
                                                  }}
                                                  align="left"
                                                >
                                                  {row.add_dropOffBatch}
                                                </TableCell>
                                              </TableRow>
                                            );
                                          }
                                        )
                                      : rowsNew.map(
                                          (
                                            row: DropOffDataList,
                                            index: number
                                          ) => {
                                            const isItemSelected = isSelectedList(
                                              row.dropOff_route_alias as string
                                            );
                                            const labelId = `enhanced-table-checkbox-${index}`;
                                            return (
                                              <TableRow
                                                hover
                                                role="checkbox"
                                                aria-checked={isItemSelected}
                                                tabIndex={-1}
                                                key={row.dropOff_route_alias}
                                                selected={isItemSelected}
                                              >
                                                <TableCell
                                                  onClick={(event) => {
                                                    showBatchTable(
                                                      event,
                                                      row.dropOff_route_alias as string
                                                    );
                                                  }}
                                                  padding="checkbox"
                                                >
                                                  <Checkbox
                                                    color="primary"
                                                    checked={isItemSelected}
                                                    inputProps={{
                                                      "aria-labelledby": labelId,
                                                    }}
                                                  />
                                                </TableCell>
                                                <TableCell
                                                  onClick={(event) => {
                                                    showBatchTable(
                                                      event,
                                                      row.dropOff_route_alias as string
                                                    );
                                                  }}
                                                  component="th"
                                                  sx={{ whiteSpace: "pre" }}
                                                  align={"left"}
                                                  id={labelId}
                                                  scope="row"
                                                  padding="normal"
                                                >
                                                  {row.hub}
                                                </TableCell>
                                                <TableCell
                                                  onClick={(event) => {
                                                    showBatchTable(
                                                      event,
                                                      row.dropOff_route_alias as string
                                                    );
                                                  }}
                                                  sx={{ whiteSpace: "nowrap" }}
                                                  align="left"
                                                >
                                                  {truncateString(
                                                    row.address as string,
                                                    20
                                                  )}
                                                </TableCell>
                                                <TableCell
                                                  onClick={(event) => {
                                                    showBatchTable(
                                                      event,
                                                      row.dropOff_route_alias as string
                                                    );
                                                  }}
                                                  align="left"
                                                >
                                                  <div
                                                    className={
                                                      "route-alias-text-div"
                                                    }
                                                  >
                                                    <div
                                                      className={
                                                        "route-alias-text"
                                                      }
                                                    >
                                                      {row.dropOff_route_alias}
                                                    </div>
                                                  </div>
                                                </TableCell>
                                                <TableCell
                                                  onClick={(event) => {
                                                    showBatchTable(
                                                      event,
                                                      row.dropOff_route_alias as string
                                                    );
                                                  }}
                                                  align="left"
                                                >
                                                  {row.batches_count}
                                                </TableCell>
                                                <TableCell
                                                  onClick={(event) => {
                                                    showBatchTable(
                                                      event,
                                                      row.dropOff_route_alias as string
                                                    );
                                                  }}
                                                  align="left"
                                                >
                                                  {parseInt(
                                                    String(row.price)
                                                  ).toLocaleString()}
                                                </TableCell>
                                                <TableCell
                                                  onClick={(event) => {
                                                    showBatchTable(
                                                      event,
                                                      row.dropOff_route_alias as string
                                                    );
                                                  }}
                                                  align="left"
                                                >
                                                  {row.hub_personnel_mobile_tel}
                                                </TableCell>
                                                <TableCell
                                                  onClick={(event) => {
                                                    showBatchTable(
                                                      event,
                                                      row.dropOff_route_alias as string
                                                    );
                                                  }}
                                                  align="left"
                                                >
                                                  {row.status}
                                                </TableCell>
                                                <TableCell
                                                  onClick={(event) => {
                                                    showBatchTable(
                                                      event,
                                                      row.dropOff_route_alias as string
                                                    );
                                                  }}
                                                  sx={{ whiteSpace: "pre" }}
                                                  align="left"
                                                >
                                                  {row.timestamp_created}
                                                </TableCell>
                                                <TableCell
                                                  onClick={(event) => {
                                                    showBatchTable(
                                                      event,
                                                      row.dropOff_route_alias as string
                                                    );
                                                  }}
                                                  sx={{ whiteSpace: "pre" }}
                                                  align="left"
                                                >
                                                  {row.timestamp_complete}
                                                </TableCell>
                                                <TableCell align="left">
                                                  {row.edit_route}
                                                </TableCell>
                                                <TableCell align="left">
                                                  {row.complete_route}
                                                </TableCell>
                                                <TableCell align="left">
                                                  {row.assignment_push}
                                                </TableCell>
                                              </TableRow>
                                            );
                                          }
                                        )}
                                    {emptyRows > 0 && (
                                      <TableRow
                                        style={{
                                          height: 33 * emptyRows,
                                        }}
                                      >
                                        <TableCell colSpan={6} />
                                      </TableRow>
                                    )}
                                    <Box
                                      id={"resizeable-box"}
                                      sx={{ height: "0px" }}
                                    />
                                  </TableBody>
                                </Table>
                              </div>
                            </TableContainer>
                          </Box>
                        ) : (
                          <>
                            <EnhancedTableHead
                              numSelected={selected.length}
                              order={order}
                              orderBy={orderBy}
                              onSelectAllClick={handleSelectAllClick}
                              onRequestSort={handleRequestSort}
                              rowCount={rowsNew.length}
                            />
                            <div className={"empty-div"}>
                              <Empty />
                            </div>
                          </>
                        )
                      ) : null}
                      <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={pageCount}
                        showFirstButton={true}
                        rowsPerPage={rowsPerPage}
                        onMouseDown={mouseDownHandler}
                        style={{
                          backgroundColor: "#F3F3F3",
                          boxShadow: "10px 0px 10px rgba(0, 0, 0, 0.25)",
                        }}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        sx={{
                          cursor: "row-resize",
                          ".MuiTablePagination-selectLabel": {
                            marginTop: "0.7rem !important",
                          },
                          ".MuiTablePagination-displayedRows": {
                            marginTop: "0.7rem !important",
                          },
                          ".MuiPaper-root": {
                            marginBottom: "0 ! important",
                          },
                          ".MuiToolbar-root": {
                            marginRight: "3vw ! important",
                          },
                        }}
                      />
                    </>
                  )}
                </Paper>
                {batchTableData.length > 0 &&
                value !== t("tab.dropoff_route_new") ? null : (
                  <div
                    id={"resizer-bottom"}
                    onMouseDown={mouseDownHandler}
                    className="resizer resizer-b"
                  />
                )}
                {selectedList.length > 0 &&
                  value !== t("tab.dropoff_route_new") && (
                    <Box className={"batch-table-box"}>
                      <EnhancedBatchToolbar
                        numSelected={selectedList ? selectedList.length : 0}
                      />
                      {!hideSecondTable && (
                        <TableContainer
                          sx={{
                            height: "29vh",
                            overflowY: "scroll",
                            backgroundColor: "white",
                            "&::-webkit-scrollbar": {
                              width: 18,
                            },
                            "&::-webkit-scrollbar-track": {
                              backgroundColor: "#f9f8f9",
                            },
                            "&::-webkit-scrollbar-thumb": {
                              backgroundColor: "#646463",
                            },
                          }}
                          className={"table-container"}
                        >
                          <Table
                            stickyHeader
                            aria-label="sticky table"
                            sx={{
                              minWidth: 450,
                              zIndex: 333,
                              position: "relative",
                            }}
                            aria-labelledby="tableTitle"
                            size={"small"}
                          >
                            <TableHeadBatch
                              numSelected={selectedBatch.length}
                              order={order}
                              orderBy={orderBy}
                              onSelectAllClick={handleSelectAllClick}
                              rowCount={rowsBatch.length}
                            />
                            <TableBody>
                              {rowsBatch.map(
                                (row: DropOffBatchData, index: number) => {
                                  const isItemSelected = isSelectedBatch(
                                    row.uuid as string
                                  );
                                  const labelId = `enhanced-table-checkbox-${index}`;
                                  return (
                                    <TableRow
                                      hover
                                      role="checkbox"
                                      aria-checked={isItemSelected}
                                      tabIndex={-1}
                                      key={row.uuid}
                                      selected={isItemSelected}
                                    >
                                      <TableCell
                                        onClick={(event) => {
                                          clickBatchTable(
                                            event,
                                            row.uuid as string
                                          );
                                        }}
                                        padding="checkbox"
                                      >
                                        <Checkbox
                                          color="primary"
                                          checked={isItemSelected}
                                          inputProps={{
                                            "aria-labelledby": labelId,
                                          }}
                                        />
                                      </TableCell>
                                      <TableCell
                                        onClick={(event) => {
                                          clickBatchTable(
                                            event,
                                            row.uuid as string
                                          );
                                        }}
                                        component="th"
                                        align={"left"}
                                        id={labelId}
                                        scope="row"
                                        sx={{ whiteSpace: "pre" }}
                                        padding="normal"
                                      >
                                        {row.unit_name}
                                      </TableCell>
                                      <TableCell
                                        onClick={(event) => {
                                          clickBatchTable(
                                            event,
                                            row.uuid as string
                                          );
                                        }}
                                        sx={{ whiteSpace: "nowrap" }}
                                        align="left"
                                      >
                                        {truncateString(
                                          row.unit_address as string,
                                          20
                                        )}
                                      </TableCell>
                                      <TableCell
                                        onClick={(event) => {
                                          clickBatchTable(
                                            event,
                                            row.uuid as string
                                          );
                                        }}
                                        align="left"
                                      >
                                        <div className={"route-alias-text-div"}>
                                          <div className={"route-alias-text"}>
                                            {row.dropOff_route_alias}
                                          </div>
                                        </div>
                                      </TableCell>
                                      <TableCell
                                        onClick={(event) => {
                                          clickBatchTable(
                                            event,
                                            row.uuid as string
                                          );
                                        }}
                                        align="left"
                                      >
                                        {row.count_drop}
                                      </TableCell>
                                      <TableCell
                                        onClick={(event) => {
                                          clickBatchTable(
                                            event,
                                            row.uuid as string
                                          );
                                        }}
                                        align="left"
                                      >
                                        {row.count_return}
                                      </TableCell>
                                      <TableCell
                                        onClick={(event) => {
                                          clickBatchTable(
                                            event,
                                            row.uuid as string
                                          );
                                        }}
                                        align="left"
                                      >
                                        {row.count_item}
                                      </TableCell>
                                      <TableCell
                                        onClick={(event) => {
                                          clickBatchTable(
                                            event,
                                            row.uuid as string
                                          );
                                        }}
                                        align="left"
                                      >
                                        {row.complete}
                                      </TableCell>
                                      <TableCell
                                        onClick={(event) => {
                                          clickBatchTable(
                                            event,
                                            row.uuid as string
                                          );
                                        }}
                                        sx={{ whiteSpace: "pre" }}
                                        align="left"
                                      >
                                        {row.timestamp_complete}
                                      </TableCell>
                                      <TableCell
                                        onClick={(event) => {
                                          clickBatchTable(
                                            event,
                                            row.uuid as string
                                          );
                                        }}
                                        sx={{ whiteSpace: "pre" }}
                                        align="left"
                                      >
                                        {row.timestamp_created}
                                      </TableCell>

                                      <TableCell align="left">
                                        {row.batch_complete}
                                      </TableCell>
                                    </TableRow>
                                  );
                                }
                              )}
                              {selectedList.length > 0 &&
                                batchTableData.length === 0 && (
                                  <div className={"batch-empty-div"}>
                                    <Empty />
                                  </div>
                                )}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      )}
                    </Box>
                  )}
              </Box>
            </div>
          </div>
        )}
      </>
    </>
  );
};

export default DropOffMapScreen;
