import React, { useEffect, useState } from "react";
import generatePicker from "antd/lib/date-picker/generatePicker";

import dateFnsGenerateConfig from "rc-picker/lib/generate/dateFns";
import styles from "./filter.module.css";
import { subDays, differenceInMinutes, isBefore } from "date-fns";
import locale from "antd/lib/date-picker/locale/en_US";
import { useTranslation } from "react-i18next";
type RangeValue = [Date | null, Date | null] | null;

const DateFnsPicker = generatePicker<Date>(dateFnsGenerateConfig);
interface Props {
  start?: number;
  end?: number;
  onSearch?: (startMinute: number, endMinute: number) => void;
}

const TimeFilter = ({ onSearch, start, end }: Props) => {
  const now = new Date();
  const { t } = useTranslation("shipping");
  const [dates, setDates] = useState<RangeValue>([now, subDays(now, 1)]);

  useEffect(() => {
    if (!start && !end) {
      !!onSearch && onSearch(0, 0);
      setDates([now, now]);
    }
  }, [start, end]);

  const handleChangeDate = (dates: RangeValue) => {
    setDates(dates);
    let start = 0,
      end = 0;
    if (dates && dates[0] && dates[1]) {
      start = differenceInMinutes(now, dates[0]);
      end = differenceInMinutes(now, dates[1]);
    }
    console.log("handleChangeTime", dates, start, end);

    !!onSearch && onSearch(start, end);
  };

  const disabledDate = (current: Date) => {
    if (!dates || !dates[0] || !dates[1]) {
      return false;
    }
    return isBefore(dates[0], current) && isBefore(dates[1], current);
  };

  return (
    <div className={"row align-center " + styles.timeContainer}>
      <DateFnsPicker.RangePicker
        showTime
        showNow={true}
        locale={{
          ...locale,
          lang: {
            ...locale.lang,
            year: "년",
            month: "월",
            dateTimeFormat: "HH:mm",
            shortWeekDays: ["일", "월", "화", "수", "목", "금", "토"],
            shortMonths: [
              "1월",
              "2월",
              "3월",
              "4월",
              "5월",
              "6월",
              "7월",
              "8월",
              "9월",
              "10월",
              "11월",
              "12월",
            ],
            ok: t("popup.btn.ok"),
          },
        }}
        format={"YYYY-MM-dd HH:mm"}
        popupStyle={{ color: "var(--primary)" }}
        className={styles.timer}
        value={dates}
        disabledDate={disabledDate}
        onChange={handleChangeDate}
      />
    </div>
  );
};

export default TimeFilter;
