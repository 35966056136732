export const PlanMarker = (props: any) => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g id="Maps / Markers / Pinlet Marker" clipPath="url(#clip0_90_23863)">
      <path
        id="Union"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.1392 9.72857C12.874 8.74845 14 7.16801 14 5.38525C14 2.41106 10.866 0 7 0C3.13401 0 0 2.41106 0 5.38525C0 7.16441 1.12148 8.74205 2.85027 9.72262C4.14422 10.4722 5.96459 11.6481 6.43521 13.5299C6.50137 13.7945 6.72708 14 6.9998 14V10.7705C6.99985 10.7705 6.9999 10.7705 6.99995 10.7705V14C7.27268 14 7.49838 13.7945 7.56455 13.5299C8.03392 11.6531 9.84591 10.4784 11.1392 9.72857Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_90_23863">
        <rect width="14" height="14" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
