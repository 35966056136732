import React from "react";
import "./login.style.css";
import { Button, Form, Input } from "antd";
import { useTranslation } from "react-i18next";
import LeftTitledTemplate from "../../templates/leftTitled/LeftTitledTemplate";

type Value = {
  username: string;
  password: string;
};

type Props = {
  onSignin: (username: string, password: string) => void;
};
const LoginPhoneScreen = ({ onSignin }: Props) => {
  const { t } = useTranslation(["login"]);

  const handleSignin = (values: Value) => {
    if (onSignin) {
      onSignin(values.username, values.password);
    }
  };
  return (
    <LeftTitledTemplate title={t("login.title")} classname={"login-form"}>
      <Form
        name="basic"
        labelCol={{ span: 20 }}
        wrapperCol={{ span: 30 }}
        initialValues={{ remember: true }}
        data-cy="loginForm"
        onFinish={handleSignin}
      >
        <Form.Item name="username" className={"form-phone"}>
          <Input
            defaultValue={""}
            autoComplete={"off"}
            className={"input-phone"}
            placeholder={t("login.input.phone")}
            data-cy="username"
          />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[
            {
              required: true,
              message: "",
            },
          ]}
          className={"form-code"}
        >
          <Input.Password
            allowClear
            className={"input-code"}
            data-cy="password"
            placeholder={t("login.input.code")}
          />
        </Form.Item>
        <Form.Item className={"form-get-code"}>
          <Button
            type={"primary"}
            className={"btn-b btn-get-code"}
            htmlType="submit"
            data-cy="login"
          >
            {t("login.btn.phone")}
          </Button>
        </Form.Item>
      </Form>
    </LeftTitledTemplate>
  );
};

export default LoginPhoneScreen;
