import React from "react";
import styles from "./plan.module.css";

import { Button, Radio } from "antd";
import { TClusterPlanItem } from "../../../api/apiTypes";

interface Props {
  plan: TClusterPlanItem;
  selected: boolean;
  onSelect: (plan: TClusterPlanItem) => void;
}

const PlanItem = ({ selected, onSelect, plan }: Props) => {
  const handleSelect = (e: any) => {
    if (!selected) onSelect(plan);
  };

  return (
    <Button
      type={"text"}
      className={styles.itemContainer + " row align-center"}
      onClick={handleSelect}
    >
      <div>
        <Radio checked={selected} onChange={handleSelect} />
        <span className={"small bold white85"}>{plan.cluster_id}</span>
      </div>
      <span className={"small black white " + styles.itemType}>
        {plan.shipping_item_count}
      </span>
    </Button>
  );
};

export default PlanItem;
