import React, { ReactNode, useEffect, useRef, useState } from "react";
import "./barcode.style.css";
import { useTranslation } from "react-i18next";
import LeftTitledTemplate from "../../templates/leftTitled/LeftTitledTemplate";
import { Input } from "antd";
import { isDesktopByWidth } from "../../../fns/commonFns";

interface Props {
  resetRef: (func: () => void) => void;
  onScan: (val: { box_id: string }) => void;
  onRefresh: () => void;
  error: string;
  success?: string | ReactNode;
  successAlias?: string;
  successLabel?: string;
  info?: string | ReactNode;
  infoLabel?: string | ReactNode;
}

const ScanView = ({
  resetRef,
  onRefresh,
  onScan,
  error,
  success,
  successAlias,
  successLabel,
  info,
  infoLabel,
}: Props) => {
  const inputRef = useRef<any>();
  const timerRef = useRef<any>();
  const { t } = useTranslation(["scan"]);
  const [value, setValue] = useState("");
  const [showInput, setShowInput] = useState(true);

  useEffect(() => {
    document.addEventListener("keydown", onKeyDown);
    setTimeout(() => {
      resetInput();
      setShowInput(false);
      if (!!inputRef.current) {
        inputRef.current.focus();
      }
    }, 500);
    return () => {
      document.removeEventListener("keydown", onKeyDown);
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, []);

  resetRef(() => resetInput());

  const onKeyDown = () => {
    // @ts-ignore
    onRefresh();
    if (!!inputRef.current) {
      inputRef.current.focus();
    }
  };

  const handleFocus = () => {
    if (!!inputRef.current) {
      inputRef.current.focus();
    }
  };

  const handleChange = (e: any) => {
    setValue(e.target.value);
    onRefresh();
  };

  const resetInput = () => {
    setValue("");
  };

  const infoNode = (
    <div className={"result-container"}>
      <div className={"body1 bold black45 text-center"}>{info}</div>
      {infoLabel && (
        <div className={"small black45 return-scan-label"}>{infoLabel}</div>
      )}
    </div>
  );

  const placeholder = (
    <>
      <span className={"black-25 box-scan-input-placeholder"}>
        {t("input.placeholder")}
      </span>
      {infoNode}
    </>
  );

  const errorNode = (
    <div className={"result-container"}>
      <div className={"black-85 box-scan-error"}>{t("error")}</div>
      <div className={"body1 bold primary text-center"}>{error}</div>
    </div>
  );

  const successNode = (
    <div className={"result-container"}>
      <div className={"black-85 box-scan-result"}>{success}</div>
      {!!successAlias ? (
        <div className={"black-85 box-scan-alias"}>{successAlias}</div>
      ) : (
        <div className={"box-scan-divider"} />
      )}
      <div className={"body1 bold primary text-center"}>{successLabel}</div>
    </div>
  );

  const handleSubmit = (e: any) => {
    if (e.key === "Enter" || e.key === "Tab") {
      // Cancel the default action, if needed
      e.preventDefault();
      onScan({ box_id: value });
    }
  };

  return (
    <LeftTitledTemplate classname={"box-scan-container"} showBreadTitle>
      <div
        style={{
          height: isDesktopByWidth()
            ? window.innerHeight - 215
            : window.innerHeight - 180,
        }}
        className={"box-scan-inner"}
      >
        {!!success || !!error || (
          <Input
            onClick={handleFocus}
            ref={inputRef}
            bordered={false}
            readOnly={showInput}
            data-cy="checkInputForm"
            autoFocus={true}
            onChange={handleChange}
            onKeyPress={handleSubmit}
            value={value}
            className={"box-scan-input black-85"}
            placeholder={""}
            autoComplete={"off"}
          />
        )}
        {!!success
          ? successNode
          : !!error
          ? errorNode
          : !value
          ? placeholder
          : infoNode}
      </div>
    </LeftTitledTemplate>
  );
};

export default ScanView;
