import React, { useState } from "react";
import { Button, Modal, Radio, RadioChangeEvent } from "antd";
import { ContainerType } from "../../../api/apiTypes";
import { useTranslation } from "react-i18next";
import { hubContainerBatchUpdate } from "../../../api/shippingApi";
import "./index.css";
import { showMessage } from "../../../fns/message";

const SelectBatchPopup = (props: any) => {
  const { t } = useTranslation("dispatch");
  const [feeVal, setFeeVal] = useState<any>();
  const [containerClass, setContainerClass] = useState("");

  const updateBatchHub = () => {
    let rb:any[] = [];
    if (
      props.data.length > 0 &&
      (containerClass.toString().length > 0 || feeVal.toString().length > 0)
    ) {
      for (let i = 0; i < props.data.length; i++) {
        rb.push(props.data[i].uuid);
      }
      let datum: any = { container_uuid: rb };
      if (props.type === "type") {
        datum = { ...datum, container_class: containerClass };
      } else {
        datum = { ...datum, box_premium: Number(feeVal) };
      }
      hubContainerBatchUpdate(
        datum,
        (res: any) => {
          props.refresh();
          if (res.status === 200) {
            showMessage(t("res_container_setting_complete"), "success");
          }
        },
        () => {
          showMessage(t("err_container_setting_invalid_type"), "warning");
        }
      );
    }
  };

  const buttonNode = (
    <div className={"row "}>
      <Button
        type={"text"}
        className={"white bold body popup-cancel"}
        onClick={() => {
          setContainerClass("");
          setFeeVal("");
          props.onCancel();
        }}
      >
        {t("popup_button_container_setting_back")}
      </Button>
      <div className={"modal-divider"} />
      <Button
        onClick={updateBatchHub}
        type={"text"}
        className={"bold body popup-ok"}
      >
        {props.type === "type"
          ? t("popup_button_container_setting_save")
          : t("popup_button_container_fee_save")}
      </Button>
    </div>
  );

  const handleChange = (event: { target: { value: string } }) => {
    const result = event.target.value.replace(/\D/g, "");
    setFeeVal(result);
  };

  const onChange = (e: RadioChangeEvent) => {
    setContainerClass(e.target.value);
  };

  return (
    <Modal
      closable={false}
      cancelText={""}
      open={props.visible}
      maskClosable
      centered
      className={props.type === "type" ? "md-modal" : "modal-root"}
      footer={buttonNode}
    >
      {props.type === "type" ? (
        <div className={"white subtitle2 bold"}>
          {t("popup_container_setting_title")}
        </div>
      ) : (
        <div className={"modal-header"}>{t("popup_container_fee_title")}</div>
      )}
      {props.type === "type" && (
        <>
          <div className={"white-bold"}>
            {t("popup_container_setting_type_subtitle")}
          </div>
          <div className={"white-small"}>
            {t("popup_container_setting_description")}
          </div>
        </>
      )}

      {props.type === "type" ? (
        <Radio.Group
          onChange={onChange}
          className={"radioGrp"}
          name="radiogroup"
        >
          <Radio
            style={{ marginTop: "6px" }}
            className={"white bold small"}
            value={ContainerType.BLACK}
          >
            {ContainerType.BLACK}
          </Radio>
          <Radio
            style={{ marginTop: "6px" }}
            className={"white bold small"}
            value={ContainerType.BLUE}
          >
            {ContainerType.BLUE}
          </Radio>
          <Radio
            style={{ marginTop: "6px" }}
            className={"white bold small"}
            value={ContainerType.WHITE}
          >
            {ContainerType.WHITE}
          </Radio>
          <Radio
            style={{ marginTop: "6px" }}
            className={"white bold small"}
            value={ContainerType.OFFROAD}
          >
            {ContainerType.OFFROAD}
          </Radio>
          <Radio
            style={{ marginTop: "6px" }}
            className={"white bold small"}
            value={ContainerType.RAINBOW}
          >
            {ContainerType.RAINBOW}
          </Radio>
          <Radio
            style={{ marginTop: "6px" }}
            className={"white bold small"}
            value={ContainerType.YELLOW}
          >
            {ContainerType.YELLOW}
          </Radio>
        </Radio.Group>
      ) : (
        <input
          type="text"
          value={feeVal}
          onChange={handleChange}
          placeholder={t("popup_container_setting_placeholder")}
          className={"fee-input"}
        />
      )}
    </Modal>
  );
};

export default SelectBatchPopup;
