import React from "react";
import styles from "./riderItem.module.css";

import { Button, Radio } from "antd";
import { useTranslation } from "react-i18next";
import TypeItem from "../typeSelector/TypeItem";
import { BunnyType } from "../../../api/apiTypes";

interface Props {
  selected: boolean;
  is_delivering: boolean;
  onSelect: (uuid: string) => void;
  uuid: string;
  phonenumber: string;
  name: string;
  type: BunnyType;
}

const RiderItem = ({
  selected,
  onSelect,
  uuid,
  name,
  phonenumber,
  type,
  is_delivering,
}: Props) => {
  const { t } = useTranslation("dispatch");
  const handleSelect = (e: any) => {
    if (!selected) onSelect(uuid);
  };

  return (
    <Button
      type={"text"}
      className={styles.container + " row align-center"}
      onClick={handleSelect}
    >
      <Radio checked={selected} onChange={handleSelect} />
      <div className={"flex column " + styles.name}>
        <div className={"body bold white85"}>{name}</div>
        <div className={"small bold white45 " + styles.phone}>
          {phonenumber}
        </div>
        <span className={"row small bold white45 align-center"}>
          <div className={styles.dot + " " + styles.green} />
          {is_delivering ? t("popup.rider.working") : t("popup.rider.active")}
        </span>
      </div>
      <TypeItem type={type} />
    </Button>
  );
};

export default RiderItem;
