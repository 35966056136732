import React, {
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { GeolocationError } from "ol/Geolocation";

import { IClusterPlanLongItem, TShippingGeo } from "../../../api/apiTypes";
import { styleShippingCluster } from "../../../fns/commonFns";
import {
  ClusterLayer,
  Controller,
  OpenLayers,
  PolygonLayer,
  CustomOverlay,
  CustomDraw,
} from "@delivus/react-open-layers";
import {PlansTooltip,
  THoveredShipping,
} from "./PlansTooltip";
import { ObjectWith } from "../../screens/plan/PlanMapScreen";
import { PlanPolygon } from "../../../assets/svgs/planPolygon";
import { PlanTooltip } from "../../../assets/svgs/planTooltip";
import { PlanMarker } from "../../../assets/svgs/planMarker";
import { PlanSector } from "../../../assets/svgs/planSector";
import { Geolocation } from "../../../assets/svgs/geolocation";
import Feature from "ol/Feature";
import VectorSource from "ol/source/Vector";
import { getRandomColorSector } from "../../../fns/objectFns";
import { useTranslation } from "react-i18next";
import { useCoordinates } from "../../../api/useCoordinates";
import {
  HoveredShippingTooltipType,
  vworldBaseLayer,
} from "../../screens/shippingMap/ShippingMapScreen";
import { PlanMapButton } from "./PlanMapButton";
import { PlanDelete } from "../../../assets/svgs/planDelete";
import { useZipcodes } from "../../../api/useZipcodes";
import { PointProps } from "@delivus/react-open-layers/dist/cjs/types/point/point.type";
import { Style, Icon } from "ol/style";
import { SelectEvent } from "ol/interaction/Select";
import { MINT, PRIMARY } from "../../../common/consts.common";
import { getMarkerIcon } from "../../../api/useShippings";
import { PlanRefresh } from "../../../assets/svgs/planRefresh";
import { ZoomSlider } from "../../molecules/zoomSlider/ZoomSlider";
import { showApiError, showMessage } from "../../../fns/message";

const MIN_ZOOM = 10;
const MAX_ZOOM = 21;
const ZOOM = 13;
export interface IShippingPlanMarker extends TShippingGeo {
  cluster_id: string;
  hovered?: boolean;
  highlighted?: boolean;
}

interface Props {
  onSelectShippings: (shippings: IShippingPlanMarker[]) => void;
  onRefresh: () => void;
  onReset: () => void;
}

const PlanMap = forwardRef(
  ({ onSelectShippings, onRefresh, onReset }: Props, ref) => {
    const { t } = useTranslation(["shipping"]);
    const mapRef = useRef<any>();
    const zoomRef = useRef<any>();
    const zoomValue = useRef(ZOOM);
    const coloredSectorsRef = useRef<ObjectWith>({});
    const drawnPolygonsRef = useRef<Feature[]>([]);
    const selectedFeatureRef = useRef<Feature[]>([]);
    const [hoveredShippingTooltip, setHoveredShippingTooltip] = useState<
      HoveredShippingTooltipType
    >();
    const [bound, setBound] = useState<Array<number>>();
    const [isVisibleTooltip, setIsVisibleTooltip] = useState(true);
    const [isVisiblePolygon, setVisiblePolygon] = useState(false);
    const [isVisibleSector, setVisibleSector] = useState(false);
    const [isVisibleZipcode, setVisibleZipcode] = useState(false);
    const [isVisibleMarker, setVisibleMarker] = useState(true);
    const [showGeolocation, setGeolocation] = useState(false);
    const { coordinates } = useCoordinates(isVisibleSector, bound);
    const { zipcodes } = useZipcodes(isVisibleZipcode, bound);
    const [markers, setMarkers] = useState<PointProps[]>([]);
    const drawSource = useRef<VectorSource>();
    const clusterSource = useRef<VectorSource>();

    useEffect(() => {
      drawSource.current = undefined;
      drawnPolygonsRef.current = [];
    }, [isVisiblePolygon]);

    useImperativeHandle(
      ref,
      () => ({
        setPlans: createMarkerFromPlans,
        reset: handleReset,
      }),
      []
    );

    const createMarker = (
      geo: TShippingGeo,
      plan: IClusterPlanLongItem,
      index: number
    ) => {
      let marker: PointProps;
      const cluster_id = plan?.cluster_id || "";
      const color = getRandomColorSector(
        cluster_id,
        coloredSectorsRef.current[cluster_id]
      );
      coloredSectorsRef.current[cluster_id] = color;
      const shipping: IShippingPlanMarker = {
        cluster_id,
        ...geo,
      };
      marker = {
        index,
        properties: { shipping, color },
        iconOptions: getMarkerIcon(color),
        coordinate: [
          geo.shipping_item__address__lng,
          geo.shipping_item__address__lat,
        ],
      };
      return marker;
    };

    const createMarkerFromPlans = useCallback(
      (clusterPlans: IClusterPlanLongItem[]) => {
        let shippings: PointProps[] = [];
        if (clusterPlans) {
          clusterPlans.forEach((cluster) => {
            if (cluster.shippingitems_geo) {
              const shippingMarkers = cluster.shippingitems_geo.map(
                (shipping, index) => createMarker(shipping, cluster, index)
              );
              shippings = shippings.concat(shippingMarkers);
            }
          });
          setMarkers(shippings);
        }
      },
      []
    );

    const handleDrawEnd = useCallback((event: any) => {
      drawnPolygonsRef.current.push(event.feature);
      const extents = event.feature.getGeometry().getExtent();
      if (clusterSource.current) {
        const features = clusterSource.current.getFeaturesInExtent(extents);
        features.forEach((f: Feature) => {
          const properties = f.getProperties();
          if (properties.shipping && !properties.highlighted) {
            addToSelectedShippings(f, properties);
          }
        });
        const shippings = getSelectedShippings();
        onSelectShippings(shippings);
      }
    }, []);

    const handleMapBoundChanged = useCallback((event: any, extent: any) => {
      const view = event.map.getView();
      zoomValue.current = Math.floor(view.getZoom());
      console.log("handleMapBoundChanged", zoomValue.current);
      if (zoomRef.current) {
        zoomRef.current.setValue(zoomValue.current);
      }
      if (zoomValue.current < 13) {
        setVisibleSector(false);
        setVisibleZipcode(false);
        // setVisibleMarker(false);
      } else if (zoomValue.current < 14) {
        setVisibleSector(false);
      }
      setBound(extent);
    }, []);

    const unhighlighFeature = (feature: Feature) => {
      const properties = feature.getProperties();
      feature.setStyle([
        new Style({
          image: new Icon(getMarkerIcon(properties.color)),
        }),
      ]);
    };

    const getSelectedShippings = () => {
      const shippings: IShippingPlanMarker[] = [];
      selectedFeatureRef.current &&
        selectedFeatureRef.current.forEach((f) => {
          const properties = f.getProperties();
          if (properties.shipping) shippings.push(properties.shipping);
        });
      return shippings;
    };
    const highlightFeature = (feature: Feature, color: string) => {
      feature.setStyle([
        new Style({
          image: new Icon(getMarkerIcon(color)),
        }),
      ]);
    };

    const addToSelectedShippings = (feature: Feature, properties: any) => {
      selectedFeatureRef.current.push(feature);
      highlightFeature(feature, "#" + MINT);
      feature.setProperties({ ...properties, highlighted: true });
      console.log(
        "addToSelectedShippings feature",
        selectedFeatureRef.current,
        feature
      );
    };

    const removeFromSelectedShippings = (feature: Feature, properties: any) => {
      unhighlighFeature(feature);
      feature.setProperties({ ...properties, highlighted: false });
      selectedFeatureRef.current = selectedFeatureRef.current.filter(
        (selected) => {
          return selected != feature;
        }
      );
      console.log("filter feature", selectedFeatureRef.current, feature);
    };

    const handleZoom = (value: number) => {
      console.log("handleZoom", value);
      if (mapRef.current) {
        mapRef.current.zoomSmooth(value);
      }
    };

    const handleClickCluster = useCallback((selected: Feature[]) => {
      console.log("handleClickCluster", selected);
      if (selected?.length) {
        let features: Feature[] = selected[0].get("features");
        if (!features?.length) {
          //is not cluster
          features = selected;
        }
        let highlighted = false;
        features &&
          features.forEach((f: Feature) => {
            const properties = f.getProperties();
            if (properties.shipping) {
              if (!highlighted) highlighted = properties.highlighted;
              console.log("handleClick", properties);
              if (!properties.highlighted) {
                addToSelectedShippings(f, properties);
              } else {
                removeFromSelectedShippings(f, properties);
              }
            }
          });
        const shippings = getSelectedShippings();
        onSelectShippings(shippings);
      }
    }, []);

    const handleMouseOverCluster = useCallback(
      (selected: Feature[], deselected: Feature[], event: SelectEvent) => {
        console.log("handleMouseOverCluster1", selected, deselected);
        const shippings: THoveredShipping[] = [];
        let selectedFeatures: Feature[] = [];
        if (selected?.length) {
          selectedFeatures = selected[0].get("features");
          if (!selectedFeatures?.length) {
            //is not cluster
            selectedFeatures = selected;
          }
          console.log("handleMouseOverCluster features", selectedFeatures);
          selectedFeatures &&
            selectedFeatures.forEach((feature) => {
              const properties = feature.getProperties();
              console.log("handleMouseOverCluster properties", properties);
              const shipping: IShippingPlanMarker = properties.shipping;
              if (shipping) {
                highlightFeature(feature, "#" + PRIMARY);
                shippings.push({
                  tracking_number: shipping.cluster_id,
                  status: shipping.shipping_item__status,
                });
              }
            });
          if (shippings.length > 0)
            setHoveredShippingTooltip({
              shippings,
              position: event.mapBrowserEvent.coordinate,
            });
        }
        if (deselected?.length) {
          let deselectedFeatures: Feature[] = deselected[0].get("features");
          if (!deselectedFeatures?.length) {
            //is not cluster
            deselectedFeatures = deselected;
          }
          if (selectedFeatures[0] === deselectedFeatures[0]) return;
          setHoveredShippingTooltip(undefined);
          console.log(
            "handleMouseOverCluster desect features",
            deselectedFeatures
          );
          deselectedFeatures &&
            deselectedFeatures.forEach((feature) => {
              const properties = feature.getProperties();
              console.log(
                "handleMouseOverCluster  desect properties",
                properties
              );
              if (!properties.highlighted) unhighlighFeature(feature);
              else highlightFeature(feature, "#" + MINT);
            });
        }
      },
      []
    );

    const handleZipcodeVisible = () => {
      console.log("handleZipcodeVisible", isVisibleZipcode, zoomValue.current);
      if (!isVisibleZipcode && zoomValue.current < 13) {
        showMessage(
          t("The Zipcode layer can be visible when the zoom level exceeds 13")
        );
        return;
      }
      setVisibleZipcode((prev) => !prev);
    };

    const handleSectorVisible = () => {
      if (!isVisibleSector && zoomValue.current < 14) {
        showMessage(
          t("The Sector layer can be visible when the zoom level exceeds 14")
        );
        return;
      }
      setVisibleSector((prev) => !prev);
    };

    const handleMarkerVisible = () => {
      // if (!isVisibleSector && zoomValue.current < 15) {
      //   showMessage(
      //     t("The Marker layer can be visible when the zoom level exceeds 15")
      //   );
      //   return;
      // }
      setVisibleMarker((prev) => !prev);
    };

    const handleReset = useCallback(() => {
      drawnPolygonsRef.current &&
        drawnPolygonsRef.current.forEach((feature) => {
          drawSource.current && drawSource.current.removeFeature(feature);
        });
      selectedFeatureRef.current &&
        selectedFeatureRef.current.forEach((feature) => {
          const properties = feature.getProperties();
          removeFromSelectedShippings(feature, properties);
        });
      onReset();
    }, []);

    const topControlNode = (
      <>
        <PlanMapButton
          title={t("zipcode layer")}
          img={PlanSector}
          isActive={isVisibleZipcode}
          onClick={handleZipcodeVisible}
        />
        <PlanMapButton
          title={t("sector layer")}
          img={PlanSector}
          isActive={isVisibleSector}
          onClick={handleSectorVisible}
        />
        <PlanMapButton
          title={t("polygon draw")}
          img={PlanPolygon}
          isActive={isVisiblePolygon}
          onClick={() => setVisiblePolygon((prev) => !prev)}
        />
        <PlanMapButton
          title={t("marker layer")}
          img={PlanMarker}
          isActive={isVisibleMarker}
          onClick={handleMarkerVisible}
        />
        <PlanMapButton
          title={t("tooltip")}
          img={PlanTooltip}
          isActive={isVisibleTooltip}
          onClick={() => setIsVisibleTooltip((prev) => !prev)}
        />
        <PlanMapButton
          img={PlanDelete}
          isQuadrat
          isActive={false}
          onClick={handleReset}
        />
        <PlanMapButton
          img={PlanRefresh}
          isQuadrat
          isActive={false}
          onClick={onRefresh}
        />
      </>
    );
    const handleGeoLocationError = (error: GeolocationError) => {
      showApiError(error);
      setGeolocation(false);
    };

    return (
      <OpenLayers
        ref={mapRef}
        layers={[vworldBaseLayer]}
        interactionOptions={{ pinchZoom: true }}
        onMoveEnd={handleMapBoundChanged}
        showZoom
        geolocationOptions={{
          fillColor: "#6e6eff",
          onError: handleGeoLocationError,
        }}
        showGeolocation={showGeolocation}
        viewOptions={{ zoom: ZOOM, maxZoom: MAX_ZOOM, minZoom: MIN_ZOOM }}
      >
        <PolygonLayer
          polygons={isVisibleZipcode ? zipcodes : undefined}
          showCode
        />
        <PolygonLayer polygons={isVisibleSector ? coordinates : undefined} />
        <Controller
          id={"top-control"}
          className={"map-floating-cntr map-left-floating plan-top-float"}
        >
          {topControlNode}
        </Controller>
        <Controller id={"right-control"} className={"plan-map-right-control"}>
          <PlanMapButton
            img={Geolocation}
            isQuadrat
            isActive={showGeolocation}
            onClick={() => setGeolocation((prev) => !prev)}
          />
        </Controller>
        <Controller id={"slider-control"} className={"plan-map-slider-control"}>
          <ZoomSlider
            ref={zoomRef}
            onChange={handleZoom}
            max={MAX_ZOOM}
            min={MIN_ZOOM}
            defaultValue={ZOOM}
          />
        </Controller>
        {isVisiblePolygon && (
          <CustomDraw
            onSourceCreated={(source) => (drawSource.current = source)}
            onDrawEnd={handleDrawEnd}
          />
        )}
        <CustomOverlay
          id={"shipping-tooltip"}
          className={"tooltip"}
          position={hoveredShippingTooltip?.position}
        >
          {isVisibleTooltip ? (
            <PlansTooltip shippings={hoveredShippingTooltip?.shippings} />
          ) : undefined}
        </CustomOverlay>
        <ClusterLayer
          clusterStyle={styleShippingCluster}
          clusterOptions={{ distance: 40 }}
          points={isVisibleMarker ? markers : undefined}
          onOver={handleMouseOverCluster}
          onClick={handleClickCluster}
          onSourceCreated={(source) => (clusterSource.current = source)}
        />
        {/*{isLoading && <PopupSpinner />}*/}
      </OpenLayers>
    );
  }
);

export default memo(PlanMap);
