import useSWR from "swr";
import { fetcher } from "../services/http.service";
import { GetListResponse } from "./apiTypes";
import { objToQueryString } from "../fns/commonFns";
export function useTableData(
  url?: string | null,
  params?: any,
  page?: number,
  pageSize?: number
) {
  const getParamString = (url?: string) => {
    let paramString = objToQueryString(params);
    if (Number(page) > 0) {
      if (paramString) paramString = `${paramString}&page=${page}`;
      else paramString = `page=${page}`;
    }
    if (paramString) paramString = `${paramString}&page_size=${pageSize}`;
    else paramString = `page_size=${pageSize}`;

    console.log("getParamString", paramString);
    if (url && !url.includes("?")) {
      paramString = `?` + paramString;
    } else {
      paramString = `&` + paramString;
    }
    return paramString;
  };
  const { data, isLoading, mutate, error } = useSWR<GetListResponse>(
    url ? `${url}${getParamString(url)}` : null,
    fetcher
  );

  return {
    urlData: data,
    refreshing: isLoading,
    isError: error,
    mutate,
  };
}
