import React, {
  forwardRef,
  ReactNode,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { Button, Modal } from "antd";
import { useTranslation } from "react-i18next";
import styles from "./print.module.css";
import PrintLoading from "./PrintLoading";
import { usePrint } from "./usePrint";

type Props = {
  onPrinted?: (data?: any) => void;
  btnOk?: string;
};
const PrintView = forwardRef(({ onPrinted, btnOk }: Props, ref) => {
  const componentRef = useRef<any>();
  const isDamaged = useRef<any>();
  const { t } = useTranslation(["print"]);
  const [printContent, setPrintContent] = useState<ReactNode>();
  const [visible, setVisible] = useState(false);

  useImperativeHandle(
    ref,
    () => ({
      show,
      setContent: (printContent: ReactNode, data: any) => {
        setContent(printContent);
        isDamaged.current = data;
      },
    }),
    []
  );

  useEffect(() => {
    window.addEventListener("afterprint", handleAfterPrint);
    window.onafterprint = () => {
      console.log("onafterprint useeffecr");
    };
  }, []);

  window.onafterprint = () => {
    console.log("onafterprint body");
  };

  const show = () => {
    setVisible(true);
  };

  const setContent = (content: ReactNode) => {
    setVisible(true);
    setPrintContent(content);
  };

  const hide = () => {
    setVisible(false);
    setPrintContent(undefined);
  };

  const handleAfterPrint = () => {
    if (onPrinted) onPrinted(isDamaged.current);
    hide();
  };

  const handlePrint = usePrint({
    componentRef,
    onAfterPrint: handleAfterPrint,
  });

  const buttonNode = (
    <div className={"row "}>
      <Button
        type={"text"}
        className={"popup-cancel bold body white"}
        onClick={hide}
      >
        {t("btn.cancel")}
      </Button>
      <div className={"modal-divider"} />
      <Button
        type={"text"}
        className={"popup-ok bold body"}
        onClick={handlePrint}
        data-cy="handlePrinted"
      >
        {btnOk || t("btn.print")}
      </Button>
    </div>
  );

  return (
    <Modal
      closable={false}
      open={visible}
      onCancel={hide}
      footer={buttonNode}
      width={986}
      className={styles.container}
    >
      <div className={styles.printContainer}>
        <div ref={componentRef}>
          {!printContent ? <PrintLoading /> : printContent}
        </div>
      </div>
    </Modal>
  );
});

export default PrintView;
