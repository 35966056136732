import React from "react";

export interface DropOffDataNew {
  dropOff_route_alias: string;
  address: string;
  hub: string;
  timestamp_created: string;
  add_dropOffBatch: any;
  price: string;
  hub_personnel_mobile_tel: string;
}

export interface DropOffDataList {
  dropOff_route_alias: string;
  address: string;
  hub: string;
  timestamp_created: string;
  price: string;
  hub_personnel_mobile_tel: string;
  batches_count: number;
  status: string;
  timestamp_complete: string;
  edit_route: any;
  complete_route: any;
  assignment_push: any;
}

export interface DataNew {
  hub: string;
  address: string;
  pickup_route_alias: string;
  price: number;
  hub_personnel: string;
  hub_personnel_mobile_tel: string;
  timestamp_created: string;
  timestamp_complete: string;
  add_batch: any;
}

export interface DataList {
  hub: string;
  address: string;
  pickup_route_alias: string;
  price: number;
  hub_personnel: string;
  hub_personnel_mobile_tel: string;
  status: string;
  timestamp_created: string;
  timestamp_complete: string;
  routeFix: any;
  routeComplete: any;
  dispatch: any;
}

export interface BatchData {
  uuid: string;
  name: string;
  type: string;
  pickup_route_alias: string;
  order: string;
  shipping_address: string;
  return_address: string;
  count_shipping: string;
  count_return: string;
  round: string;
  hub_personnel_mobile_tel: string;
  status: string;
  timestamp_complete: string;
  complete: any;
  delay: any;
}

export interface DropOffBatchData {
  uuid: string;
  unit_name: string;
  unit_address: string;
  dropOff_route_alias: string;
  count_drop: string;
  count_return: string;
  count_item: string;
  complete: string;
  timestamp_complete: string;
  timestamp_created: string;
  batch_complete: any;
}

export interface mainHub {
  id: number;
  address: {
    address1: string;
    address2: string;
    address_jibun: string;
    address_road: string;
    id: number;
    is_resolved: boolean;
    lat: string;
    lng: string;
    mobile_tel: string;
    name: string;
    sector: object;
    timestamp_created: string;
    zipcode: string;
  };
  name: string;
}

export interface pickupDataType {
  alias: string;
  completed: boolean;
  count_stop: number;
  main_hub: mainHub;
  price: number;
  is_return: boolean | null;
  timestamp_push_sent: null | any;
  start_from_hub: boolean;
  timestamp_completed: null | string;
  timestamp_created: string;
  timestamp_started: null | string;
}

export interface HeadDropOffCellNew {
  disablePadding: boolean;
  id: keyof DropOffDataNew;
  label: string;
  numeric: boolean;
}

export interface HeadDropOffCellList {
  disablePadding: boolean;
  id: keyof DropOffDataList;
  label: string;
  numeric: boolean;
}

export const headDropOffCellsNew: readonly HeadDropOffCellNew[] = [
  {
    id: "hub",
    numeric: false,
    disablePadding: true,
    label: "table.header.unit",
  },
  {
    id: "address",
    numeric: false,
    disablePadding: false,
    label: "table.header.unit_address",
  },
  {
    id: "dropOff_route_alias",
    numeric: false,
    disablePadding: false,
    label: "table.header.dropoff_route_alias",
  },
  {
    id: "price",
    numeric: false,
    disablePadding: false,
    label: "table.header.price",
  },
  {
    id: "hub_personnel_mobile_tel",
    numeric: false,
    disablePadding: false,
    label: "table.header.hub_personnel_mobile_tel",
  },
  {
    id: "timestamp_created",
    numeric: false,
    disablePadding: false,
    label: "table.header.timestamp_created",
  },
  {
    id: "add_dropOffBatch",
    numeric: false,
    disablePadding: false,
    label: "button.add_dropoffbatch",
  },
];

export const headDropOffCellsList: readonly HeadDropOffCellList[] = [
  {
    id: "hub",
    numeric: false,
    disablePadding: true,
    label: "table.header.hub",
  },
  {
    id: "address",
    numeric: false,
    disablePadding: false,
    label: "table.header.hub_address",
  },
  {
    id: "dropOff_route_alias",
    numeric: false,
    disablePadding: false,
    label: "table.header.dropoff_route_alias",
  },
  {
    id: "batches_count",
    numeric: false,
    disablePadding: false,
    label: "table.header.count_batches",
  },
  {
    id: "price",
    numeric: false,
    disablePadding: false,
    label: "table.header.price",
  },
  {
    id: "hub_personnel_mobile_tel",
    numeric: false,
    disablePadding: false,
    label: "table.header.hub_personnel_mobile_tel",
  },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "table.header.dropoff_status",
  },
  {
    id: "timestamp_created",
    numeric: false,
    disablePadding: false,
    label: "table.header.timestamp_created",
  },
  {
    id: "timestamp_complete",
    numeric: true,
    disablePadding: false,
    label: "table.header.timestamp_completed",
  },
  {
    id: "edit_route",
    numeric: false,
    disablePadding: false,
    label: "button.edit_route",
  },
  {
    id: "complete_route",
    numeric: false,
    disablePadding: false,
    label: "button.complete_batch",
  },
  {
    id: "assignment_push",
    numeric: false,
    disablePadding: false,
    label: "button.assignment_push",
  },
];

export function createDropOffDataNew(
  hub: string,
  address: string,
  dropOff_route_alias: string,
  price: string,
  hub_personnel_mobile_tel: string,
  timestamp_created: string,
  add_dropOffBatch: any
): DropOffDataNew {
  return {
    hub,
    address,
    dropOff_route_alias,
    price,
    hub_personnel_mobile_tel,
    timestamp_created,
    add_dropOffBatch,
  };
}

export function createDropOffDataList(
  hub: string,
  address: string,
  dropOff_route_alias: string,
  batches_count: number,
  price: string,
  hub_personnel_mobile_tel: string,
  status: string,
  timestamp_created: string,
  timestamp_complete: string,
  edit_route: any,
  complete_route: any,
  assignment_push: any
): DropOffDataList {
  return {
    hub,
    address,
    dropOff_route_alias,
    batches_count,
    price,
    hub_personnel_mobile_tel,
    status,
    timestamp_created,
    timestamp_complete,
    edit_route,
    complete_route,
    assignment_push,
  };
}

export interface HeadCellNew {
  disablePadding: boolean;
  id: keyof DataNew;
  label: string;
  numeric: boolean;
}

export const headCellsNew: readonly HeadCellNew[] = [
  {
    id: "hub",
    numeric: false,
    disablePadding: true,
    label: "table.header.hub",
  },
  {
    id: "address",
    numeric: true,
    disablePadding: false,
    label: "table.header.hub_address",
  },
  {
    id: "pickup_route_alias",
    numeric: true,
    disablePadding: false,
    label: "table.header.pickup_route_alias",
  },
  {
    id: "price",
    numeric: true,
    disablePadding: false,
    label: "table.header.price",
  },
  {
    id: "hub_personnel",
    numeric: true,
    disablePadding: false,
    label: "table.header.hub_personnel",
  },
  {
    id: "hub_personnel_mobile_tel",
    numeric: true,
    disablePadding: false,
    label: "table.header.hub_personnel_mobile_tel",
  },
  {
    id: "timestamp_created",
    numeric: true,
    disablePadding: false,
    label: "table.header.timestamp_created",
  },
  {
    id: "timestamp_complete",
    numeric: true,
    disablePadding: false,
    label: "table.header.timestamp_completed",
  },
  {
    id: "add_batch",
    numeric: true,
    disablePadding: false,
    label: "title.add_pickup_batch",
  },
];

interface HeadCellList {
  disablePadding: boolean;
  id: keyof DataList;
  label: string;
  numeric: boolean;
}
export const headCellsList: readonly HeadCellList[] = [
  {
    id: "hub",
    numeric: false,
    disablePadding: true,
    label: "table.header.hub",
  },
  {
    id: "address",
    numeric: true,
    disablePadding: false,
    label: "table.header.hub_address",
  },
  {
    id: "pickup_route_alias",
    numeric: true,
    disablePadding: false,
    label: "table.header.pickup_route_alias",
  },
  {
    id: "price",
    numeric: true,
    disablePadding: false,
    label: "table.header.price",
  },
  {
    id: "hub_personnel",
    numeric: true,
    disablePadding: false,
    label: "table.header.hub_personnel",
  },
  {
    id: "hub_personnel_mobile_tel",
    numeric: true,
    disablePadding: false,
    label: "table.header.hub_personnel_mobile_tel",
  },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "table.header.status",
  },
  {
    id: "timestamp_created",
    numeric: true,
    disablePadding: false,
    label: "table.header.timestamp_created",
  },
  {
    id: "timestamp_complete",
    numeric: true,
    disablePadding: false,
    label: "table.header.timestamp_completed",
  },
  {
    id: "routeFix",
    numeric: false,
    disablePadding: false,
    label: "table.header.route.fix",
  },
  {
    id: "routeComplete",
    numeric: false,
    disablePadding: false,
    label: "button.complete_route",
  },
  {
    id: "dispatch",
    numeric: false,
    disablePadding: false,
    label: "button.assignment_push",
  },
];

interface BatchHeadCell {
  disablePadding: boolean;
  id: keyof BatchData;
  label: string;
  numeric: boolean;
}

export const BatchHeadCell: readonly BatchHeadCell[] = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "table.header.name",
  },
  {
    id: "type",
    numeric: false,
    disablePadding: false,
    label: "table.header.type",
  },
  {
    id: "pickup_route_alias",
    numeric: false,
    disablePadding: false,
    label: "table.header.pickup_route_alias",
  },
  {
    id: "order",
    numeric: false,
    disablePadding: false,
    label: "table.header.order",
  },
  {
    id: "shipping_address",
    numeric: false,
    disablePadding: false,
    label: "table.header.shipment_address",
  },
  {
    id: "return_address",
    numeric: false,
    disablePadding: false,
    label: "table.header.return_address",
  },
  {
    id: "count_shipping",
    numeric: false,
    disablePadding: false,
    label: "table.header.count_shipping",
  },
  {
    id: "count_return",
    numeric: false,
    disablePadding: false,
    label: "table.header.count_return",
  },
  {
    id: "round",
    numeric: false,
    disablePadding: false,
    label: "table.header.round",
  },
  {
    id: "hub_personnel_mobile_tel",
    numeric: false,
    disablePadding: false,
    label: "table.header.hub_personnel_mobile_tel",
  },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "table.header.status",
  },
  {
    id: "timestamp_complete",
    numeric: false,
    disablePadding: false,
    label: "table.header.timestamp_completed",
  },
  {
    id: "complete",
    numeric: false,
    disablePadding: false,
    label: "table.filter.toggle.complete",
  },
  {
    id: "delay",
    numeric: false,
    disablePadding: false,
    label: "button.delay",
  },
];

interface BatchHeadDropOffCell {
  disablePadding: boolean;
  id: keyof DropOffBatchData;
  label: string;
  numeric: boolean;
}

export const BatchHeadDropOffCell: readonly BatchHeadDropOffCell[] = [
  {
    id: "unit_name",
    numeric: false,
    disablePadding: true,
    label: "table.header.unit",
  },
  {
    id: "unit_address",
    numeric: false,
    disablePadding: false,
    label: "table.header.unit_address",
  },
  {
    id: "dropOff_route_alias",
    numeric: false,
    disablePadding: false,
    label: "table.header.dropoff_route_alias",
  },
  {
    id: "count_drop",
    numeric: false,
    disablePadding: false,
    label: "table.header.count_drop",
  },
  {
    id: "count_return",
    numeric: false,
    disablePadding: false,
    label: "table.header.count_return",
  },
  {
    id: "count_item",
    numeric: false,
    disablePadding: false,
    label: "table.header.count_item",
  },
  {
    id: "complete",
    numeric: false,
    disablePadding: false,
    label: "table.header.dropoff_batch.complete",
  },
  {
    id: "timestamp_complete",
    numeric: false,
    disablePadding: false,
    label: "table.header.dropoff_batch_complete_timestamp",
  },
  {
    id: "timestamp_created",
    numeric: false,
    disablePadding: false,
    label: "table.header.dropoff_batch_created_timestamp",
  },
  {
    id: "batch_complete",
    numeric: false,
    disablePadding: false,
    label: "button.complete_batch",
  },
];

export function createDataNew(
  hub: string,
  address: string,
  pickup_route_alias: string,
  price: number,
  hub_personnel: string,
  hub_personnel_mobile_tel: string,
  timestamp_created: string,
  timestamp_complete: string,
  add_batch: any
): DataNew {
  return {
    hub,
    address,
    pickup_route_alias,
    price,
    hub_personnel,
    hub_personnel_mobile_tel,
    timestamp_created,
    timestamp_complete,
    add_batch,
  };
}

export function createDataList(
  hub: string,
  address: string,
  pickup_route_alias: string,
  price: number,
  hub_personnel: string,
  hub_personnel_mobile_tel: string,
  status: string,
  timestamp_created: string,
  timestamp_complete: string,
  routeFix: any,
  routeComplete: any,
  dispatch: any
): DataList {
  return {
    hub,
    address,
    pickup_route_alias,
    price,
    hub_personnel,
    hub_personnel_mobile_tel,
    status,
    timestamp_created,
    timestamp_complete,
    routeFix,
    routeComplete,
    dispatch,
  };
}

export function createBatchData(
  uuid: string,
  name: string,
  type: string,
  pickup_route_alias: string,
  order: string,
  shipping_address: string,
  return_address: string,
  count_shipping: string,
  count_return: string,
  round: string,
  hub_personnel_mobile_tel: string,
  status: string,
  timestamp_complete: string,
  complete: any,
  delay: any
): BatchData {
  return {
    uuid,
    name,
    type,
    pickup_route_alias,
    order,
    shipping_address,
    return_address,
    count_shipping,
    count_return,
    round,
    hub_personnel_mobile_tel,
    status,
    timestamp_complete,
    complete,
    delay,
  };
}

export function createDropOffBatchData(
  uuid: string,
  unit_name: string,
  unit_address: string,
  dropOff_route_alias: string,
  count_drop: string,
  count_return: string,
  count_item: string,
  complete: string,
  timestamp_complete: string,
  timestamp_created: string,
  batch_complete: any
): DropOffBatchData {
  return {
    uuid,
    unit_name,
    unit_address,
    dropOff_route_alias,
    count_drop,
    count_return,
    count_item,
    complete,
    timestamp_complete,
    timestamp_created,
    batch_complete,
  };
}
export const defaultPickupParams = {
  empty: true,
  alias: "",
  date_end: "",
  date_start: "",
  page: 1,
  page_size: 10,
};

export const defaultPickupBatchParams = {
  empty: false,
  alias: "",
  date_end: "",
  date_start: "",
  page: 1,
  page_size: 10,
};

export const defaultDropoffParams = {
  empty: true,
  alias: "",
  date_end: "",
  date_start: "",
  only_new: false,
  ordering: "",
  page: 1,
  page_size: 10,
  user_uuid: "",
};

export const defaultDropoffBatchParams = {
  empty: false,
  alias: "",
  date_end: "",
  date_start: "",
  only_new: false,
  ordering: "",
  page: 1,
  page_size: 10,
  user_uuid: "",
};
export interface EnhancedTableToolbarProps {
  numSelected: number;
}
type Order = "asc" | "desc";

export interface EnhancedTableProps {
  numSelected: number;
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof DataNew
  ) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

export interface BatchTableProps {
  numSelected: number;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}
