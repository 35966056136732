import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ProfileType, UpdateProfileParam } from "./reducerTypes";
import { AppDispatch, AppThunk } from "./store";
import {
  GetContainerStsApiResponse,
  GetLocationStsApiResponse,
  SectorType,
} from "../api/apiTypes";
import { isBrowser } from "../fns/commonFns";

const initialState: ProfileType = (isBrowser() &&
  window.Cypress &&
  window.initialState?.profile) || {
  phonenumber: "",
  di: "",
  fullname: "",
  dob: "",
  gender: "",
  res_seq: "",
  ride_type: "",
  itemCount: 0,
  boxCount: 0,
  damagedCount: 0,
  invoicePendingCount: 0,
  hubCount: 0,
  unitCount: 0,
  bank_info_name: "",
  bank_info_bank_code: "",
  bank_info_account_number: "",
  sector: [],
};

const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    updateProfile: (state, action: PayloadAction<UpdateProfileParam>) => {
      return { ...state, ...action.payload };
    },
    updateBoxSts: (
      state,
      action: PayloadAction<GetContainerStsApiResponse>
    ) => {
      return {
        ...state,
        boxCount: action.payload.num_container_pending_close,
        itemCount: action.payload.num_item_pending_close,
      };
    },

    updateDamagedCnt: (state, action: PayloadAction<number>) => {
      return {
        ...state,
        damagedCount: action.payload,
      };
    },

    updateInvoicePendingCnt: (state, action: PayloadAction<number>) => {
      return {
        ...state,
        invoicePendingCount: action.payload,
      };
    },

    updateLocationSts: (
      state,
      action: PayloadAction<GetLocationStsApiResponse>
    ) => {
      return {
        ...state,
        hubCount: action.payload.num_hub,
        unitCount: action.payload.num_unit,
      };
    },
    clearProfile: (state, action: PayloadAction) => {
      return initialState;
    },
    addSector: (state, action: PayloadAction<SectorType>) => {
      let sector = state.sector;
      console.log("addSector", action);
      // sector.push(action.payload);

      sector = [action.payload, ...sector];
      return { ...state, sector };
    },

    clearSector: (state, action: PayloadAction<string>) => {
      let sector = state.sector;
      if (sector) {
        sector = sector.filter((s) => s.id !== action.payload);
      }
      return { ...state, sector };
    },
  },
});

export const updateProfile = (data: UpdateProfileParam): AppThunk => async (
  dispatch: AppDispatch
) => {
  dispatch(profileSlice.actions.updateProfile(data));
};

export const updateBoxSts = (
  data: GetContainerStsApiResponse
): AppThunk => async (dispatch: AppDispatch) => {
  dispatch(profileSlice.actions.updateBoxSts(data));
};

export const updateLocationSts = (
  data: GetLocationStsApiResponse
): AppThunk => async (dispatch: AppDispatch) => {
  dispatch(profileSlice.actions.updateLocationSts(data));
};

export const updateDamagedSts = (data: number): AppThunk => async (
  dispatch: AppDispatch
) => {
  dispatch(profileSlice.actions.updateDamagedCnt(data));
};

export const updateInvoicePendingCnt = (data: number): AppThunk => async (
  dispatch: AppDispatch
) => {
  dispatch(profileSlice.actions.updateInvoicePendingCnt(data));
};

export const clearProfile = (): AppThunk => async (dispatch: AppDispatch) => {
  dispatch(profileSlice.actions.clearProfile());
};

export default profileSlice.reducer;
