import React, { useCallback, useRef, useState } from "react";
import styles from "./containerManage.module.css";
import { useTranslation } from "react-i18next";
import LeftTitledTemplate from "../../templates/leftTitled/LeftTitledTemplate";
import TableView from "../../molecules/table/TableView";
import { Button } from "antd";
import { showApiError, showMessage } from "../../../fns/message";
import { destroyBox, getBoxesSts, returnBox } from "../../../api/shippingApi";
import {
  ContainerApiResponse,
  GetBoxStsApiResponse,
} from "../../../api/apiTypes";
import { format } from "date-fns";
import { AxiosError } from "axios";
import SearchView, {
  SearchResultType,
} from "../../organisms/searchView/SearchView";
import CntStateCount from "../../molecules/countState/CntStateCount";
import { useEffect } from "react";
import { handleCopy } from "../../../fns/commonFns";

type SearchType = {
  filter_status?: string | number;
  search?: string;
};
const ContainerManageScreen = () => {
  const { t } = useTranslation(["box"]);
  const selectedRowsRef = useRef<any[]>();
  const dataRef = useRef<any[]>();
  const [params, setParams] = useState<SearchType>();
  const [refresh, setRefresh] = useState(false);
  const [boxCounts, setBoxCounts] = useState<GetBoxStsApiResponse>();
  useEffect(() => {
    getBoxesSts((res) => {
      setBoxCounts(res);
    });
  }, []);

  const handleSearch = useCallback(
    ({ checkedValue, inputValue }: SearchResultType) => {
      setParams({ filter_status: checkedValue, search: inputValue });
    },
    []
  );

  const handleSelect = useCallback((data: any[]) => {
    selectedRowsRef.current = data;
  }, []);

  const handleGetCount = useCallback((count: number, data: any[]) => {
    dataRef.current = data;
  }, []);

  const handleReturn = (data?: any) => {
    const array = !!data && data.length > 0 ? data : selectedRowsRef.current;
    if (!!array && array.length > 0) {
      let pendingCnt = 0,
        successCnt = 0,
        error: AxiosError;
      const req = array.map(
        (s: ContainerApiResponse): Promise<number> => {
          return new Promise<number>((resolve) => {
            if (s.status !== "FINISHED") {
              // 이미 발송 진행한 주문
              pendingCnt++;
              returnBox(
                s.box?.uuid || "",
                () => {
                  resolve(successCnt++);
                },
                (err) => {
                  resolve(0);
                  error = err;
                }
              );
            } else {
              resolve(0);
            }
          });
        }
      );
      Promise.all<number>(req).then((cnt) => {
        if (successCnt > 0) {
          requestRefresh();
          if (successCnt === 1) {
            showMessage(t("success.popup.box.return"));
          } else {
            showMessage(t("success.popup.box.return.all"));
          }
        } else if (!!error) {
          showApiError(error);
        }
      });
      if (pendingCnt === 0) {
        showMessage(t("error.popup.box.checkin.nopending"));
      }
    } else {
      showMessage(t("error.popup.box.checkin.notselected"));
    }
  };

  const requestRefresh = () => {
    setRefresh((prev) => !prev);
  };

  const handleReturnAll = () => {
    handleReturn(dataRef.current);
  };

  const handleCheckLost = (c: ContainerApiResponse) => {
    destroyBox(c.box?.uuid || "", () => {});
  };

  const columns = [
    {
      title: <div className={"bold body"}>{t("manage.table.unit")}</div>,
      width: 100,
      dataIndex: "unit",
      key: "unit",
      render: (text: string, p: ContainerApiResponse) => (
        <div className={"body link table-column"}>
          {p.unit_location?.name || "-"}
        </div>
      ),
    },
    {
      title: <div className={"bold body"}>{t("manage.table.sector")}</div>,
      width: 150,
      dataIndex: "sector",
      key: "sector",
      render: (text: string, p: ContainerApiResponse) => (
        <div className={"body table-column"}>{p.sector?.name || "-"}</div>
      ),
    },
    {
      title: <div className={"bold body"}>{t("manage.table.box")}</div>,
      width: 120,
      dataIndex: "box",
      key: "box",
      render: (text: string, p: ContainerApiResponse) => (
        <div
          className={"body underline text-center primary"}
          onClick={() => handleCopy(p.uuid)}
        >
          {p.box?.alias || "-"}
        </div>
      ),
    },
    {
      title: (
        <div className={"bold body text-center"}>{t("manage.table.type")}</div>
      ),
      width: 80,
      dataIndex: "type",
      key: "type",
      render: (text: string, p: ContainerApiResponse) =>
        p.pickup_only ? (
          <div className={styles.cellStatus + " small bold " + styles.pickup}>
            {t("pickup_only")}
          </div>
        ) : p.count_return > 0 ? (
          <div className={styles.cellStatus + " small bold " + styles.mixed}>
            {t("shipping_mixed")}
          </div>
        ) : (
          <div className={styles.cellStatus + " small " + styles.shipping}>
            {t("shipping_only")}
          </div>
        ),
    },
    {
      title: (
        <div className={"bold body text-center"}>{t("manage.table.count")}</div>
      ),
      dataIndex: "count",
      key: "count",
      width: 60,
      render: (text: string, p: ContainerApiResponse) => (
        <div className={"small black85 underline text-center"}>
          {p.count_total_items}
        </div>
      ),
    },
    {
      title: <div className={"bold body"}>{t("manage.table.status")}</div>,
      width: 160,
      dataIndex: "status",
      key: "status",
      render: (text: string, p: ContainerApiResponse) => (
        <div className={"row align-center body"}>
          <div
            className={
              styles.dot +
              " " +
              (p.status === "FINISHED" ? styles.inactive : "")
            }
          />
          {t(p.status)}
        </div>
      ),
    },
    {
      title: <div className={"bold body"}>{t("manage.table.status.box")}</div>,
      width: 160,
      dataIndex: "status",
      key: "status",
      render: (text: string, p: ContainerApiResponse) => (
        <div className={"row align-center body"}>
          <div
            className={
              styles.dot +
              " " +
              (p.box?.status === "DELIVERYCOMPLETED" ? styles.inactive : "")
            }
          />
          {p.box?.status ? t("list.item." + p.box?.status) : ""}
        </div>
      ),
    },
    {
      title: <div className={"bold body"}>{t("manage.table.rider")}</div>,
      width: 160,
      dataIndex: "rider",
      key: "rider",
      render: (text: string, p: ContainerApiResponse) => (
        <div className={"body table-column"}>{p?.user?.fullname || "-"}</div>
      ),
    },
    {
      title: <div className={"bold body"}>{t("manage.table.phone")}</div>,
      width: 160,
      dataIndex: "phone",
      key: "phone",
      render: (text: string, p: ContainerApiResponse) => (
        <div className={"body row align-center"}>
          {p?.user?.phonenumber ? p?.user?.phonenumber : "-"}
        </div>
      ),
    },
    {
      title: <div className={"bold body"}>{t("manage.table.date")}</div>,
      width: 120,
      dataIndex: "date",
      key: "date",
      render: (text: string, p: ContainerApiResponse) => (
        <div className={"body table-column"}>
          {!!p.timestamp_finished
            ? format(new Date(p.timestamp_finished), "yyyy.MM.dd HH:mm:ss")
            : "-"}
        </div>
      ),
    },
    {
      title: <div className={"bold body"}>{t("manage.table.change")}</div>,
      width: 130,
      dataIndex: "change",
      key: "change",
      render: (text: string, p: ContainerApiResponse) => (
        <Button
          type={"primary"}
          disabled={p.status !== "FINISHED"} //허브 회수 시에만 회수 가능
          className={
            styles.cellBtn +
            " body " +
            (p.status === "FINISHED" ? styles.cellBtnInactive : "")
          }
          onClick={() => {
            handleReturn([p]);
          }}
        >
          {p.status === "FINISHED"
            ? t("manage.btn.finished")
            : t("manage.btn.pending")}
        </Button>
      ),
    },
    {
      title: <div className={"bold body"}>{t("manage.table.lost")}</div>,
      width: 100,
      dataIndex: "lost",
      key: "lost",
      render: (text: string, p: ContainerApiResponse) => (
        <Button
          type={"primary"}
          disabled={p.status !== "UNIT_RETURNED" && p.status !== "FINISHED"} //유닛 회수, 허브 회수 시에만 폐기처리 가능
          onClick={() => handleCheckLost(p)}
          className={
            styles.cellBtn +
            " body " +
            (p.status === "FINISHED" ? styles.cellBtnInactive : "")
          }
        >
          {t("manage.btn.lost")}
        </Button>
      ),
    },
  ];

  const checks = [
    { title: t("all"), value: "ALL" },
    { title: t("manage.sts.working"), value: "WORKING" },
    { title: t("manage.sts.pending"), value: "READYFORRETURN" },
    { title: t("manage.sts.returned"), value: "RETURNED" },
    { title: t("manage.sts.deleted"), value: "DESTROYED" },
  ];

  const btns = [
    { title: t("manage.btn.return"), onClick: handleReturn },
    { title: t("manage.btn.return.all"), onClick: handleReturnAll },
  ];

  const statusNode = (
    <div className={styles.statusCntr + " row"}>
      <CntStateCount
        label={t("manage.sts.all")}
        count={boxCounts?.num_box_total}
        data-cy="HomeAll"
        onClick={() =>
          setParams((params) => ({ ...params, filter_status: "ALL" }))
        }
      />
      <div className={styles.statusDivider} />
      <CntStateCount
        label={t("manage.sts.working")}
        count={boxCounts?.num_box_working}
        data-cy="HomeWorking"
        onClick={() =>
          setParams((params) => ({ ...params, filter_status: "WORKING" }))
        }
      />
      <div className={styles.statusDivider} />
      <CntStateCount
        label={t("manage.sts.pending")}
        count={
          boxCounts
            ? boxCounts?.num_box_return_later + boxCounts?.num_box_return_ready
            : 0
        }
        data-cy="HomeReturn"
        onClick={() =>
          setParams((params) => ({
            ...params,
            filter_status: "READYFORRETURN",
          }))
        }
      />
      <div className={styles.statusDivider} />
      <CntStateCount
        label={t("manage.sts.returned")}
        count={boxCounts?.num_box_returned || 0}
        data-cy="HomeReturned"
        onClick={() =>
          setParams((params) => ({ ...params, filter_status: "RETURNED" }))
        }
      />
      <div className={styles.statusDivider} />
      <CntStateCount
        label={t("manage.sts.deleted")}
        count={boxCounts?.num_box_terminated}
        data-cy="HomeDelete"
        onClick={() =>
          setParams((params) => ({ ...params, filter_status: "DESTROYED" }))
        }
      />
    </div>
  );

  return (
    <LeftTitledTemplate showBreadTitle header={""} classname={styles.cntr}>
      {statusNode}
      <SearchView buttons={btns} />
      <TableView
        scrollX={1500}
        scrollY={
          "calc(var(--app-height) - var(--navbar-height) - var(--tab-height) - 310px)"
        }
        onChange={handleSelect}
        params={params}
        refresh={refresh}
        sortColumn={"timestamp"}
        columns={columns}
        onGetData={handleGetCount}
        url={"/v1/hub/containers/"}
        t={t}
      />
    </LeftTitledTemplate>
  );
};

export default ContainerManageScreen;
