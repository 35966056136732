import React, {
    forwardRef,
    useCallback,
    useEffect,
    useImperativeHandle, useRef,
    useState,
} from "react";
import {Table} from "antd";
import "./table.style.css";
import {useVT} from "virtualizedtableforantd4";
import {getKeyFromDataGetSelectedData} from "../../../fns/objectFns";

export type InfiniteTableProps = {
    className?: string;
    data?: any[];
    columns?: any[];
    rowSelection?: any;
    isLoading?: boolean;
    scrollX?: string | number | true;
    scrollY?: number | string;
    scrollEndY?: number;
    rowKey?: string;
    locale?: any;
    t: any;
    page?: number;
    onChange?: (rows: any[], keys: any[], page?: number) => void;
    onSelect?: (selectedRow: any) => void;
    onLink?: (index: any) => void;
    defaultPageSize?: number;
    onScrollEnd?: () => void;
};

export const InfiniteTable = forwardRef(
    (
        {
            className,
            scrollX,
            scrollEndY,
            scrollY = 500,
            defaultPageSize = 100,
            rowSelection = {},
            isLoading,
            locale,
            onChange,
            onSelect,
            columns,
            onLink,
            data: dataProp,
            t,
            page,
            onScrollEnd,
            rowKey
        }: InfiniteTableProps,
        ref
    ) => {
        const [dataSource, setDataSource] = useState<any[] | undefined>(dataProp);
        const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);
        const [vt] = useVT(
            () => ({
                onScroll: async ({top, isEnd}) => {
                    if (isEnd) {
                        onScrollEnd && onScrollEnd();
                    }
                },
                offset: 40,
                scroll: {y: scrollEndY || scrollY || 500},
                debug: false,
            }),
            [dataSource, scrollEndY]
        );

        useEffect(() => {
            if (!!dataProp && dataProp.length > 0) {
                setDataSource(dataProp);
            } else {
                setDataSource([]);
            }
        }, [dataProp]);

        useImperativeHandle(
            ref,
            () => ({
                setDataSource: (data: any, checkAll?: boolean, releaseCheck?: boolean) => {
                    if (data) {
                        setDataSource(data)
                        if (checkAll) {
                            console.log("getKeyFromDataGetSelectedData handleSelectAll", data, rowKey);
                            const {keys, selectedData} = getKeyFromDataGetSelectedData(data, rowKey);
                            handleChangeRow(keys, selectedData);
                        } 
                        else if(releaseCheck) {
                           handleChangeRow([], []);
                        }
                        else{
                         const {keys, selectedData} = getKeyFromDataGetSelectedData(data, rowKey, selectedRowKeys);
                            handleChangeRow(selectedRowKeys, selectedData);
                       }
                    }
                },
                releaseCheck: () => {
                    handleChangeRow([], []);
                },
            }),
            [selectedRowKeys]
        );

        const handleChangeRow = useCallback(
            (keys: string[], rows: any[]) => {
                console.log("handleChangeRow", keys, rows);
                setSelectedRowKeys(keys);
                if (onChange) onChange(rows, keys);
            },
            [onChange]
        );

        const handleSelectRow = useCallback(
            (record: any, selected: any, unk: any, unk2: any) => {
                if (onSelect) onSelect(record);
            },
            [onSelect]
        );

        return (
            <Table
                className={className}
                scroll={{
                    x: scrollX,
                    scrollToFirstRowOnChange: false,
                    y: scrollY,
                }}
                loading={isLoading}
                rowSelection={
                    onChange
                        ? {
                            selectedRowKeys,
                            onChange: handleChangeRow,
                            onSelect: handleSelectRow,
                            ...rowSelection,
                        }
                        : undefined
                }
                pagination={false}
                columns={columns}
                dataSource={dataSource}
                components={vt}
                locale={locale}
                rowKey={rowKey}
            />
        );
    }
);
