import React, {forwardRef, useImperativeHandle, useState} from "react";
import styles from "./filter.module.css";

import {Button} from "antd";
import {useTranslation} from "react-i18next";
import {IShippingPlanMarker} from "./PlanMap";

interface Props {
    onReset?: () => void;
}

const SelectedShippingPlans = forwardRef(({onReset}: Props, ref) => {
    const [selectedShippings, setSelectedShippings] = useState<
        IShippingPlanMarker[]
    >();
    const {t} = useTranslation("shipping");

    useImperativeHandle(
        ref,
        () => ({
            setShippings: setSelectedShippings,
        }),
        []
    );
    return (
        <div className={styles.shippingList}>
            <div className={styles.shippingList}>
                {selectedShippings &&
                    selectedShippings.map((s, idx) => (
                        <div key={"chek" + idx} className={styles.check + " body"}>
                            {s.shipping_item__tracking_number}
                        </div>
                    ))}
            </div>
            <Button
                type={"text"}
                className={"lime body " + styles.reset}
                onClick={onReset}
            >
                <div className={"row space-between"}>
                    <span>{t("reset")}</span>
                    <span>{selectedShippings?.length || 0}</span>
                </div>
            </Button>
        </div>
    );
});

export default SelectedShippingPlans;
