import i18n from "i18next";
import Backend from "i18next-xhr-backend";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import boxKO from "./ko/box.json";
import scanKO from "./ko/scan.json";
import commonKO from "./ko/common.json";
import loginKO from "./ko/login.json";
import qrKO from "./ko/qr.json";
import printKO from "./ko/print.json";
import pickUpKO from "./ko/routepickUp.json";
import dropOffKO from "./ko/routedropOff.json";
import dispatchKO from "./ko/dispatch.json";
import entryKO from "./ko/entry.json";
import shippingKO from "./ko/shipping.json";
import sortKO from "./ko/sort.json";
import box from "./en/box.json";
import pickUp from "./en/routepickUp.json";
import dropOff from "./en/routedropOff.json";
import scan from "./en/scan.json";
import common from "./en/common.json";
import login from "./en/login.json";
import qr from "./en/qr.json";
import print from "./en/print.json";
import dispatch from "./en/dispatch.json";
const resources = {
  ko: {
    box: boxKO,
    scan: scanKO,
    common: commonKO,
    login: loginKO,
    qr: qrKO,
    print: printKO,
    pickup: pickUpKO,
    dropoff: dropOffKO,
    dispatch: dispatchKO,
    entry: entryKO,
    shipping: shippingKO,
    sort: sortKO,
  },
  en: {
    box: box,
    scan: scan,
    common: common,
    login: login,
    qr: qr,
    print: print,
    pickup: pickUp,
    dropoff: dropOff,
    dispatch: dispatch,
  },
};
i18n
  .use(Backend)
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources,
    ns: [
      "box",
      "scan",
      "common",
      "login",
      "qr",
      "print",
      "pickup",
      "dispatch",
      "shipping",
    ],
    fallbackLng: "ko",
    lng: "ko",
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: false,
    },
  });

export default i18n;
