import React, { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { checkinShippingItem } from "../../api/shippingApi";
import { ShippingType } from "../../api/apiTypes";
import { useHistory } from "react-router-dom";
import { AxiosError } from "axios";
import ScanView from "../organisms/scanView/ScanView";
import { getApiError } from "../../fns/objectFns";
import TabbarView from "../molecules/tabbar/TabbarView";

const ScanShippingItemScreen = () => {
  let resetForm: () => void;
  const { t } = useTranslation(["scan"]);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState<ShippingType>();
  const [loading, setLoading] = useState(false);

  const handleCheckin = ({ box_id }: { box_id: string }) => {
    console.log("onScan", box_id);
    if (box_id && !loading) {
      setLoading(true);
      checkinShippingItem(box_id, onCheckingSuccess, onCheckingFail);
    }
  };

  const handleRefresh = () => {
    setError("");
    setSuccess(undefined);
  };

  const onCheckingSuccess = (res: ShippingType) => {
    setLoading(false);
    setSuccess(res);
    if (!!resetForm) {
      resetForm();
    }
    setTimeout(handleRefresh, 2000);
  };

  const onCheckingFail = (err: AxiosError) => {
    setLoading(false);
    setSuccess(undefined);
    setError(getApiError(err));
    if (!!resetForm) {
      resetForm();
    }

    setTimeout(handleRefresh, 2000);
  };

  return (
    <>
      <ScanView
        resetRef={(c) => {
          resetForm = c;
        }}
        onScan={handleCheckin}
        onRefresh={handleRefresh}
        error={error}
        successAlias={success?.alias}
        success={
          !!success?.shipping_container?.box?.alias ? (
            <div style={{ fontSize: 75 }}>
              {success?.shipping_container?.box?.alias}
            </div>
          ) : undefined
        }
        successLabel={t("scan.done")}
        info={t("scan.info")}
        infoLabel={
          <Trans
            i18nKey={"scan.label"}
            ns={"scan"}
            components={{
              dark: <span className={"black"} />,
            }}
          />
        }
      />
      <TabbarView />
    </>
  );
};

export default ScanShippingItemScreen;
