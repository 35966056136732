import http from "../services/http.service";
import { ConfirmCodeApiResponse } from "./apiTypes";
import { AxiosError } from "axios";

export const signin = (
  username: string,
  password: string,
  onSuccess: (data: ConfirmCodeApiResponse) => void,
  onError: (err: AxiosError) => void
) => {
  http
    .post("/v1/auth/signin/", {
      username,
      password,
    })
    .then((res) => {
      console.log("signin", res);
      onSuccess(res.data);
    })
    .catch(onError);
};

export const signout = (
  refresh: string,
  onSuccess: () => void,
  onError: (e: AxiosError) => void
) => {
  http
    .post("/v1/auth/signout/", { refresh })
    .then((res) => {
      console.log("signout");
      onSuccess();
    })
    .catch(onError);
};
export const verifyToken = (token: string | null) => {
  return http.post("/v1/auth/token/verify/", { token });
};

export const refreshToken = (refresh: string | null) => {
  return http.post("/v1/auth/token/refresh/", { refresh });
};
