import React, { memo, ReactNode, useRef } from "react";
import "./table.style.css";
import SortButton, { SortButtonProps } from "./SortButton";
import { Skeleton } from "antd";

export interface Props {
  value?: string;
  children?: string | ReactNode;
  onClick?: () => void;
  className?: string;
  isAsc?: boolean;
  refreshing?: boolean;
  sort?: any | SortButtonProps;
}

const TableCell = ({
  value,
  children,
  onClick,
  className,
  sort,
  refreshing,
}: Props) => {
  const isClickedRef = useRef(false);
  const handleOnClick = () => {
    isClickedRef.current ? sort?.onSortAsc() : sort?.onSortDesc();
    isClickedRef.current = !isClickedRef.current;
  };

  if (!!onClick) {
    return (
      <a
        className={"body black85 " + className + " table-link"}
        onClick={onClick}
        data-cy="tableNotClick"
      >
        {refreshing ? (
          <Skeleton.Button active style={{ width: 100, height: 24 }} />
        ) : (
          value || children
        )}
      </a>
    );
  }

  if (!!sort) {
    return (
      <a
        className={"body black85 row " + className}
        onClick={handleOnClick}
        data-cy="tableSortClick"
      >
        {refreshing ? (
          <Skeleton.Button active style={{ width: 100, height: 24 }} />
        ) : !!value ? (
          value
        ) : (
          children || "-"
        )}
        {!!sort && <SortButton {...(sort as SortButtonProps)} />}
      </a>
    );
  }

  return (
    <span className={"body black85 row " + (refreshing ? "" : className)}>
      {refreshing ? (
        <Skeleton.Button active style={{ width: "100%", height: 22 }} />
      ) : (
        value || children || "-"
      )}
    </span>
  );
};
export default memo(TableCell);
