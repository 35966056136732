import React, { useEffect, useState } from "react";
import { Row } from "antd";
import styles from "./main.module.css";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import LeftTitledTemplate from "../../templates/leftTitled/LeftTitledTemplate";
import { RootState } from "../../../reducers/reducer";
import { signout } from "../../../api/authApi";
import { clearProfile } from "../../../reducers/profileReducer";
import { clearAuthState } from "../../../reducers/authReducer";
import { TokenStorage } from "../../../services/token.service";
import {
  BUNNY_LINK,
  GUIDE_LINK,
  PERM_MASTER,
  PERM_SORTING,
  PERM_UNIT,
  SELLER_GUIDE_LINK,
  SELLER_LINK,
} from "../../../common/consts.common";
import { format } from "date-fns";
import MainCountView, {
  MainCountProps,
} from "../../molecules/mainCount/MainCountView";
import { getHubStatus } from "../../../api/shippingApi";

type CountType = {
  boxTotal?: number;
  boxGeneral?: number;
  boxReturn?: number;
  returnPending?: number;
  returnLost?: number;
  returnTotalLost?: number;
  hubs?: number;
  units?: number;
  assignPending?: number;
  assignDone?: number;
  pushPending?: number;
  riderBlack?: number;
  riderBlue?: number;
  riderWhite?: number;
  printAll?: number;
  printPending?: number;
  printDone?: number;
  entryLog?: number;
};

const MainScreen = () => {
  const { t } = useTranslation(["common"]);
  const dispatch = useDispatch();
  const { type, fullname, phonenumber, permission_group } = useSelector(
    (state: RootState) => state.profile
  );
  const date = format(new Date(), "MM.dd");

  const [counts, setCounts] = useState<CountType>({});

  useEffect(() => {
    getHubStatus((res) => {
      const counts: CountType = {};
      counts.boxTotal = res.num_container_today;
      counts.boxGeneral = res.num_item_shipping_pending_scan;
      counts.boxReturn = res.num_item_return_pending_scan;
      counts.returnPending = res.num_box_return_ready;
      counts.returnLost = res.num_box_terminated;
      counts.returnTotalLost = res.num_box_terminated;
      counts.assignPending = res.num_container_assign_request_pending;
      counts.assignDone = res.num_container_assign_requested;
      counts.pushPending = res.num_container_assign_push_pending;
      counts.riderBlack = res.num_rider_black;
      counts.riderBlue = res.num_rider_blue;
      counts.riderWhite = res.num_rider_white;
      counts.printAll = res.num_collect_invoice;
      counts.printPending = res.num_collect_invoice_pending;
      counts.printDone = res.num_collect_invoice_done;
      counts.units = res.num_unit;
      counts.hubs = res.num_hub;
      setCounts(counts);
    });
  }, []);

  const handleLogout = () => {
    const refresh = TokenStorage.getRefreshToken() || "";
    signout(
      refresh,
      () => {
        TokenStorage.clear();
        dispatch(clearProfile());
        dispatch(clearAuthState());
      },
      () => {
        TokenStorage.clear();
        dispatch(clearProfile());
        dispatch(clearAuthState());
      }
    );
  };

  const headNode = (
    <div data-cy="headNode" className={styles.darkCntr + " column"}>
      <div className={"row"}>
        <div className={"column flex"}>
          <div className={"body bold white"}>{t("main.admin.info")}</div>
          <span className={styles.profileCntr + " row body1 bold white "}>
            {fullname + " " + phonenumber}
          </span>
        </div>
        <span className={"body bold primaryActive"}>{type}</span>
      </div>
    </div>
  );

  const masterMenu = [
    {
      link: "/home/box",
      label: t("home.box"),
      dataCy: "navigateHome",
      counts: [
        { label: t("count.item.box"), count: counts.boxTotal },
        { label: t("count.item.general"), count: counts.boxGeneral },
        { label: t("count.item.return"), count: counts.boxReturn },
      ],
    },
    {
      link: "/home/return",
      label: t("home.return"),
      dataCy: "navigateHomeManage",
      counts: [
        {
          label: t("count.return.pending"),
          count: counts.returnPending,
        },
        { label: t("count.lost"), count: counts.returnLost },
        { label: t("count.lost.total"), count: counts.returnTotalLost },
      ],
    },
    {
      link: "/dispatch/list",
      label: t("dispatch.list"),
      dataCy: "navigateDispatch",
      counts: [
        {
          label: t("count.assign.pending"),
          count: counts.assignPending,
        },
        { label: t("count.assign.done"), count: counts.assignDone },
        { label: t("count.push.done"), count: counts.pushPending },
      ],
    },
    {
      link: "/status/shipping",
      label: t("status.shipping"),
      dataCy: "navigateShipping",
      counts: [
        { label: "-", count: "-" },
        { label: "-", count: "-" },
        { label: "-", count: "-" },
      ],
    },
    {
      link: "/status/rider",
      label: t("status.rider"),
      dataCy: "navigateRider",
      counts: [
        { label: t("count.black"), count: counts.riderBlack },
        { label: t("count.blue"), count: counts.riderBlue },
        { label: t("count.white"), count: counts.riderWhite },
      ],
    },
    {
      onClick: () => window.open(SELLER_GUIDE_LINK),
      label: t("main.seller.guide"),
      dataCy: "navigateGuideOne",
    },
    {
      onClick: () => window.open(GUIDE_LINK),
      label: t("main.bunny.guide"),
      dataCy: "navigateGuideTwo",
    },
    {
      onClick: () => window.open(SELLER_LINK),
      label: t("main.seller"),
      dataCy: "navigateSeller",
    },
    {
      onClick: () => window.open(BUNNY_LINK),
      label: t("main.bunny"),
      dataCy: "navigateBunny",
    },
  ];

  const hubMenu = [
    {
      link: "/home/box",
      label: t("home.box"),
      dataCy: "navigateHome",
      counts: [
        { label: t("count.item.box"), count: counts.boxTotal },
        { label: t("count.item.general"), count: counts.boxGeneral },
        { label: t("count.item.return"), count: counts.boxReturn },
      ],
    },
    {
      link: "/status/shipping",
      label: t("status.shipping"),
      dataCy: "navigateShipping",
      counts: [
        { label: "-", count: "-" },
        { label: "-", count: "-" },
        { label: "-", count: "-" },
      ],
    },
    {
      onClick: () => window.open(SELLER_GUIDE_LINK),
      label: t("main.seller.guide"),
      dataCy: "navigateGuideOne",
    },
    {
      onClick: () => window.open(GUIDE_LINK),
      label: t("main.bunny.guide"),
      dataCy: "navigateGuideTwo",
    },
    {
      onClick: () => window.open(SELLER_LINK),
      label: t("main.seller"),
      dataCy: "navigateSeller",
    },
    {
      onClick: () => window.open(BUNNY_LINK),
      label: t("main.bunny"),
      dataCy: "navigateBunny",
    },
  ];

  const unitMenu: any = [];

  const links: MainCountProps[] = permission_group
    ? permission_group.includes(PERM_MASTER) ||
      permission_group.includes(PERM_SORTING)
      ? masterMenu
      : permission_group.includes(PERM_UNIT)
      ? unitMenu
      : hubMenu
    : hubMenu;

  return (
    <LeftTitledTemplate
      classname={styles.cntr}
      showBreadTitle
      breadButton={{ title: t("logout"), onClick: handleLogout }}
    >
      {headNode}
      <div className={"body1 bold black " + styles.currentDate}>
        {t("main.current", { date })}
      </div>
      <Row gutter={[8, 10]}>
        {links &&
          links.map((l, index) => (
            <MainCountView key={"main-count" + index} {...l} />
          ))}
      </Row>
    </LeftTitledTemplate>
  );
};

export default MainScreen;
