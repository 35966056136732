import { Dropdown, MenuProps } from "antd";
import React, { useMemo } from "react";
import ArrowDown from "../../../assets/svgs/arrowDown";
import "./typeSelector.css";

type KeyType = { key: string; title: string | JSX.Element };
const TypeSelector = ({
  selectType,
  onSelect,
  values,
}: {
  values: KeyType[];
  selectType: any;
  onSelect: (type: any) => void;
}) => {
  const handleSelect = ({ key }: any) => {
    console.log("handleSelect", key, key);
    onSelect(key);
  };

  const items: MenuProps["items"] = values.map((v, index) => ({
    key: v.key + "",
    label: v.title,
  }));

  const selectedValue = useMemo(() => {
    const selected = values.filter((v) => v.key == selectType);
    if (selected.length > 0) return selected[0];
    return null;
  }, [selectType]);

  return (
    <Dropdown
      menu={{ items, onSelect: handleSelect, selectable: true }}
      placement={"bottom"}
      trigger={["click"]}
    >
      <div className={"row align-center"}>
        {selectedValue?.title}
        <ArrowDown color={"var(--black45)"} className={"type-option-arrow"} />
      </div>
    </Dropdown>
  );
};

export default TypeSelector;
