import React, { useState } from "react";
import "./table.style.css";

export interface SortButtonProps {
  onSortAsc: () => void;
  onSortDesc: () => void;
}

const SortButton = ({ onSortAsc, onSortDesc }: SortButtonProps) => {
  const [upColor, setUpColor] = useState("black");
  const [downColor, setDownColor] = useState("black");

  const handleMouseEnterUp = () => {
    setUpColor("#096DD9");
  };

  const handleMouseLeaveUp = () => {
    setUpColor("black");
  };
  const handleMouseEnter = () => {
    setDownColor("#096DD9");
  };

  const handleMouseLeave = () => {
    setDownColor("black");
  };
  return (
    <div className={"column sort-cntr"}>
      <div
        className={"sort-btn"}
        onMouseEnter={handleMouseEnterUp}
        data-cy="tableSortBtn"
        onMouseLeave={handleMouseLeaveUp}
        // onClick={onSortAsc}
      >
        {/*<SortUp color={upColor} />*/}
      </div>
      <div
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        data-cy="tableSortBtn"
        className={"sort-btn"}
        // onClick={onSortDesc}
      >
        {/*<SortDown color={downColor} />*/}
      </div>
    </div>
  );
};

export default SortButton;
