export const PlanDelete = (props: any) => (
  <svg
    {...props}
    width="14"
    height="14"
    viewBox="0 0 14 14"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      id="Vector"
      d="M2.625 14C2.14375 14 1.73192 13.8478 1.3895 13.5434C1.04708 13.2391 0.875583 12.8727 0.875 12.4444V2.33333H0V0.777778H4.375V0H9.625V0.777778H14V2.33333H13.125V12.4444C13.125 12.8722 12.9538 13.2386 12.6114 13.5434C12.269 13.8483 11.8568 14.0005 11.375 14H2.625ZM4.375 10.8889H6.125V3.88889H4.375V10.8889ZM7.875 10.8889H9.625V3.88889H7.875V10.8889Z"
      fill="currentColor"
    />
  </svg>
);
