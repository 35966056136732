import React, { forwardRef, useImperativeHandle, useState } from "react";
import classes from "./zoomslider.module.css";
import { Slider } from "antd";

type Props = {
  defaultValue?: number;
  min?: number;
  max?: number;
  onChange: (value: number) => void;
};

const OFFSET = 1;
export const ZoomSlider = forwardRef(
  ({ onChange, defaultValue = 13, min = 10, max = 21 }: Props, ref) => {
    const [value, setValue] = useState(defaultValue * OFFSET);

    useImperativeHandle(
      ref,
      () => ({
        setValue: (zoom: number) => {
          console.log("handleM zoom", zoom, value, zoom != value);
          zoom = OFFSET * zoom;
          if (zoom != value) {
            setValue(zoom);
          }
        },
      }),
      [value]
    );

    const handleChange = (value: number) => {
      setValue(value);
      onChange(value / OFFSET);
    };

    const tooltipFormatter = (value?: number) => {
      switch (value) {
        case 13 * OFFSET:
          return "13 우편번호";
        case 14 * OFFSET:
          return "14 지역";
        default:
          return value ? value / OFFSET : value;
      }
    };

    return (
      <div className={classes.container}>
        <Slider
          dots
          value={value}
          vertical
          min={min * OFFSET}
          tooltip={{ formatter: tooltipFormatter }}
          defaultValue={defaultValue * OFFSET}
          max={max * OFFSET}
          onChange={handleChange}
        />
      </div>
    );
  }
);
