import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import styles from "./riderItem.module.css";

import { Button, Input, Modal } from "antd";
import { useTranslation } from "react-i18next";
import ContainerItem from "./ContainerItem";
import { ContainerApiResponse, SectorType } from "../../../api/apiTypes";
import { changeContainer } from "../../../api/shippingApi";
import InfiniteList from "../../molecules/list/InfiniteList";
import { AxiosError } from "axios";
import { showApiError, showMessage } from "../../../fns/message";
import { TrackingType } from "../sectorChange/SectorChangePopup";
import Lottie from "react-lottie";
import spinner from "../../../assets/lotties/spinner.json";

interface Props {
  sector?: SectorType;
  onSplitted: () => void;
}

type SearchParams = {
  search?: string;
};
export const lottieOptions = {
  loop: true,
  autoplay: true,
  animationData: spinner,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};
const ContainerChangePopup = forwardRef(({ onSplitted }: Props, ref) => {
  const { t } = useTranslation("shipping");
  const [selectedUuid, setSelectedUuid] = useState("");
  const [visible, setVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [params, setParams] = useState<SearchParams>({});
  const inputValueRef = useRef("");
  const trackingNumbers = useRef<TrackingType[]>();

  useImperativeHandle(
    ref,
    () => ({
      show: (trackings: TrackingType[]) => {
        setVisible(true);
        trackingNumbers.current = trackings;
      },
      hide: hide,
    }),
    []
  );

  const handleSelect = (uuid: string) => {
    setSelectedUuid(uuid);
  };

  const handleChangeContainer = (e: any) => {
    const array = trackingNumbers.current;
    let successCnt = 0,
      error: AxiosError;
    if (!!array && array.length > 0 && !!selectedUuid) {
      setIsLoading(true);
      const req = array.map(
        ({ tracking_number }): Promise<number> => {
          return new Promise<number>((resolve) => {
            changeContainer(
              { container_uuid: selectedUuid, tracking_number },
              () => {
                resolve(successCnt++);
              },
              (err) => {
                resolve(0);
                error = err;
              }
            );
          });
        }
      );
      Promise.all<number>(req).then((cnt) => {
        setIsLoading(false);
        if (successCnt > 0) {
          onSplitted();
          hide();
        } else if (!!error) {
          showApiError(error);
        }
      });
    } else {
      showMessage("변경할 박스를 선택해주세요.");
    }
  };

  const handleSearch = (e: any) => {
    setParams({ search: inputValueRef.current });
  };

  const hide = () => {
    setVisible(false);
    setSelectedUuid("");
    setParams({});
  };

  function handleKeyUp(e: any) {
    // Enter
    if (e.keyCode === 13) {
      handleSearch(e);
    }
  }

  const buttonNode = (
    <div className={"row "}>
      <Button
        type={"text"}
        className={"white bold body popup-cancel"}
        onClick={hide}
      >
        {t("popup.btn.back")}
      </Button>
      <div className={"modal-divider"} />
      <Button
        type={"text"}
        className={"bold body popup-ok"}
        onClick={handleChangeContainer}
      >
        {t("container.change.popup.title")}
      </Button>
    </div>
  );
  console.log("riderselect", params);

  return (
    <Modal
      closable={false}
      cancelText={""}
      open={visible}
      maskClosable
      centered
      className={"sm-modal"}
      footer={buttonNode}
    >
      <div className={"white subtitle2 bold"}>
        {t("container.change.popup.title")}
      </div>
      <Input
        onChange={(e: any) => (inputValueRef.current = e.target.value)}
        className={"popup-input "}
        placeholder={t("container.change.popup.input")}
        autoComplete={"off"}
        onKeyUp={handleKeyUp}
        suffix={
          <Button
            type={"text"}
            onClick={handleSearch}
            className={styles.inputBtn + " small bold primary"}
          >
            {t("search")}
          </Button>
        }
      />
      <InfiniteList
        url={
          visible ? "/v1/hub/containers/?status=CHECKIN&status=CHECKOUT" : null
        }
        params={params}
        height={300}
        className={styles.list}
      >
        {(count, data) =>
          data && data.length > 0
            ? data.map((c: ContainerApiResponse, id) => (
                <ContainerItem
                  key={"rider" + id}
                  selected={c.uuid == selectedUuid}
                  {...c}
                  onSelect={handleSelect}
                />
              ))
            : isLoading || (
                <div className={"small text-center white"}>
                  {t("rider.empty")}
                </div>
              )
        }
      </InfiniteList>
      {isLoading && <Lottie options={lottieOptions} height={31} width={25} />}
    </Modal>
  );
});

export default ContainerChangePopup;
