import React, { useState } from "react";
import "./login.style.css";
import { signin } from "../../../api/authApi";
import LoginPhoneScreen from "../login/LoginPhoneScreen";
import PopupSpinner from "../../molecules/spinner/PopupSpinnerView";
import { useTranslation } from "react-i18next";
import { ConfirmCodeApiResponse } from "../../../api/apiTypes";
import { useDispatch } from "react-redux";
import { showApiError } from "../../../fns/message";
import { AxiosError } from "axios";
import { TokenStorage } from "../../../services/token.service";
import { updateAuthenticated } from "../../../reducers/authReducer";
import { getUser } from "../../../api/userApi";
import { updateProfile } from "../../../reducers/profileReducer";
import { isDesktopByWidth } from "../../../fns/commonFns";

const LoginPhoneContainer = () => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const { t } = useTranslation(["login"]);

  const handleSignin = (username: string, password: string) => {
    setLoading(true);
    signin(username, password, onPhoneVerified, onError);
  };

  const onError = (err: AxiosError) => {
    showApiError(err);
    setLoading(false);
  };

  const onPhoneVerified = (data: ConfirmCodeApiResponse) => {
    TokenStorage.storeToken(data.access);
    TokenStorage.storeRefreshToken(data.refresh);
    getUser((pro) => {
      dispatch(updateProfile(pro));
    });
    dispatch(updateAuthenticated(true));
  };

  return (
    <div>
      {isDesktopByWidth() && (
        <div className={"menu-header white body1 bold"}>{t("header")}</div>
      )}
      <LoginPhoneScreen onSignin={handleSignin} />
      {loading && <PopupSpinner />}
    </div>
  );
};

export default LoginPhoneContainer;
