import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import BoxRegister from "../components/screens/box/BoxRegister";
import BoxListScreen from "../components/screens/boxList/BoxListScreen";
import BoxDetailScreen from "../components/screens/boxDetail/BoxDetailScreen";
import ScanBoxScreen from "../components/screens/ScanBoxScreen";
import ScanShippingItemScreen from "../components/screens/ScanShippingItemScreen";
import ScanReturnItemScreen from "../components/screens/ScanReturnItemScreen";
import ContainerManageScreen from "../components/screens/containerManage/ContainerManageScreen";
import PlanMapScreen from "../components/screens/plan/PlanMapScreen";
function ManagerRoutes() {
  const renderRootRedirect = () => <Redirect to={"/home/box"} />;
  return (
    <Switch>
      <Route exact path="/home/scan" component={ScanShippingItemScreen} />
      <Route exact path="/home/list" component={BoxListScreen} />
      <Route exact path="/home/scan/return" component={ScanReturnItemScreen} />
      <Route exact path="/home/box" component={BoxRegister} />
      <Route exact path="/home/plan" component={PlanMapScreen} />
      <Route
        exact
        path="/home/list/detail/:uuid/:cntrUuid/"
        component={BoxDetailScreen}
      />
      <Route exact path="/home/return" component={ContainerManageScreen} />
      <Route exact path="/home" component={renderRootRedirect} />
      <Route
        exact
        path="/home/box/detail/:uuid/:cntrUuid/"
        component={BoxDetailScreen}
      />
      <Route
        exact
        path="/home/box/scan/:sector/:name/"
        component={ScanBoxScreen}
      />
      <Route path="*" component={renderRootRedirect} />
    </Switch>
  );
}

export default ManagerRoutes;
