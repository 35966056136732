import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import PrintInvoiceScreen from "../components/screens/print/PrintInvoiceScreen";
import ShippingMapScreen from "../components/screens/shippingMap/ShippingMapScreen";
function StatusRoutes() {
  const renderStatusRedirect = () => <Redirect to={"/status/shipping/"} />;

  return (
    <Switch>
      <Route exact path="/status/shipping/" component={ShippingMapScreen} />
      <Route exact path="/status/rider/" component={PrintInvoiceScreen} />
      <Route path="*" component={renderStatusRedirect} />
    </Switch>
  );
}

export default StatusRoutes;
