import React from "react";
import styles from "./filter.module.css";
import { CloseCircleFilled } from "@ant-design/icons";

import { Input } from "antd";
import { useTranslation } from "react-i18next";

interface Props {
  value?: number | string;
  onChange: (value: number | string) => void;
}

export const RoundSelector = ({ value, onChange }: Props) => {
  const { t } = useTranslation("shipping");

  const handleChange = (value: string) => {
    if (!!value && value.match(/\d+/)) {
      const num = Number(value);
      console.log("hanldeChaneg", num, value);
      if (!isNaN(num)) onChange(num);
    } else if (!value) {
      onChange("");
    }
  };

  return (
    <Input
      className={styles.picker}
      placeholder={t("Filter by plan no")}
      value={value}
      maxLength={3}
      inputMode="numeric"
      onChange={(e) => handleChange(e.target.value)}
      size="middle"
      suffix={
        <CloseCircleFilled
          style={{ color: "rgba(0,0,0,.25)" }}
          onClick={(e) => handleChange("")}
        />
      }
    />
  );
};
