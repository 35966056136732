import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import styles from "./riderItem.module.css";

import { Button, Input, Modal, Radio } from "antd";
import { useTranslation } from "react-i18next";
import InfiniteList from "../../molecules/list/InfiniteList";
import RiderItem from "./RiderItem";
import {
  BunnyType,
  ContainerApiResponse,
  UserAccountType,
} from "../../../api/apiTypes";
import { AxiosError } from "axios";
import { assignContainer } from "../../../api/shippingApi";
import { showApiError, showMessageWithTitle } from "../../../fns/message";

interface Props {
  onAssigned: () => void;
}

type SearchParams = {
  fullname?: string;
  bunny_type?: string;
};
const RiderSelectPopup = forwardRef(({ onAssigned }: Props, ref) => {
  const { t } = useTranslation("dispatch");
  const [selectedUuid, setSelectedUuid] = useState("");
  const [visible, setVisible] = useState(false);
  const [params, setParams] = useState<SearchParams>({});
  const inputValueRef = useRef("");
  const containersRef = useRef<any[]>();

  useImperativeHandle(
    ref,
    () => ({
      show: (containers: any[]) => {
        containersRef.current = containers;
        setVisible(true);
      },
      hide: hide,
    }),
    []
  );

  const handleSelect = (uuid: string) => {
    setSelectedUuid(uuid);
  };

  const handleSearch = (e: any) => {
    setParams((prev) => ({ ...prev, fullname: inputValueRef.current }));
  };

  const handleOk = () => {
    if (!!selectedUuid) {
      handleAssign();
    }
  };

  const handleAssign = () => {
    const array = containersRef.current;
    let successCnt = 0,
      error: AxiosError;
    if (!!array && array.length > 0) {
      const req = array.map(
        (s: ContainerApiResponse): Promise<number> => {
          return new Promise<number>((resolve) => {
            assignContainer(
              s.uuid,
              selectedUuid,
              () => {
                resolve(successCnt++);
              },
              (err) => {
                resolve(0);
                error = err;
              }
            );
          });
        }
      );
      Promise.all<number>(req).then((cnt) => {
        if (successCnt > 0) {
          onAssigned();
          showMessageWithTitle(
            t("assign.popup.title"),
            t("assign.popup.success.content")
          );
          hide();
        } else if (!!error) {
          showApiError(error);
        }
      });
    }
  };

  const hide = () => {
    setVisible(false);
    setSelectedUuid("");
    setParams({});
  };

  const handleFilterType = (e: any) => {
    console.log("radio checked", e.target.value);
    setParams((prev) => ({
      ...prev,
      bunny_type: e.target.value === "ALL" ? undefined : e.target.value,
    }));
  };

  function handleKeyUp(e: any) {
    // Enter
    if (e.keyCode === 13) {
      handleSearch(e);
    }
  }

  const buttonNode = (
    <div className={"row "}>
      <Button
        type={"text"}
        className={"white bold body popup-cancel"}
        onClick={hide}
      >
        {t("assign.popup.btn.cancel")}
      </Button>
      <div className={"modal-divider"} />
      <Button type={"text"} className={"bold body popup-ok"} onClick={handleOk}>
        {t("btn.dispatch.req")}
      </Button>
    </div>
  );
  console.log("riderselect", params);

  return (
    <Modal
      closable={false}
      cancelText={""}
      open={visible}
      maskClosable
      centered
      className={"md-modal"}
      footer={buttonNode}
    >
      <div className={"white subtitle2 bold"}>{t("assign.popup.title")}</div>
      <Radio.Group
        className={styles.radioGrp + " row"}
        name="radiogroup"
        defaultValue={"ALL"}
        onChange={handleFilterType}
      >
        <Radio className={"white bold small"} value={"ALL"}>
          {t("all")}
        </Radio>
        <Radio className={"white bold small"} value={BunnyType.STAFF}>
          {BunnyType.STAFF}
        </Radio>
        <Radio className={"white bold small"} value={BunnyType.FIXED}>
          {BunnyType.FIXED}
        </Radio>
        <Radio className={"white bold small"} value={BunnyType.FLEX}>
          {BunnyType.FLEX}
        </Radio>
      </Radio.Group>
      <Input
        onChange={(e: any) => (inputValueRef.current = e.target.value)}
        className={"popup-input "}
        placeholder={t("input.rider.search")}
        autoComplete={"off"}
        onKeyUp={handleKeyUp}
        suffix={
          <Button
            type={"text"}
            onClick={handleSearch}
            className={styles.inputBtn + " small bold primary"}
          >
            {t("search")}
          </Button>
        }
      />
      <InfiniteList
        url={"/v1/ops/riders/?page_size=40"}
        params={params}
        height={300}
        className={styles.list}
      >
        {(count, data, isLoading) =>
          data && data.length > 0
            ? data.map((rider: UserAccountType, id) => (
                <RiderItem
                  key={"rider" + id}
                  selected={rider.uuid == selectedUuid}
                  uuid={rider.uuid}
                  name={rider.fullname}
                  phonenumber={rider.phonenumber}
                  type={rider.bunny_type}
                  is_delivering={rider.is_delivering}
                  onSelect={handleSelect}
                />
              ))
            : isLoading || (
                <div className={"small text-center white"}>
                  {t("rider.empty")}
                </div>
              )
        }
      </InfiniteList>
    </Modal>
  );
});

export default RiderSelectPopup;
