export const PlanSector = (props: any) => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      id="Vector"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.23077 1.61538H9.46939L6.75231 5.88539L1.71231 2.67831C1.82571 2.36671 2.03227 2.09755 2.30392 1.90739C2.57558 1.71724 2.89917 1.61529 3.23077 1.61538ZM10.7692 12.3846H4.53062L7.68169 7.434L11.3217 1.71231C11.6333 1.82571 11.9024 2.03227 12.0926 2.30392C12.2828 2.57558 12.3847 2.89917 12.3846 3.23077V10.7692C12.3846 11.1977 12.2144 11.6085 11.9115 11.9115C11.6085 12.2144 11.1977 12.3846 10.7692 12.3846ZM3.23077 0C2.37392 0 1.55216 0.340384 0.946271 0.946271C0.340384 1.55216 0 2.37392 0 3.23077V10.7692C0 11.6261 0.340384 12.4478 0.946271 13.0537C1.55216 13.6596 2.37392 14 3.23077 14H10.7692C11.6261 14 12.4478 13.6596 13.0537 13.0537C13.6596 12.4478 14 11.6261 14 10.7692V3.23077C14 2.37392 13.6596 1.55216 13.0537 0.946271C12.4478 0.340384 11.6261 0 10.7692 0H3.23077Z"
      fill="currentColor"
    />
  </svg>
);
