import React, { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { checkinBox } from "../../api/shippingApi";
import { DeliveryWorkingType } from "../../api/apiTypes";
import { AxiosError } from "axios";
import ScanView from "../organisms/scanView/ScanView";
import { getApiError } from "../../fns/objectFns";

interface Params {
  params: {
    sector: string;
    name: string;
  };
}

const ScanBoxScreen = ({ match }: { match: Params }) => {
  const { sector, name } = match.params;
  let resetForm: () => void;
  const { t } = useTranslation(["scan"]);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState<DeliveryWorkingType>();
  const [loading, setLoading] = useState(false);

  const handleCheckin = ({ box_id }: { box_id: string }) => {
    console.log("onScan", box_id);
    if (box_id && !loading) {
      setLoading(true);
      checkinBox(
        { box_uuid: box_id, sector_id: sector },
        onCheckingSuccess,
        onCheckingFail
      );
    }
  };

  const handleRefresh = () => {
    setError("");
    setSuccess(undefined);
  };

  const onCheckingSuccess = (res: DeliveryWorkingType) => {
    setLoading(false);
    setSuccess(res);
    if (!!resetForm) {
      resetForm();
    }
    setTimeout(handleRefresh, 2000);
  };

  const onCheckingFail = (err: AxiosError) => {
    setLoading(false);
    setSuccess(undefined);
    setError(getApiError(err) || t("box.scan.error"));
    if (!!resetForm) {
      resetForm();
    }
    setTimeout(handleRefresh, 2000);
  };

  return (
    <ScanView
      resetRef={(c) => {
        resetForm = c;
      }}
      onScan={handleCheckin}
      onRefresh={handleRefresh}
      error={error}
      success={success?.box?.alias}
      info={
        <Trans
          i18nKey={"box.scan.info"}
          ns={"scan"}
          values={{ sector: name }}
          components={{
            dark: <span className={"black black-85"} />,
          }}
        />
      }
      successLabel={t("box.scan.done")}
    />
  );
};

export default ScanBoxScreen;
