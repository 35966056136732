import React, { forwardRef } from "react";
import styles from "./filter.module.css";

import { Checkbox } from "antd";
import { CheckboxGroupProps } from "antd/es/checkbox";
import useSWR from "swr";
import { fetcher } from "../../../services/http.service";

interface Props extends CheckboxGroupProps {
  onSearch?: (value: string[]) => void;
}

const SectorsNameFilter = forwardRef(({ onSearch, ...rest }: Props, ref) => {
  const { data: sectorsName } = useSWR<string[]>(
    `/v1/location/sectors/name`,
    fetcher
  );
  const handleChangeChecked = (checkedValues: any[]) => {
    console.log("handleChangeChecked = ", checkedValues, sectorsName);
    !!onSearch && onSearch(checkedValues);
  };

  return (
    <div className={styles.container}>
      <div className={"refreshList"}>
        {sectorsName && (
          <Checkbox.Group
            className={styles.list}
            onChange={handleChangeChecked}
            options={sectorsName}
            {...rest}
          />
        )}
        <div style={{ height: 12 }} />
      </div>
    </div>
  );
});

export default SectorsNameFilter;
