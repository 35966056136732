import { AxiosError } from "axios";
import { ErrorType } from "./message";
import { getRandomNumber } from "./commonFns";

export const getKeyFromDataGetSelectedData = (data: any[], keyField?: string, selectedKeys?: string[]) => {
  console.log('getKeyFromDataGetSelectedData 1', keyField, data)
  const keys: any = [];
  const key = keyField || "key";
  const selectedData: any = [];
  if (data) {
    data.forEach((d: any, index: number) => {
      if (d[key]) {
        keys.push(d[key]);
      } else {
        d[key] = index;
        keys.push(index);
      }
      if(selectedKeys){
        selectedKeys.forEach((sKey)=>{
          if(d[key] === sKey)
            selectedData.push(d);
        })
      }
      else
        selectedData.push(d);
    });
  }
  console.log('getKeyFromDataGetSelectedData', keyField, keys, data)
  return {
    selectedData,
    keys,
  };
};

export const deleteObjEmptyValue = (obj: any) => {
  for (var propName in obj) {
    if (!obj[propName]) {
      delete obj[propName];
    }
  }
  return obj;
};

export function arrayToQueryString(key: string, array: string[]) {
  let keyValuePairs: string[] = [];
  array.forEach((value) => {
    keyValuePairs.push(
      encodeURIComponent(key) + "=" + encodeURIComponent(value)
    );
  });
  return "?" + keyValuePairs.join("&");
}

export const isEmptyObject = (object: any) => {
  return !object || !(Object.keys(object).length > 0);
};

export const getApiError = (err: AxiosError) => {
  console.log("error", err?.response);
  const errors: ErrorType[] = err.response?.data?.errors;
  let toast: string = "";
  if (errors && errors.length > 0) {
    errors.forEach((e) => {
      switch (e.code) {
        case "invalid":
          if (err.config.url?.includes("containers/checkin")) {
            toast =
              "등록할 수 없는 박스 또는, 잘못된 UUID입니다. 다시 확인해주세요.";
          } else {
            toast = "잘못된 " + e.field + "입니다. 다시 확인해주세요.";
          }
      }
      if (!toast) {
        switch (e.field) {
          case "toast":
            toast =
              typeof e.message === "string" ? e.message : e.message?.message;
            return;
          case "ui.text":
            toast =
              typeof e.message === "string" ? e.message : e.message?.message;
            break;
          case "ui.title":
          case "ui.button.ok":
          case "ui.button.cancel":
            break;
          case "":
          default:
            toast =
              typeof e.message === "string" ? e.message : e.message?.message;
        }
      }
    });
  } else {
    toast = err?.response?.data?.detail || err.message;
  }
  return toast;
};

export const getRandomColorSector = (
  sector?: string,
  defaultColor?: string,
  nofilter?: boolean
) => {
  const color = defaultColor;
  if (color) {
    return color;
  }
  const random = getRandomNumber(!nofilter);

  // @ts-ignore
  let randomColor = "#" + random.toString(16);
  randomColor = randomColor.padEnd(7, "0");
  return randomColor;
};
