import React from "react";
import styles from "./emptyContent.module.css";
import { useTranslation } from "react-i18next";

type Props = {
  title?: string;
  label?: string;
};
const EmptyContent = ({ title: titleProp, label }: Props) => {
  const { t } = useTranslation(["box"]);
  const title = titleProp || t("empty.title");

  return (
    <div className={styles.container}>
      <div className={"body1 black85"}>{title}</div>
      <div className={"body black45"}>{label}</div>
    </div>
  );
};

export default EmptyContent;
