import React, { useEffect, useState } from "react";
import "../screens/boxList/list.style.css";

import { useTranslation } from "react-i18next";
import { ShippingItemType } from "../../api/apiTypes";

interface Props extends ShippingItemType {
  onClick: (tracking_number: string, select?: boolean) => void;
}

const ShippingItem = ({
  is_return,
  tracking_number,
  alias,
  onClick,
}: Props) => {
  const { t } = useTranslation(["box"]);
  const [selected, setSelected] = useState(false);
  useEffect(() => {
    setSelected(false);
  }, [tracking_number]);

  const handleClick = () => {
    setSelected((selected) => {
      onClick(tracking_number, !selected);
      return !selected;
    });
  };

  return (
    <div
      className={
        "row shipping-item-cntr space-between " +
        (selected ? " shipping-item-selected" : "")
      }
      onClick={handleClick}
    >
      <div className={"column"}>
        <div className={"body1 black black shipping-alias"}>{alias}</div>
        <div className={"body medium black-85"}>{tracking_number}</div>
      </div>

      <div className={"shipping-itrm-sts border-left"}>
        {is_return ? (
          <span className={"body errorActive "}>{t("return")}</span>
        ) : (
          <span className={"body primary "}>{t("delivery")}</span>
        )}
      </div>
    </div>
  );
};

export default ShippingItem;
