import React from "react";
import { Route, Redirect } from "react-router-dom";
import { MenuTemplate } from "@delivus/daas-leftmenu-template";
import menuData from "../datas/menu.data.json";
import menuhubData from "../datas/menuhub.data.json";
import menuUnitData from "../datas/menuUnit.data.json";
import { useSelector } from "react-redux";
import { RootState } from "../reducers/reducer";
import { PERM_MASTER, PERM_SORTING, PERM_UNIT } from "../common/consts.common";
import { useTranslation } from "react-i18next";

type Props = {
  isLoggedIn: boolean;
  showBreadTitle?: boolean;
  component: any;
  path?: string;
};

function ProtectedRouteComponent({
  component: Component,
  showBreadTitle = true,
  isLoggedIn,
  ...rest
}: Props) {
  const { t } = useTranslation("common");
  const { permission_group } = useSelector((state: RootState) => state.profile);

  const menus =
    permission_group &&
    (permission_group.includes(PERM_MASTER) ||
      permission_group.includes(PERM_SORTING))
      ? menuData
      : permission_group && permission_group.includes(PERM_UNIT)
      ? menuUnitData
      : menuhubData;

  return (
    <Route
      {...rest}
      render={(props) => {
        return isLoggedIn ? (
          <MenuTemplate
            header={t("header")}
            t={t}
            showBreadTitle={showBreadTitle}
            menuData={menus}
            defaultOpenKeys={["main", "dispatch", "home", "status", "route"]}
          >
            <Component {...props} />
          </MenuTemplate>
        ) : (
          <Redirect to={"/auth/login"} />
        );
      }}
    />
  );
}

export default ProtectedRouteComponent;
