import React, { useMemo } from "react";
import styles from "./filter.module.css";
import "../unitMap/tooltip.css";
import { useTranslation } from "react-i18next";

export type THoveredShipping = {
  tracking_number: string;
  status: string;
  complete?: boolean;
};
const ShippingsTooltip = ({
  shippings,
}: {
  shippings?: THoveredShipping[];
}) => {
  const { t } = useTranslation("shipping");
  const filtered = useMemo(() => {
    const filtered: {
      status: string;
      count: number;
      complete?: boolean;
    }[] = [];
    if (shippings && shippings.length > 4) {
      shippings.forEach((s) => {
        const contain = filtered.filter((f) => f.status === s.status);
        if (contain.length > 0) {
          contain[0].count++;
        } else {
          filtered.push({ status: s.status, count: 1, complete: s.complete });
        }
      });
    }
    return filtered;
  }, [shippings]);

  if (shippings && shippings.length > 4) {
    return (
      <div className={styles.tooltipCntr}>
        {filtered.map((s, i) => (
          <div key={"shippinginfo" + i} className={"row small info-row"}>
            <span className={"bold"}>{s.count}</span>
            <div className={"row align-center info-label-shipping"}>
              <div
                className={
                  styles.dot + " " + (s.complete ? styles.grey : styles.green)
                }
              />
              {t(s.status)}
            </div>
          </div>
        ))}
      </div>
    );
  }
  if (shippings && shippings.length)
    return (
      <div className={styles.tooltipCntr}>
        {shippings &&
          shippings.map((s, i) => (
            <div key={"shippinginfo" + i} className={"row small info-row"}>
              <span className={"bold"}>{s.tracking_number}</span>
              <div className={"row align-center info-label-shipping"}>
                <div
                  className={
                    styles.dot + " " + (s.complete ? styles.grey : styles.green)
                  }
                />
                {t(s.status)}
              </div>
            </div>
          ))}
      </div>
    );

  return null;
};

export default ShippingsTooltip;
