const ArrowDown = ({
  color = "#6E6EFF",
  className,
}: {
  color?: string;
  className?: string;
}) => (
  <svg
    width="9"
    height="6"
    viewBox="0 0 9 6"
    fill="none"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.393 0.142822L7.55595 0.142822C7.49903 0.142822 7.44545 0.170724 7.41197 0.216483L4.24121 4.58702L1.07045 0.216483C1.03697 0.170724 0.9834 0.142822 0.926481 0.142822L0.0894271 0.142822C0.0168824 0.142822 -0.0255283 0.225412 0.0168824 0.284563L3.95215 5.70979C4.09501 5.90622 4.38742 5.90622 4.52916 5.70979L8.46443 0.284563C8.50796 0.225412 8.46554 0.142822 8.393 0.142822Z"
      fill={color}
    />
  </svg>
);
export default ArrowDown;
