import { AuthType } from "./reducerTypes";
import { AppDispatch, AppThunk } from "./store";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { isBrowser } from "../fns/commonFns";

const initialState: AuthType = (isBrowser() &&
  window.Cypress &&
  window.initialState?.auth) || {
  authenticated: false,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    updateAuthenticated: (state, action: PayloadAction<boolean>) => {
      return { ...state, authenticated: action.payload };
    },
    clearAuth: (state, action: PayloadAction) => {
      return initialState;
    },
  },
});

export const updateAuthenticated = (data: boolean): AppThunk => async (
  dispatch: AppDispatch
) => {
  dispatch(authSlice.actions.updateAuthenticated(data));
};

export const clearAuthState = (): AppThunk => async (dispatch: AppDispatch) => {
  dispatch(authSlice.actions.clearAuth());
};

export default authSlice.reducer;
