import React, { forwardRef, useImperativeHandle, useMemo } from "react";
import styles from "./filter.module.css";

import { Checkbox } from "antd";
import { GetSectorsAPIParams, SectorTypeV2 } from "../../../api/apiTypes";
import { CheckboxGroupProps } from "antd/es/checkbox";
import useSWR from "swr";
import { fetcher } from "../../../services/http.service";
import { objToQueryString } from "../../../fns/commonFns";
import Lottie from "react-lottie";
import spinner from "../../../assets/lotties/spinner.json";
import {queries} from "../../../api/queries";
const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: spinner,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};
interface Props extends CheckboxGroupProps {
  params?: GetSectorsAPIParams;
  onSearch?: (value: string[]) => void;
}

const SectorFilter = forwardRef(({ params, onSearch, ...rest }: Props, ref) => {
  const paramString = useMemo(() => {
    if (params) {
      const {
        designated_sector__code__in,
        designated_sector__name__in,
        ...filtered
      } = params;
      return objToQueryString(filtered, true);
    }
    return "";
  }, [params]);

  const { data: sectors, isLoading, mutate } = useSWR<SectorTypeV2[]>(
    queries.shipping.sectors+`?${paramString}`,
    fetcher
  );

  const formattedSector = useMemo(() => {
    let formatted: any[] = [];
    if (sectors)
      formatted = sectors.map(({ designated_sector__code, count }) => ({
        value: designated_sector__code,
        label: `${designated_sector__code}(${count})`,
      }));
    return formatted;
  }, [sectors]);

  useImperativeHandle(
    ref,
    () => ({
      mutate,
    }),
    []
  );

  const handleChangeChecked = (checkedValues: any[]) => {
    console.log("checked = ", checkedValues);
    !!onSearch && onSearch(checkedValues);
  };

  return (
    <div className={styles.container}>
      <div className={"refreshList"}>
        {sectors && sectors.length > 0 ? (
          <Checkbox.Group
            className={styles.list}
            onChange={handleChangeChecked}
            options={formattedSector}
            {...rest}
          />
        ) : (
          isLoading || "데이터가 없습니다"
        )}
        <div style={{ height: 12 }} />
        {isLoading && (
          <Lottie options={defaultOptions} height={31} width={25} />
        )}
      </div>
    </div>
  );
});

export default SectorFilter;
