import { fetcher } from "../services/http.service";
import { GetListResponse, TZipcodeItem } from "./apiTypes";
import { useEffect, useState } from "react";
import useSWRInfinite from "swr/infinite";
import { queries } from "./queries";
import { useSelector } from "react-redux";
import { RootState } from "../reducers/reducer";
import { fromLonLat } from "ol/proj";
import { PolygonProps } from "@delivus/react-open-layers/dist/esm/types/polygon/polygon.type";

const DARK = "#000000";
export const useZipcodes = (use: boolean, bound?: Array<number>) => {
  const { authenticated } = useSelector((state: RootState) => state.auth);
  const [zipcodes, setZipcodes] = useState<PolygonProps[]>();

  const getKey = (
    pageIndex: number,
    previousPageData: GetListResponse<TZipcodeItem>
  ) => {
    console.log("coordinates*** getKey ", pageIndex, previousPageData);
    if (use && authenticated && pageIndex === 0 && bound && bound?.length > 3)
      return (
        queries.location.zipcode +
        `?page_size=300&min_x=${bound[0]}&min_y=${bound[1]}&max_x=${bound[2]}&max_y=${bound[3]}`
      );
    if (previousPageData && previousPageData.next) return previousPageData.next;
    return null; // reached the end
  };

  const { data, error, setSize } = useSWRInfinite<
    GetListResponse<TZipcodeItem>
  >(getKey, fetcher, {
    initialSize: 1,
    revalidateOnFocus: false,
    revalidateIfStale: false,
    revalidateFirstPage: false,
  });

  console.log("render**** useZipcodes", data, error, zipcodes);
  useEffect(() => {
    if (data && data?.length > 0) {
      const lastRes = data[data?.length - 1];
      console.log("zipcode 1", data, lastRes);
      if (
        lastRes &&
        lastRes.current_page * lastRes.items_per_page < lastRes.count
      )
        setSize((size) => size + 1);
      else {
        let listData: PolygonProps[] = [];
        data.forEach((item) => {
          console.log("coordinates 2", item);
          if (item?.results) {
            item.results.forEach(({ coords, zipcode }, index) => {
              const polygon = createPolygonFromZipcode(coords, zipcode);
              console.log("zipcode polygon", polygon);
              if (polygon) listData.push(polygon);
            });
          }
        });
        setZipcodes(listData);
      }
    }
  }, [data]);

  const createPolygonFromZipcode = (coordString: string, code: string) => {
    let polygon: PolygonProps | null = null;
    console.log("createPolygonFromZipcode", coordString, code);
    if (!!coordString) {
      const coordGroups: Array<Array<number>> = JSON.parse(coordString);
      const coordinates = coordGroups.map((coord) => fromLonLat(coord));
      polygon = {
        strokeWidth: 1,
        strokeColor: DARK,
        fillColor: DARK + "10",
        code,
        coordinates,
      };
    }
    return polygon;
  };

  return {
    zipcodes,
    error,
  };
};
