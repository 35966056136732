import React, { memo } from "react";
import { Button, Skeleton } from "antd";
import styles from "./cnt.module.css";

type Props = {
  count?: number;
  label: string;
  onClick?: (sts: string) => void;
};
const CntStateCount = ({ count, label, onClick }: Props) => {
  function handleClick() {
    onClick && onClick(label);
  }

  return (
    <Button
      type={"primary"}
      className={styles.stsCntr}
      data-cy="homeManageTabs"
      onClick={handleClick}
    >
      {count !== undefined && count >= 0 ? (
        <>
          <span className={"body1 bold white " + styles.stsCount}>{label}</span>
          <span className={" primary"}>{count}</span>
        </>
      ) : (
        <Skeleton
          active
          title={false}
          paragraph={{ rows: 1, width: 120, className: styles.stsLoading }}
        />
      )}
    </Button>
  );
};

export default memo(CntStateCount);
