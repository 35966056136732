import http from "../services/http.service";
import {
    CheckinBoxApiParams,
    CheckoutBoxApiParams,
    ContainerApiResponse,
    ContainerBatchAPIParams,
    ContainerListApiParams,
    ContainerListApiResponse,
    ContainerType,
    CreatePlanMappingParam,
    DeliveryWorkingType,
    GenerateTokeApiResponse,
    GetBoxStsApiResponse,
    GetContainerDetailAPIParam,
    GetContainerDispatchStsApiResponse,
    GetContainerStsApiResponse,
    GetHubStatusApiResponse,
    GetSectorApiResponse, GetSectorsAPIParams,
    GetSellersApiResponse,
    GetShippingAPIParams,
    GetShippingApiResponse, SectorTypeV2,
    ShippingsInContainerApiResponse,
    ShippingType,
    SortStstApiResponse,
} from "./apiTypes";
import { showApiError } from "../fns/message";
import { AxiosError } from "axios";
import { arrayToQueryString } from "../fns/objectFns";
import { objToQueryString } from "../fns/commonFns";
import { TrackingType } from "../components/organisms/sectorChange/SectorChangePopup";
import { ShippingInvoiceFormatTypes } from "@delivus/daas-print-lib/dist/esm/types/components/shippingInvoice/ShippingInvoiceFormat.types";
import {queries} from "./queries";

export const checkinShippingItem = (
  tracking_number: string,
  onSucceed: (data: ShippingType) => void,
  onError: (err: AxiosError) => void
) => {
  http
    .post(`/v1/hub/shippingitems/checkin/`, { tracking_number })
    .then((res) => {
      console.log("checkinShippingItem", res);
      onSucceed(res.data);
    })
    .catch(onError);
};

export const removeShippingPlan = (
  trackingList: TrackingType[],
  onSucceed: (data: ShippingType) => void,
  onError: (err: AxiosError) => void
) => {
  http
    .post(`/v1/hub/sorterplans/shippingitems/remove/`, trackingList)
    .then((res) => {
      console.log("removeShippingPlan", res);
      onSucceed(res.data);
    })
    .catch(onError);
};

export const postShippingSuccess = (
  tracking_number: string,
  onSucceed: (data: ShippingType) => void,
  onError: (err: AxiosError) => void
) => {
  http
    .post(`/v1/ops/shippingitems/${tracking_number}/result/`)
    .then((res) => {
      console.log("postShippingSuccess", res);
      onSucceed(res.data);
    })
    .catch(onError);
};

export const uncheckShippingItem = (
  tracking_number: string,
  onSucceed: (data: ShippingType) => void,
  onError: (err: AxiosError) => void
) => {
  http
    .post(`/v1/hub/shippingitems/uncheck/`, { tracking_number })
    .then((res) => {
      console.log("uncheckShippingItem", res);
      onSucceed(res.data);
    })
    .catch(onError);
};

export const checkinReturnItem = (
  tracking_number: string,
  onSucceed: (data: ShippingType) => void,
  onError: (err: AxiosError) => void
) => {
  http
    .post(`/v1/hub/shippingitems/checkin/return/hub/`, { tracking_number })
    .then((res) => {
      onSucceed(res.data);
    })
    .catch(onError);
};

export const checkinBatchBox = (
  data: CheckinBoxApiParams,
  onSucceed: (data: GetSectorApiResponse) => void,
  onError?: (e: AxiosError) => void
) => {
  http
    .post(`/v1/hub/containers/checkin/batch/`, data)
    .then((res) => {
      console.log("checkinBatchBox", res);
      onSucceed(res.data);
    })
    .catch((e) => {
      if (onError) {
        onError(e);
      } else {
        showApiError(e);
      }
    });
};

export const checkinPlanAll = (
  data: CheckinBoxApiParams,
  onSucceed: (data: GetSectorApiResponse) => void,
  onError?: (e: AxiosError) => void
) => {
  http
    .post(`/v1/hub/containers/checkin/plan/all/`, data)
    .then((res) => {
      console.log("checkin plan all", res);
      onSucceed(res.data);
    })
    .catch((e) => {
      if (onError) {
        onError(e);
      } else {
        showApiError(e);
      }
    });
};

export const checkinBox = (
  data: { box_uuid?: string; sector_id?: string | number; is_plan?: boolean },
  onSucceed: (data: DeliveryWorkingType) => void,
  onError: (err: AxiosError) => void
) => {
  http
    .post(`/v1/hub/containers/checkin/`, data)
    .then((res) => {
      console.log("checkinBox", res);
      onSucceed(res?.data);
    })
    .catch(onError);
};

export const checkoutBatchBox = (
  data: CheckoutBoxApiParams,
  onSucceed: (data: GetSectorApiResponse) => void,
  onError?: (e: AxiosError) => void
) => {
  http
    .post(`/v1/hub/containers/checkout/batch/`, data)
    .then((res) => {
      console.log("checkoutBatchBox", res);
      onSucceed(res.data);
    })
    .catch((e) => {
      if (onError) {
        onError(e);
      } else {
        showApiError(e);
      }
    });
};

export const deletePlan = (
  plan_name: string,
  onSucceed: (data: GetSectorApiResponse) => void,
  onError?: (e: AxiosError) => void
) => {
  http
    .delete(`/v1/hub/sorterplans/${plan_name}/`)
    .then((res) => {
      console.log("deletePlan", res);
      onSucceed(res.data);
    })
    .catch((e) => {
      if (onError) {
        onError(e);
      } else {
        showApiError(e);
      }
    });
};

export const deleteUUID = (
  uuid: string,
  onSucceed: (data: GetSectorApiResponse) => void,
  onError?: (e: AxiosError) => void
) => {
  http
    .delete(`/v1/hub/containers/${uuid}/unregister/`)
    .then((res) => {
      console.log("deleteUUID", res);
      onSucceed(res.data);
    })
    .catch((e) => {
      if (onError) {
        onError(e);
      } else {
        showApiError(e);
      }
    });
};

export const syncSorter = (
  onSucceed: (data: GetSectorApiResponse) => void,
  onError?: (e: AxiosError) => void
) => {
  http
    .post(`/v1/hub/sorterplans/sync/`)
    .then((res) => {
      console.log("syncSorter", res);
      onSucceed(res.data);
    })
    .catch((e) => {
      if (onError) {
        onError(e);
      } else {
        showApiError(e);
      }
    });
};

export const deletePlanBox = (
  container_uuid: string,
  onSucceed: (data: GetSectorApiResponse) => void,
  onError?: (e: AxiosError) => void
) => {
  http
    .post(`/v1/hub/sorterplans/containers/delete/bulk/`, [{ container_uuid }])
    .then((res) => {
      console.log("deletePlanBox", res);
      onSucceed(res.data);
    })
    .catch((e) => {
      if (onError) {
        onError(e);
      } else {
        showApiError(e);
      }
    });
};

export const finalizeConfirm = (
  data: [{ container_uuid: string; plan_name: string }],
  onSucceed: (data: GetSectorApiResponse) => void,
  onError?: (e: AxiosError) => void
) => {
  http
    .post(`/v1/hub/sorterplans/containers/finalize/`, data)
    .then((res) => {
      console.log("deletePlanBox", res);
      onSucceed(res.data);
    })
    .catch((e) => {
      if (onError) {
        onError(e);
      } else {
        showApiError(e);
      }
    });
};

export const syncSortResults = (
  onSucceed: (data: GetSectorApiResponse) => void,
  onError?: (e: AxiosError) => void
) => {
  http
    .post(`/v1/hub/sorterresults/sync/`)
    .then((res) => {
      console.log("syncSortResults", res);
      onSucceed(res.data);
    })
    .catch((e) => {
      if (onError) {
        onError(e);
      } else {
        showApiError(e);
      }
    });
};

export const emptyContainer = (
  uuid: string,
  onSucceed: (data: GetSectorApiResponse) => void,
  onError?: (e: AxiosError) => void
) => {
  http
    .post(`/v1/hub/containers/${uuid}/empty/`)
    .then((res) => {
      console.log("emptyContainer", res);
      onSucceed(res.data);
    })
    .catch((e) => {
      if (onError) {
        onError(e);
      } else {
        showApiError(e);
      }
    });
};

export const checkoutBox = (
  box_uuid: string,
  onSucceed: (data: GetSectorApiResponse) => void,
  onError?: (e: AxiosError) => void
) => {
  http
    .post(`/v1/hub/containers/checkout/`, { box_uuid })
    .then((res) => {
      onSucceed(res.data);
    })
    .catch((e) => {
      if (onError) {
        onError(e);
      } else {
        showApiError(e);
      }
    });
};

export const destroyBox = (
  uuid: string,
  onSucceed: (data: GetSectorApiResponse) => void
) => {
  http
    .post(`/v1/hub/boxes/detroy/`, { uuid })
    .then((res) => {
      onSucceed(res.data);
    })
    .catch(showApiError);
};

export const assignContainer = (
  uuid: string,
  user_uuid: string,
  onSucceed: (data: GenerateTokeApiResponse) => void,
  onError: (err: AxiosError) => void
) => {
  http
    .post(`/v1/hub/containers/${uuid}/assign/`, { user_uuid })
    .then((res) => {
      console.log("assignContainer", res);
      onSucceed(res.data);
    })
    .catch(onError);
};

export const unassignContainer = (
  uuid: string,
  onSucceed: (data: GenerateTokeApiResponse) => void,
  onError: (err: AxiosError) => void
) => {
  http
    .post(`/v1/hub/containers/${uuid}/unassign/`)
    .then((res) => {
      console.log("unassignContainer", res);
      onSucceed(res.data);
    })
    .catch(onError);
};

export const getContainers = (
  onSucceed: (data: ContainerListApiResponse) => void,
  params?: ContainerListApiParams
) => {
  http
    .get(`/v1/hub/containers/`, { params })
    .then((res) => {
      console.log("getContainers", res);
      onSucceed(res.data);
    })
    .catch(showApiError);
};

export const getSectors = (
    params: GetSectorsAPIParams,
    onSucceed: (data: SectorTypeV2[]) => void
) => {
    let paramString = objToQueryString(params, true);
    http
        .get(queries.shipping.sectors + `?${paramString}`)
        .then((res) => {
            console.log("getSectors", queries.shipping.sectors + `?${paramString}`, res.data);
            onSucceed(res.data);
        })
        .catch(showApiError);
};

export const getSorterStatus = (
  onSucceed: (data: SortStstApiResponse) => void
) => {
  http
    .get(`/v1/hub/sorterplan/stat/`)
    .then((res) => {
      console.log("getSorterStatus", res);
      onSucceed(res.data);
    })
    .catch(showApiError);
};

export const updateContainer = (
  uuid: string,
  data: {
    price_premium?: string;
    container_class?: ContainerType;
    count_box?: string | number;
    is_plan?: boolean;
  },
  onSucceed: (data: ContainerApiResponse) => void
) => {
  http
    .patch(`/v1/hub/containers/${uuid}/`, data)
    .then((res) => {
      console.log("updateContainer", res);
      onSucceed(res.data);
    })
    .catch(showApiError);
};

export const returnBox = (
  uuid: string,
  onSucceed: (data: GetSectorApiResponse) => void,
  onError: (err: AxiosError) => void
) => {
  http
    .post(`/v1/hub/boxes/return/`, { uuid })
    .then((res) => {
      onSucceed(res.data);
    })
    .catch(onError);
};

export const getContainerDetail = (
  uuid: string,
  onSucceed: (res: GetContainerDetailAPIParam) => void
) => {
  http
    .get(`/v1/hub/containers/${uuid}/`)
    .then((res) => {
      console.log("getContainerDetail", res);
      onSucceed(res.data);
    })
    .catch(showApiError);
};

export const getShippingsInContainer = (
  uuid: string,
  onSucceed: (res: ShippingsInContainerApiResponse) => void
) => {
  http
    .get(`/v1/hub/boxes/${uuid}/shippingitems`)
    .then((res) => {
      console.log("getShippingsInContainer", res);
      onSucceed(res.data);
    })
    .catch(showApiError);
};

export const getContainerSts = (
  onSucceed: (res: GetContainerStsApiResponse) => void
) => {
  http
    .get(`/v1/hub/containers/stat/`)
    .then((res) => {
      console.log("getContainerSts", res);
      onSucceed(res.data);
    })
    .catch(showApiError);
};

export const getBoxesSts = (onSucceed: (res: GetBoxStsApiResponse) => void) => {
  http
    .get(`/v1/hub/boxes/stat/`)
    .then((res) => {
      console.log("getBoxesSts", res);
      onSucceed(res.data);
    })
    .catch(showApiError);
};

export const getContainerDispatchSts = (
  onSucceed: (res: GetContainerDispatchStsApiResponse) => void
) => {
  http
    .get(`/v1/hub/containers/stat/assign/`)
    .then((res) => {
      console.log("getContainerDispatchSts", res);
      onSucceed(res.data);
    })
    .catch(showApiError);
};

export const getDamagedInvoiceCnt = (onSucceed: (res: number) => void) => {
  http
    .get(`/v1/unit/shippingitems/collect_invoices`)
    .then((res) => {
      console.log("getDamagedInvoiceCnt", res);
      onSucceed(res.data.count);
    })
    .catch(showApiError);
};

export const getInvoiceCntSts = (
  onSucceed: (res: { num_collect_invoice_pending: number }) => void
) => {
  http
    .get(`/v1/unit/collect_invoices/stat/`)
    .then((res) => {
      console.log("getInvoiceCntSts", res);
      onSucceed(res.data);
    })
    .catch(showApiError);
};

export const updateInvoicePrinted = (
  id: string,
  onSucceed: (data: GetSectorApiResponse) => void,
  onError?: (err: AxiosError) => void
) => {
  http
    .post(`/v1/unit/shippingitems/collect_invoices/${id}/print/`, {})
    .then((res) => {
      console.log("updateInvoicePrinted", res);
      onSucceed(res.data);
    })
    .catch(onError);
};

export const revertShipping = (
  data: TrackingType[],
  onSucceed: (data: GetSectorApiResponse) => void,
  onError?: (err: AxiosError) => void
) => {
  http
    .post(`/v1/hub/shippingitems/revert/hub/`, data)
    .then((res) => {
      console.log("revertShipping", res);
      onSucceed(res.data);
    })
    .catch(onError);
};

export const changeSector = (
  data: {
    tracking_number: string;
    sector_code: string;
  },
  onSucceed: (data: GetSectorApiResponse) => void,
  onError?: (err: AxiosError) => void
) => {
  http
    .post(`/v1/hub/shippingitems/update/sector/`, data)
    .then((res) => {
      console.log("changeSector", res);
      onSucceed(res.data);
    })
    .catch(onError);
};

export const changeContainer = (
  data: {
    tracking_number: string;
    container_uuid: string;
  },
  onSucceed: (data: GetSectorApiResponse) => void,
  onError?: (err: AxiosError) => void
) => {
  http
    .post(`/v1/hub/shippingitems/update/box/`, data)
    .then((res) => {
      console.log("changeBox", res);
      onSucceed(res.data);
    })
    .catch(onError);
};

export const batchContainer = (
  data: ContainerBatchAPIParams[],
  onSucceed: (data: GetSectorApiResponse) => void,
  onError?: (err: AxiosError) => void
) => {
  http
    .post(`/v1/hub/shippingitems/checkin/batch/`, data)
    .then((res) => {
      console.log("batchContainer", res);
      onSucceed(res.data);
    })
    .catch(onError);
};

export const createPlan = (
  data: {
    plan_name: string;
    sort_equipment_id?: string;
    mapping?: CreatePlanMappingParam[];
  },
  onSucceed: (data: GetSectorApiResponse) => void,
  onError?: (err: AxiosError) => void
) => {
  http
    .post(`/v1/hub/sorterplans/containers/`, data)
    .then((res) => {
      console.log("createPlan", res);
      onSucceed(res.data);
    })
    .catch(onError);
};

export const getPrintList = (
  tracking: string[] | undefined,
  onSucceed: (data: ShippingInvoiceFormatTypes[]) => void,
  page_size: number = 10
) => {
  let params: any;
  const urlParam =
    tracking && tracking.length > 0
      ? arrayToQueryString("tracking", tracking)
      : "";
  console.log("getPrintList", urlParam, tracking);
  http
    .get(`/v1/shipping/shippingitems/print/` + urlParam, {
      params: { page_size },
    })
    .then((res) => {
      console.log("getPrintList", urlParam, tracking, params, res);
      onSucceed(res.data?.results);
    })
    .catch(showApiError);
};

export const getHubStatus = (
  onSucceed: (data: GetHubStatusApiResponse) => void
) => {
  http
    .get(`/v1/hub/stat/`)
    .then((res) => {
      console.log("getHubStatus", res);
      onSucceed(res.data);
    })
    .catch(showApiError);
};

export const splitContainer = (
  uuid: string,
  tracking_numbers: string[],
  onSucceed: (data: ContainerApiResponse) => void
) => {
  http
    .post(`/v1/hub/containers/${uuid}/split/`, { tracking_numbers })
    .then((res) => {
      console.log("splitContainer", res);
      onSucceed(res.data);
    })
    .catch(showApiError);
};

export const getShippings = async (
  params: GetShippingAPIParams,
  onSucceed: (res: GetShippingApiResponse) => void,
  onError: (e: any) => void
) => {
  try {
    const res = await http.get(`/v1/hub/shippingitems/`, {
      params,
    });
    const count = res.data.count || 50;
    http
      .get(`/v1/hub/shippingitems/?page_size=${count}`, {
        params,
      })
      .then((res) => {
        console.log("getShippings", res);
        onSucceed(res.data);
      })
      .catch(onError);
  } catch (error: any) {
    onError(error);
  }
};

export const getSellers = async (
  onSucceed: (res: GetSellersApiResponse) => void
) => {
  try {
    const res = await http.get(`/v1/ops/shops/ongoing/`);
    const count = res.data.count || 10;
    http
      .get(`/v1/ops/shops/ongoing/?page_size=${count}`)
      .then((res) => {
        console.log("getSellers", res);
        onSucceed(res.data);
      })
      .catch(showApiError);
  } catch (error: any) {
    showApiError(error);
  }
};

export const hubContainerBatchUpdate = (
  batchData: any,
  onSucceed: (data: any) => void,
  onError: () => void
) => {
  http
    .post(`/v1/hub/shippingcontainers/update/`, batchData)
    .then((res) => {
      onSucceed(res);
    })
    .catch((e: any) => {
      onError();
    });
};
