export const PlanRefresh = (props: any) => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      id="Vector"
      d="M11.9425 2.05625C11.2945 1.40424 10.5237 0.886866 9.67471 0.533952C8.82569 0.181039 7.91516 -0.000432561 6.99562 7.74239e-07C3.1257 7.74239e-07 0 3.1325 0 7C0 10.8675 3.1257 14 6.99562 14C10.2614 14 12.9844 11.7688 13.7636 8.75H11.9425C11.5818 9.77333 10.9122 10.6596 10.0262 11.2865C9.14014 11.9134 8.08125 12.25 6.99562 12.25C4.09756 12.25 1.74234 9.89625 1.74234 7C1.74234 4.10375 4.09756 1.75 6.99562 1.75C8.44903 1.75 9.74484 2.35375 10.6904 3.3075L7.87117 6.125H14V7.74239e-07L11.9425 2.05625Z"
      fill="currentColor"
    />
  </svg>
);
