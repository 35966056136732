import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import styles from "./riderItem.module.css";

import { Button, Input, Modal } from "antd";
import { useTranslation } from "react-i18next";
import SectorItem from "./SectorItem";
import { SectorType } from "../../../api/apiTypes";
import { changeSector } from "../../../api/shippingApi";
import InfiniteList from "../../molecules/list/InfiniteList";
import { AxiosError } from "axios";
import { showApiError, showMessage } from "../../../fns/message";
import Lottie from "react-lottie";
import { lottieOptions } from "../containerChange/ContainerChangePopup";

interface Props {
  sector?: SectorType;
  onSplitted: () => void;
}

type SearchParams = {
  fullname?: string;
  bunny_type?: string;
};

export type TrackingType = {
  tracking_number: string;
};

const SectorChangePopup = forwardRef(({ onSplitted }: Props, ref) => {
  const { t } = useTranslation("shipping");
  const [selectedCode, setSelectedCode] = useState("");
  const [visible, setVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [params, setParams] = useState<SearchParams>({});
  const inputValueRef = useRef("");
  const trackingNumbers = useRef<TrackingType[]>();

  useImperativeHandle(
    ref,
    () => ({
      show: (trackings: TrackingType[]) => {
        setVisible(true);
        trackingNumbers.current = trackings;
      },
      hide: hide,
    }),
    []
  );

  const handleSelect = (code: string) => {
    setSelectedCode(code);
  };

  const handleChangeSector = (e: any) => {
    const array = trackingNumbers.current;
    let successCnt = 0,
      error: AxiosError;
    if (!!array && array.length > 0 && !!selectedCode) {
      setIsLoading(true);
      const req = array.map(
        ({ tracking_number }): Promise<number> => {
          return new Promise<number>((resolve) => {
            changeSector(
              { sector_code: selectedCode, tracking_number },
              () => {
                resolve(successCnt++);
              },
              (err) => {
                resolve(0);
                error = err;
              }
            );
          });
        }
      );
      Promise.all<number>(req).then((cnt) => {
        if (successCnt > 0) {
          onSplitted();
          hide();
        } else if (!!error) {
          showApiError(error);
        }
      });
    } else {
      showMessage("변경할 지역을 선택해주세요.");
    }
  };

  function handleKeyUp(e: any) {
    // Enter
    if (e.keyCode === 13) {
      handleSearch(e);
    }
  }

  const handleSearch = (e: any) => {
    setParams((prev) => ({ ...prev, code: inputValueRef.current }));
  };

  const hide = () => {
    setIsLoading(false);
    setVisible(false);
    setSelectedCode("");
    setParams({});
  };

  const buttonNode = (
    <div className={"row "}>
      <Button
        type={"text"}
        className={"white bold body popup-cancel"}
        onClick={hide}
      >
        {t("popup.btn.back")}
      </Button>
      <div className={"modal-divider"} />
      <Button
        type={"text"}
        className={"bold body popup-ok"}
        onClick={handleChangeSector}
      >
        {t("sector.change.popup.title")}
      </Button>
    </div>
  );

  return (
    <Modal
      closable={false}
      cancelText={""}
      open={visible}
      maskClosable
      centered
      className={"sm-modal"}
      footer={buttonNode}
    >
      <div className={"white subtitle2 bold"}>
        {t("sector.change.popup.title")}
      </div>
      <Input
        onChange={(e: any) => (inputValueRef.current = e.target.value)}
        className={"popup-input "}
        placeholder={t("sector.change.popup.input")}
        autoComplete={"off"}
        onKeyUp={handleKeyUp}
        suffix={
          <Button
            type={"text"}
            onClick={handleSearch}
            className={styles.inputBtn + " small bold primary"}
          >
            {t("search")}
          </Button>
        }
      />
      <InfiniteList
        url={visible ? "/v1/location/sectors/?allowed=1" : null}
        params={params}
        height={300}
        className={styles.list}
      >
        {(count, data) =>
          data && data.length > 0
            ? data.map((c: SectorType, id) => (
                <SectorItem
                  key={"rider" + id}
                  selected={c.code == selectedCode}
                  {...c}
                  onSelect={handleSelect}
                />
              ))
            : isLoading || (
                <div className={"small text-center white"}>
                  {t("rider.empty")}
                </div>
              )
        }
      </InfiniteList>
      {isLoading && <Lottie options={lottieOptions} height={31} width={25} />}
    </Modal>
  );
});

export default SectorChangePopup;
