import React, { useEffect } from "react";
import "./App.css";
import "@delivus/react-open-layers/dist/esm/index.css";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "./reducers/reducer";
import { clearAuthState, updateAuthenticated } from "./reducers/authReducer";
import ManagerRoutes from "./router/ManagerRoutes";
import AuthRouteComponent from "./router/AuthRouteComponent";
import AuthRoutes from "./router/AuthRoutes";
import ProtectedRouteComponent from "./router/ProtectedRouteComponent";
import ProfileExpired from "./components/screens/launch/ProfileExpired";
import Routes from "./router/Routes";
import MainScreen from "./components/screens/main/MainScreen";
import {
  clearProfile,
  updateBoxSts,
  updateLocationSts,
  updateProfile,
} from "./reducers/profileReducer";
import { getUser } from "./api/userApi";
import { TokenStorage } from "./services/token.service";
import { getContainerSts } from "./api/shippingApi";
import { getLocationSts } from "./api/locationApi";
import DispatchListScreen from "./components/screens/dispatchList/DispatchListScreen";
import StatusRoutes from "./router/StatusRoutes";
import { setResponse } from "./services/http.service";
import { signout } from "./api/authApi";
import { showApiError } from "./fns/message";

declare global {
  interface Window {
    $: any;
    Cypress: any;
    initialState: any;
    install: any;
    installed: boolean;
  }
}

if (process.env.REACT_APP_STAGE === "PROD") {
  console.log = function no_console() {};
}

function App() {
  const dispatch = useDispatch();
  let verifyCalled = false;
  const { authenticated } = useSelector((state: RootState) => state.auth);

  const appHeight = () => {
    const doc = document.documentElement;
    setTimeout(() => {
      if (doc) {
        let vh = window.innerHeight * 0.01;
        doc.style.setProperty("--vh", `${vh}px`);
      }
    }, 100);
  };

  const onContext = (e: any) => {
    // e.preventDefault();
  };

  const onBeforeAppInstalled = (e: any) => {
    e.preventDefault();
    window.install = e;
  };

  const onAppInstalled = () => {
    window.installed = true;
  };

  const onContentLoaded = () => {
    if (
      // @ts-ignore
      navigator?.standalone ||
      window.matchMedia("(display-mode: standalone)").matches ||
      window.matchMedia("(display-mode: fullscreen)").matches ||
      window.matchMedia("(display-mode: minimal-ui)").matches
    ) {
      window.installed = true;
    }
  };

  useEffect(() => {
    setResponse(dispatch);
    document.addEventListener("contextmenu", onContext);
    window.addEventListener("resize", appHeight);
    appHeight();
    window.addEventListener("beforeinstallprompt", onBeforeAppInstalled);

    if (!navigator.serviceWorker) {
      window.installed = true;
    }

    window.addEventListener("appinstalled", onAppInstalled);

    //but also add a listener. After app installation on desktop, the app will open in their own window right away.
    window.addEventListener("DOMContentLoaded", onContentLoaded);

    return () => {
      window.removeEventListener("resize", appHeight);
      window.removeEventListener("contextmenu", onContext);
      window.removeEventListener("beforeinstallprompt", onBeforeAppInstalled);
      window.removeEventListener("DOMContentLoaded", onContentLoaded);
      window.removeEventListener("appinstalled", onAppInstalled);
    };
  }, []);

  useEffect(() => {
    if (TokenStorage.isAuthenticated() && !verifyCalled) {
      verifyCalled = true;
      TokenStorage.verifyToken(onTokenValid, onRefreshError);
    }
  }, []);

  useEffect(() => {
    console.log("clearProfile", authenticated);
    if (authenticated === false) {
      const refresh = TokenStorage.getRefreshToken();
      if (refresh) {
        signout(
          refresh,
          () => {
            TokenStorage.clear();
            dispatch(clearProfile());
          },
          (e) => {
            showApiError(e);
            TokenStorage.clear();
            dispatch(clearProfile());
          }
        );
      }
    }
  }, [authenticated, dispatch]);

  const onTokenValid = () => {
    dispatch(updateAuthenticated(true));
    getUser((pro) => {
      dispatch(updateProfile(pro));
    });
    getLocationSts((res) => {
      dispatch(updateLocationSts(res));
    });
    getContainerSts((cnt) => {
      dispatch(updateBoxSts(cnt));
    });
  };

  const onRefreshError = (e: any) => {
    dispatch(clearProfile());
    dispatch(clearAuthState());
  };

  const renderRootRedirect = () => (
    <Redirect to={authenticated ? "/main" : "/auth/login"} />
  );

  return (
    <Router>
      <Switch>
        <Route exact path="/" component={renderRootRedirect} />
        <Route exact path="/logout" component={ProfileExpired} />
        <ProtectedRouteComponent
          path={"/home"}
          isLoggedIn={authenticated}
          showBreadTitle={false}
          component={ManagerRoutes}
        />
        <ProtectedRouteComponent
          path={"/status"}
          isLoggedIn={authenticated}
          showBreadTitle={false}
          component={StatusRoutes}
        />
        <ProtectedRouteComponent
          path={"/route"}
          isLoggedIn={authenticated}
          showBreadTitle={false}
          component={Routes}
        />
        <ProtectedRouteComponent
          path={"/dispatch/list"}
          isLoggedIn={authenticated}
          component={DispatchListScreen}
        />
        <ProtectedRouteComponent
          path={"/main"}
          isLoggedIn={authenticated}
          showBreadTitle={false}
          component={MainScreen}
        />
        <AuthRouteComponent
          path={"/auth"}
          isLoggedIn={authenticated}
          component={AuthRoutes}
        />
        <Route path="*" component={renderRootRedirect} />
      </Switch>
    </Router>
  );
}

export default App;
