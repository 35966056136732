export const Geolocation = (props: any) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g id="nearby">
      <path
        id="Oval"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.0001 12.5713C11.4203 12.5713 12.5716 11.4201 12.5716 9.9999C12.5716 8.57973 11.4203 7.42847 10.0001 7.42847C8.57998 7.42847 7.42871 8.57973 7.42871 9.9999C7.42871 11.4201 8.57998 12.5713 10.0001 12.5713Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="1.28571"
        strokeLinecap="round"
      />
      <path
        id="Oval_2"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.99986 16.4287C13.5503 16.4287 16.4284 13.5505 16.4284 10.0001C16.4284 6.4497 13.5503 3.57153 9.99986 3.57153C6.44946 3.57153 3.57129 6.4497 3.57129 10.0001C3.57129 13.5505 6.44946 16.4287 9.99986 16.4287Z"
        stroke="currentColor"
        strokeWidth="1.28571"
        strokeLinecap="round"
      />
      <path
        id="Vector 1"
        d="M1 10H3.4M16.6 10H19"
        stroke="currentColor"
        strokeWidth="1.28571"
        strokeLinecap="round"
      />
      <path
        id="Vector 2"
        d="M10 19V16.6M10 3.4V0.999999"
        stroke="currentColor"
        strokeWidth="1.28571"
        strokeLinecap="round"
      />
    </g>
  </svg>
);
