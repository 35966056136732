import React from "react";
import styles from "./riderItem.module.css";

import { Button, Radio } from "antd";
import { SectorType } from "../../../api/apiTypes";

interface Props extends SectorType {
  selected: boolean;
  onSelect: (code: string) => void;
}

const SectorItem = ({ selected, onSelect, code, id, area }: Props) => {
  const handleSelect = (e: any) => {
    if (!selected) onSelect(code);
  };

  return (
    <Button
      type={"text"}
      className={styles.container + " row align-center"}
      onClick={handleSelect}
    >
      <Radio checked={selected} onChange={handleSelect} />
      <div className={"flex column " + styles.name}>
        <div className={"small bold white85"}>{code}</div>
        <div className={"small bold white45 " + styles.phone}>{id}</div>
      </div>
      <span className={"small black white " + styles.type}>{area}</span>
    </Button>
  );
};

export default SectorItem;
