import React, { useEffect, useState } from "react";
import "../screens/box/box.style.css";

import { Button, Dropdown, Menu } from "antd";
import { Trans, useTranslation } from "react-i18next";
import { LogicalBoxType } from "../../api/apiTypes";
import { checkinBox, checkoutBox } from "../../api/shippingApi";
import {
  showMessage,
  showMessageWithTitle,
  showApiError,
} from "../../fns/message";
import { isDesktop } from "react-device-detect";
import PopupSpinner from "../molecules/spinner/PopupSpinnerView";
import ArrowDown from "../../assets/svgs/arrowDown";

interface Props extends LogicalBoxType {
  onCloseDone: () => void;
  refresh: boolean;
  onClick: (data: LogicalBoxType, selected: boolean) => void;
  onPrint: (data: [LogicalBoxType], damaged?: boolean) => void;
}
const BoxRegisterItem = ({
  onCloseDone,
  onPrint,
  onClick,
  refresh,
  ...data
}: Props) => {
  const { t } = useTranslation(["box"]);
  const [loading, setLoading] = useState(false);
  const { box, container, sector } = data;
  const [selected, setSelected] = useState(false);

  useEffect(() => {
    setSelected(false);
  }, [refresh]);

  const handleClick = () => {
    setSelected((selected) => {
      onClick(data, !selected);
      return !selected;
    });
  };

  const askCheckout = () => {
    showMessageWithTitle(
      t("popup.check.ask.title", { box: box?.alias }),
      t("popup.check.ask.content", { count: 30 }),
      "warning",
      t("popup.check.ask.btn.ok"),
      handleCloseBox,
      t("popup.check.ask.btn.cancel")
    );
  };

  const handleCheckinKey = ({ key }: any) => {
    handleCheckingBox(key === "plan");
  };

  const handleCloseBox = () => {
    if (box) {
      checkoutBox(box?.uuid, onCheckoutSuccess);
    } else {
    }
  };

  const handleCheckingBox = (is_plan?: boolean) => {
    setLoading(true);
    checkinBox({ sector_id: sector?.id, is_plan }, onCheckinSuccess, (e) => {
      setLoading(false);
      showApiError(e);
    });
  };

  const onCheckinSuccess = () => {
    setLoading(false);
    onCloseDone();
    showMessage(t("popup.checkin.done"));
  };

  const onCheckoutSuccess = () => {
    setLoading(false);
    showMessage(t("popup.check.done", { box: box?.alias }));
    onCloseDone();
  };

  const handlePrint = () => {
    if (isDesktop) {
      onPrint([data]);
    } else {
      showMessage(t("box.item.popup.print.error.notdesktop"));
    }
  };

  const handlePrintDamaged = () => {
    if (isDesktop) {
      onPrint([data], true);
    } else {
      showMessage(t("box.item.popup.print.error.notdesktop"));
    }
  };

  const aliasNode = (
    <div
      className={"row box-item-alias-cntr space-between small bold "}
      data-cy="boxRegisterBtn"
      onClick={!!box?.alias ? undefined : () => handleCheckingBox()}
    >
      <span className={"flex black text-center"}>{box?.alias}</span>
      <span className={"flex black45 text-center"}>{t("box.item.nobox")}</span>
      <Button
        type={"primary"}
        className={
          "small bold box-item-sector" +
          (!!box?.alias ? " active" : " inactive")
        }
      >
        {sector?.code}
      </Button>
    </div>
  );

  const cntNode = (
    <div className={"column"}>
      <div className={"body" + (!!box?.alias ? " black85" : " black45")}>
        <Trans
          i18nKey={"box.item.cnt.pending"}
          ns={"box"}
          values={{
            return:
              container?.pending_return_item_count ||
              sector?.pending_return_item_count,
            shipping:
              container?.pending_shipping_item_count ||
              sector?.pending_shipping_item_count,
          }} //wip: 서버에세 API 제공후 실 카운트 적
          components={{
            red: (
              <span className={!!box?.alias ? " errorActive" : " black45"} />
            ),
          }}
        />
      </div>
      <div className={"body " + (!!box?.alias ? " black85" : " black45")}>
        <Trans
          i18nKey={"box.item.cnt.scanned"}
          ns={"box"}
          values={{
            return: container?.checked_in_return_item_count || 0,
            shipping: container?.checked_in_shipping_item_count || 0,
          }} //wip: 서버에세 API 제공후 실 카운트 적
          components={{
            red: (
              <span className={!!box?.alias ? " errorActive" : " black45"} />
            ),
          }}
        />
      </div>
    </div>
  );

  const menu = () => (
    <Menu onClick={handleCheckinKey}>
      <Menu.Item key={"general"} className="row align-center">
        {t("box.item.btn.checkin.gen")}
      </Menu.Item>
      <Menu.Item key={"plan"} className="row align-center">
        {t("box.item.btn.checkin.plan")}
      </Menu.Item>
    </Menu>
  );

  const btnNode = (
    <div className={"row"}>
      {!!box ? (
        <Button
          type={"text"}
          className={"flex btn-medium bold body primary"}
          onClick={askCheckout}
        >
          {t("box.item.btn.checkout")}
        </Button>
      ) : (
        <div className={"flex row align-center justify-center"}>
          <Dropdown
            overlay={menu}
            align={{ offset: [0, 0] }}
            placement={"bottomRight"}
            trigger={["click"]}
          >
            <div
              onMouseEnter={(e) => e.preventDefault()}
              className={"row align-center drop-style primary body bold"}
            >
              {t("box.item.btn.checkin")}
              <ArrowDown
                color={"var(--black25)"}
                className={"type-option-arrow"}
              />
            </div>
          </Dropdown>
        </div>
      )}
      <Button
        type={"text"}
        className={"flex btn-medium bold body primary"}
        disabled={!box}
        onClick={handlePrint}
      >
        {t("box.item.btn.label.count", { count: container?.count_box || 0 })}
      </Button>
      <Button
        type={"text"}
        className={"flex btn-medium bold body primary"}
        disabled={!box}
        onClick={handlePrintDamaged}
      >
        {t("box.item.btn.damaged")}
      </Button>
    </div>
  );

  return (
    <div
      className={
        "round-white box-register-item " +
        (selected ? " shipping-item-selected" : "")
      }
      onClick={handleClick}
    >
      <div className={"box-register-item-top"}>
        {aliasNode}
        {cntNode}
      </div>
      <div className={"border-top"}>{btnNode}</div>
      {loading && <PopupSpinner />}
    </div>
  );
};

export default BoxRegisterItem;
