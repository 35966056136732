import React, {useRef} from "react";
import styles from "./filter.module.css";
import {SearchOutlined} from "@ant-design/icons";

import {Input} from "antd";
import {useTranslation} from "react-i18next";

interface Props {
    onChange: (value: string) => void;
}

export const ClusterIDSearch = ({onChange}: Props) => {
    const {t} = useTranslation("shipping");
    const valueRef = useRef("");

    return (
        <Input
            className={styles.picker}
            placeholder={t("cluster_id")}
            maxLength={16}
            inputMode="numeric"
            onChange={(e) => {
                valueRef.current = e.target.value
                onChange(valueRef.current)
            }
            }
            size="middle"
            suffix={
                <SearchOutlined
                    style={{color: "rgba(0,0,0,.25)"}}
                    onClick={(e) => onChange(valueRef.current)}
                />
            }
        />
    );
};
