import React, { useCallback, useEffect, useRef, useState } from "react";
import styles from "./dispatchList.module.css";
import { useTranslation } from "react-i18next";
import LeftTitledTemplate from "../../templates/leftTitled/LeftTitledTemplate";
import TableView from "../../molecules/table/TableView";
import { Button } from "antd";
import { showApiError, showMessage } from "../../../fns/message";
import {
  getContainerDispatchSts,
  unassignContainer,
  updateContainer,
} from "../../../api/shippingApi";
import {
  BunnyType,
  ContainerApiResponse,
  ContainerType,
  GetContainerDispatchStsApiResponse,
} from "../../../api/apiTypes";
import { format } from "date-fns";
import { AxiosError } from "axios";
import SearchView, {
  SearchResultType,
} from "../../organisms/searchView/SearchView";
import CntStateCount from "../../molecules/countState/CntStateCount";
import { notifyRider } from "../../../api/opsApi";
import RiderSelectPopup from "../../organisms/riderSelect/RiderSelectPopup";
import TableCell from "../../molecules/table/TableCell";
import InputPopup from "../../organisms/InputPopup";
import TypeSelector from "../../organisms/typeSelector/TypeSelector";
import TypeItem from "../../organisms/typeSelector/TypeItem";
import { handleCopy } from "../../../fns/commonFns";
import SelectBatchPopup from "../../organisms/selectBatch/SelectBatchPopup";

type SearchType = {
  filter_assign?: string | number;
  search?: string;
  container_class?: string;
  rider_name?: string;
};
const DispatchListScreen = () => {
  const { t } = useTranslation("dispatch");
  const selectRiderRef = useRef<any>();
  const premiumPopupRef = useRef<any>();
  const selectedRowsRef = useRef<any[]>();
  const dataRef = useRef<any[]>();
  const [params, setParams] = useState<SearchType>();
  const [batchPopup, setBatchPopup] = useState(false);
  const [refresh, setRefresh] = useState(true);
  const [batchType, setBatchType] = useState("type");
  const [batchData, setBatchData] = useState<any>([]);
  const [boxCounts, setBoxCounts] = useState<
    GetContainerDispatchStsApiResponse
  >();
  useEffect(() => {
    getContainerDispatchSts((res) => {
      setBoxCounts(res);
    });
  }, []);

  const handleUpdatePremium = (id: string, inputValue: string) => {
    updateContainer(id, { price_premium: inputValue }, requestRefresh);
  };

  const handleSearch = useCallback(
    ({ inputValue, selectedOption }: SearchResultType) => {
      setParams((prev) => ({
        ...prev,
        container_class: selectedOption == "all" ? undefined : selectedOption,
        rider_name: inputValue,
      }));
      setRefresh(true);
    },
    []
  );

  const handleSelect = useCallback((data: any[]) => {
    setBatchData(data);
    selectedRowsRef.current = data;
  }, []);

  const handleGetCount = useCallback((count: number, data: any[]) => {
    console.log("handleGEtCount", refresh);
    dataRef.current = data;
    setRefresh(false);
  }, []);

  const handleSelectRider = (data?: any[]) => {
    if (data && data?.length > 0) {
      selectedRowsRef.current = data;
    }
    if (!!selectedRowsRef.current && selectedRowsRef.current.length > 0) {
      selectRiderRef.current.show(selectedRowsRef.current);
    } else {
      showMessage(t("assign.popup.error.notselect"));
    }
  };

  const handleContainerSetting = () => {
    if (!!selectedRowsRef.current && selectedRowsRef.current.length > 0) {
      setBatchPopup(true);
      setBatchType("type");
    } else {
      showMessage(t("err_container_setting_select"));
    }
  };

  const handleContainerFeeSetting = () => {
    if (!!selectedRowsRef.current && selectedRowsRef.current.length > 0) {
      setBatchPopup(true);
      setBatchType("fee");
    } else {
      showMessage(t("err_container_setting_select"));
    }
  };

  const handleSelectContainerClass = (
    uuid: string,
    container_class?: ContainerType
  ) => {
    updateContainer(uuid, { container_class }, requestRefresh);
  };

  const handleUnassign = (data?: any[]) => {
    const array = data && data?.length > 0 ? data : selectedRowsRef.current;
    if (!!array && array.length > 0) {
      let successCnt = 0,
        error: AxiosError;
      const req = array.map(
        (s: ContainerApiResponse): Promise<number> => {
          return new Promise<number>((resolve) => {
            unassignContainer(
              s.uuid || "",
              () => {
                resolve(successCnt++);
              },
              (err) => {
                resolve(0);
                error = err;
              }
            );
          });
        }
      );
      Promise.all<number>(req).then((cnt) => {
        if (successCnt > 0) {
          requestRefresh();
          showMessage(t("unassign.popup.success.content"));
        } else if (!!error) {
          showApiError(error);
        }
      });
    } else {
      showMessage(t("assign.popup.error.notselect"));
    }
  };

  const handleSendPush = (data?: any[]) => {
    const array = data && data?.length > 0 ? data : selectedRowsRef.current;
    if (!!array && array.length > 0) {
      let successCnt = 0,
        error: AxiosError;
      const uuidList: string[] = [];
      array.forEach((s: ContainerApiResponse) => {
        if (s.user?.uuid) uuidList.push(s.user?.uuid);
      });
      if (uuidList.length > 0) {
        const req = uuidList.map(
          (uuid: string): Promise<number> => {
            return new Promise<number>((resolve) => {
              notifyRider(
                uuid,
                () => {
                  resolve(successCnt++);
                },
                (err) => {
                  resolve(0);
                  error = err;
                }
              );
            });
          }
        );
        Promise.all<number>(req).then((cnt) => {
          if (successCnt > 0) {
            requestRefresh();
            showMessage(t("push.popup.success.content"));
          } else if (!!error) {
            showApiError(error);
          }
        });
      } else {
        showMessage(t("notify.popup.error.noavailable"));
      }
    } else {
      showMessage(t("assign.popup.error.notselect"));
    }
  };

  const requestRefresh = () => {
    setRefresh((prev) => !prev);
  };

  const columns = [
    {
      title: (
        <TableCell className={"bold body"} value={t("btn.dispatch.req")} />
      ),
      width: 90,
      dataIndex: "change",
      render: (text: string, p: ContainerApiResponse) => (
        <TableCell key={"tcellchange" + p.uuid} refreshing={refresh}>
          <Button
            type={"primary"}
            disabled={!!p.user} //허브 회수 시에만 회수 가능
            className={
              styles.cellBtn +
              " body " +
              (!!p.user ? styles.cellBtnInactive : "")
            }
            onClick={() => {
              handleSelectRider([p]);
            }}
          >
            {t("btn.dispatch.req")}
          </Button>
        </TableCell>
      ),
    },
    {
      title: (
        <TableCell className={"bold body"} value={t("btn.dispatch.cancel")} />
      ),
      width: 90,
      dataIndex: "change",
      render: (text: string, p: ContainerApiResponse) => (
        <TableCell key={"tcellchange" + p.uuid} refreshing={refresh}>
          <Button
            type={"primary"}
            disabled={!p.user} //허브 회수 시에만 회수 가능
            className={
              styles.cellBtn +
              " body " +
              (!p.user ? styles.cellBtnInactive : "")
            }
            onClick={() => {
              handleUnassign([p]);
            }}
          >
            {t("btn.dispatch.cancel")}
          </Button>
        </TableCell>
      ),
    },
    {
      title: (
        <TableCell className={"bold body"} value={t("btn.dispatch.push")} />
      ),
      width: 90,
      dataIndex: "change",
      render: (text: string, p: ContainerApiResponse) => (
        <TableCell key={"tcellchange" + p.uuid} refreshing={refresh}>
          <Button
            key={"tcellchange" + p.uuid}
            type={"primary"}
            disabled={!p.user || p.status !== "UNITARRIVED"} //유닛 도착 시 푸시 발송 가능
            className={
              styles.cellBtn +
              " body " +
              (!p.user ? styles.cellBtnInactive : "")
            }
            onClick={() => {
              handleSendPush([p]);
            }}
          >
            {t("btn.push")}
          </Button>
        </TableCell>
      ),
    },
    {
      title: <TableCell className={"bold body"} value={t("table.unit")} />,
      width: 120,
      dataIndex: "unit",
      render: (text: string, p: ContainerApiResponse) => (
        <TableCell
          refreshing={refresh}
          key={"tcellorder" + p.uuid}
          className={"body link table-column"}
          value={p.unit_location?.name || "-"}
        />
      ),
    },
    {
      title: <TableCell className={"bold body"} value={t("table.unit.box")} />,
      width: 120,
      dataIndex: "box",
      render: (text: string, p: ContainerApiResponse) => (
        <TableCell
          key={"tcellbox" + p.uuid}
          refreshing={refresh}
          className={
            "body text-center primary" + (p.box?.alias && " underline")
          }
          onClick={() => handleCopy(p.uuid)}
          value={p.box?.alias || "-"}
        />
      ),
    },
    {
      title: (
        <TableCell className={"bold body"} value={t("table.container.class")} />
      ),
      width: 120,
      dataIndex: "rider",
      render: (text: string, p: ContainerApiResponse) => (
        <TableCell refreshing={refresh} key={"tcellrider" + p.uuid}>
          <TypeSelector
            values={[
              {
                key: ContainerType.BLACK,
                title: <TypeItem type={ContainerType.BLACK} />,
              },
              {
                key: ContainerType.BLUE,
                title: <TypeItem type={ContainerType.BLUE} />,
              },
              {
                key: ContainerType.WHITE,
                title: <TypeItem type={ContainerType.WHITE} />,
              },
              {
                key: ContainerType.OFFROAD,
                title: <TypeItem type={ContainerType.OFFROAD} />,
              },
              {
                key: ContainerType.RAINBOW,
                title: <TypeItem type={ContainerType.RAINBOW} />,
              },
              {
                key: ContainerType.YELLOW,
                title: <TypeItem type={ContainerType.YELLOW} />,
              },
              {
                key: ContainerType.ORANGE,
                title: <TypeItem type={ContainerType.ORANGE} />,
              },
            ]}
            selectType={p.container_class}
            onSelect={(container_class) =>
              handleSelectContainerClass(p.uuid, container_class)
            }
          />
        </TableCell>
      ),
    },
    {
      title: (
        <TableCell
          className={"bold body text-center"}
          value={t("table.type")}
        />
      ),
      width: 80,
      dataIndex: "type",
      render: (text: string, p: ContainerApiResponse) =>
        p.pickup_only ? (
          <TableCell
            key={"tcelltype" + p.uuid}
            refreshing={refresh}
            className={styles.cellStatus + " small bold " + styles.pickup}
            value={t("pickup_only")}
          />
        ) : p.count_return > 0 ? (
          <TableCell
            key={"tcelltype" + p.uuid}
            refreshing={refresh}
            className={styles.cellStatus + " small bold " + styles.mixed}
            value={t("shipping_mixed")}
          />
        ) : (
          <TableCell
            key={"tcelltype" + p.uuid}
            className={styles.cellStatus + " small " + styles.shipping}
            refreshing={refresh}
            value={t("shipping_only")}
          />
        ),
    },
    {
      title: (
        <TableCell
          className={"bold body text-center"}
          value={t("table.items")}
        />
      ),
      dataIndex: "count",
      width: 60,
      render: (text: string, p: ContainerApiResponse) => (
        <TableCell
          key={"tcellcnt" + p.uuid}
          refreshing={refresh}
          className={"black85"}
          value={p.count_total_items + ""}
        />
      ),
    },
    {
      title: <TableCell className={"bold body"} value={t("table.price")} />,
      width: 100,
      render: (text: string, p: ContainerApiResponse) => (
        <TableCell
          key={"tcellphone" + p.uuid}
          refreshing={refresh}
          className={"body row align-center"}
          value={p.price ? p.price.toLocaleString() : "-"}
        />
      ),
    },
    {
      title: <TableCell className={"bold body"} value={t("table.premium")} />,
      width: 130,
      dataIndex: "premium",
      render: (text: string, p: ContainerApiResponse) => (
        <TableCell
          refreshing={refresh}
          key={"tcellphone" + p.uuid}
          className={"body row align-center"}
        >
          {p.price_premium ? "+" + p.price_premium.toLocaleString() : "-"}
          <a
            className={"small black45 underline " + styles.btnUnderline}
            onClick={() =>
              premiumPopupRef.current.show(
                p.uuid,
                p.price_premium.toLocaleString()
              )
            }
          >
            {t("premium.btn.edit")}
          </a>
        </TableCell>
      ),
    },
    {
      title: (
        <TableCell className={"bold body"} value={t("table.dispatch.status")} />
      ),
      width: 160,
      dataIndex: "status",
      render: (text: string, p: ContainerApiResponse) => (
        <TableCell
          refreshing={refresh}
          key={"tcellsts" + p.uuid}
          className={"row align-center body"}
        >
          <>
            <div
              className={
                styles.dot +
                " " +
                (p.status === "CLAIMED" ? styles.green : "primary-bg")
              }
            />
            {p.status === "UNITARRIVED"
              ? !!p.user
                ? t("UNITARRIVED")
                : t("UNITARRIVEDPENDING")
              : t(p.status)}
          </>
        </TableCell>
      ),
    },
    {
      title: <TableCell className={"bold body"} value={t("table.rider")} />,
      width: 100,
      dataIndex: "rider",
      render: (text: string, p: ContainerApiResponse) => (
        <TableCell
          refreshing={refresh}
          key={"tcellrider" + p.uuid}
          className={"body table-column"}
          value={p?.user?.fullname || "-"}
        />
      ),
    },
    {
      title: <TableCell className={"bold body"} value={t("table.rank")} />,
      width: 120,
      dataIndex: "rider",
      render: (text: string, p: ContainerApiResponse) => (
        <TableCell refreshing={refresh} key={"tcellrider" + p.uuid}>
          <TypeItem type={p?.user?.bunny_type} />
        </TableCell>
      ),
    },
    {
      title: <TableCell className={"bold body"} value={t("table.ride.type")} />,
      width: 120,
      dataIndex: "rider",
      render: (text: string, p: ContainerApiResponse) => (
        <TableCell refreshing={refresh} key={"tcellrider" + p.uuid}>
          {t(p?.user?.ride_type || "-")}
        </TableCell>
      ),
    },
    {
      title: <TableCell className={"bold body"} value={t("table.status")} />,
      width: 120,
      dataIndex: "status",
      render: (text: string, p: ContainerApiResponse) => (
        <TableCell
          refreshing={refresh}
          key={"tcellsts" + p.uuid}
          className={"row align-center body"}
        >
          {!p.user ? (
            "-"
          ) : p.user?.rider_in_delivery_status ? (
            <>
              <div className={styles.dot + " " + styles.working} />
              {t("rider.working")}
            </>
          ) : !p.user?.is_active && !p.user.is_available ? (
            <>
              <div className={styles.dot + " " + styles.inactive} />
              {t("rider.inactive")}
            </>
          ) : (
            <>
              <div className={styles.dot + " " + styles.active} />
              {t("rider.active")}
            </>
          )}
        </TableCell>
      ),
    },
    {
      title: (
        <TableCell className={"bold body"} value={t("table.push.status")} />
      ),
      width: 120,
      dataIndex: "status",
      render: (text: string, p: ContainerApiResponse) => (
        <TableCell
          refreshing={refresh}
          key={"tcellsts" + p.uuid}
          className={"row align-center body"}
        >
          {p.push_assigned ? (
            <>
              <div className={styles.dot + " " + styles.green} />
              {t("push.sent")}
            </>
          ) : (
            <>
              <div className={styles.dot + " " + styles.grey} />
              {t("push.unsent")}
            </>
          )}
        </TableCell>
      ),
    },
    {
      title: (
        <TableCell className={"bold body"} value={t("table.date.checkin")} />
      ),
      width: 180,
      dataIndex: "date",
      render: (text: string, p: ContainerApiResponse) => (
        <TableCell
          refreshing={refresh}
          key={"tcelldate" + p.uuid}
          className={"body table-column"}
        >
          {!!p.timestamp_checkin
            ? format(new Date(p.timestamp_checkin), "yyyy.MM.dd HH:mm:ss")
            : "-"}
        </TableCell>
      ),
    },
    {
      title: (
        <TableCell className={"bold body"} value={t("table.date.assigned")} />
      ),
      width: 180,
      dataIndex: "date",
      render: (text: string, p: ContainerApiResponse) => (
        <TableCell
          refreshing={refresh}
          key={"tcelldate" + p.uuid}
          className={"body table-column"}
        >
          {!!p.timestamp_manual_assigned
            ? format(
                new Date(p.timestamp_manual_assigned),
                "yyyy.MM.dd HH:mm:ss"
              )
            : "-"}
        </TableCell>
      ),
    },
    {
      title: (
        <TableCell className={"bold body"} value={t("table.date.claimed")} />
      ),
      width: 180,
      dataIndex: "date",
      render: (text: string, p: ContainerApiResponse) => (
        <TableCell
          refreshing={refresh}
          key={"tcelldate" + p.uuid}
          className={"body table-column"}
        >
          {!!p.timestamp_claimed
            ? format(new Date(p.timestamp_claimed), "yyyy.MM.dd HH:mm:ss")
            : "-"}
        </TableCell>
      ),
    },
  ];

  const searchOptions = [
    { title: t("all"), value: t("all") },
    { title: t("black"), value: "BLACK" },
    { title: t("blue"), value: "BLUE" },
  ];

  const searchButtons = [
    { title: t("btn_container_setting"), onClick: handleContainerSetting },
    {
      title: t("btn_container_setting_fee"),
      onClick: handleContainerFeeSetting,
    },
    { title: t("btn.dispatch.req"), onClick: handleSelectRider },
    { title: t("btn.dispatch.cancel"), onClick: handleUnassign },
    { title: t("btn.send.push"), onClick: handleSendPush },
  ];

  const statusNode = (
    <div className={styles.statusCntr + " row"}>
      <CntStateCount
        label={t("count.all")}
        count={boxCounts?.num_container_assign_all}
        onClick={() =>
          setParams((params) => ({ ...params, filter_assign: undefined }))
        }
      />
      <div className={styles.statusDivider} />
      <CntStateCount
        label={t("count.pending")}
        count={boxCounts?.num_container_assign_request_pending}
        onClick={() =>
          setParams((params) => ({
            ...params,
            filter_assign: "request_pending",
          }))
        }
      />
      <div className={styles.statusDivider} />
      <CntStateCount
        label={t("count.assigned")}
        count={boxCounts?.num_container_assign_requested}
        onClick={() =>
          setParams((params) => ({ ...params, filter_assign: "requested" }))
        }
      />
      <div className={styles.statusDivider} />
      <CntStateCount
        label={t("count.push")}
        count={boxCounts?.num_container_assign_push_pending}
        onClick={() =>
          setParams((params) => ({ ...params, filter_assign: "push_pending" }))
        }
      />
      <div className={styles.statusDivider} />
      <CntStateCount
        label={t("count.claimed")}
        count={boxCounts?.num_container_assign_accept_pending}
        onClick={() =>
          setParams((params) => ({
            ...params,
            filter_assign: "accept_pending",
          }))
        }
      />

      <div className={styles.statusDivider} />
      <CntStateCount
        label={t("count.accepted")}
        count={boxCounts?.num_container_assign_accepted}
        onClick={() =>
          setParams((params) => ({ ...params, filter_assign: "accepted" }))
        }
      />
    </div>
  );

  const resetRefresh = () => {
    setBatchData([]);
    selectedRowsRef.current = undefined;
    dataRef.current = undefined;
    setBatchPopup(false);
    requestRefresh();
  };

  return (
    <LeftTitledTemplate classname={styles.cntr}>
      {statusNode}
      <SearchView
        options={searchOptions}
        buttons={searchButtons}
        onSearch={handleSearch}
      />
      <TableView
        scrollX={1500}
        scrollY={"calc(var(--app-height) - var(--navbar-height) - 320px)"}
        onChange={handleSelect}
        params={params}
        refresh={refresh}
        sortColumn={"timestamp"}
        columns={columns}
        onGetData={handleGetCount}
        url={"/v1/hub/containers/assign/"}
        reset={refresh}
        t={t}
      />
      <RiderSelectPopup ref={selectRiderRef} onAssigned={requestRefresh} />
      <SelectBatchPopup
        type={batchType}
        onCancel={() => setBatchPopup(false)}
        data={batchData}
        visible={batchPopup}
        refresh={resetRefresh}
      />
      <InputPopup
        ref={premiumPopupRef}
        title={t("premium.popup.title")}
        onOK={handleUpdatePremium}
      />
    </LeftTitledTemplate>
  );
};

export default DispatchListScreen;
